import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    fontSize: "30px",
    color: theme.palette.grey[800],
    textAlign: "center",
    margin: "calc(4px) auto auto auto",
  },
}));

export const ForbiddenPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      403
      <br />
      You don't have permission to access.
    </div>
  );
};
