import { createMuiTheme } from "@material-ui/core/styles";
import React, { useContext } from "react";
import { ThemeProvider } from "@material-ui/styles";
import { ThemeContext } from "./stores/ThemeStore";

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    lpFirst: Palette["primary"];
    lpSecond: Palette["primary"];
    lpThird: Palette["primary"];
    lpFourth: Palette["primary"];
    lpGray: Palette["primary"];
    lpWhite: Palette["primary"];
    lpTextBox: Palette["primary"];
    lpText: Palette["primary"];
    lpSideBar: Palette["primary"];
    lpRed: Palette["primary"];
    lpGreen: Palette["primary"];
  }
  interface PaletteOptions {
    lpFirst: PaletteOptions["primary"];
    lpSecond: PaletteOptions["primary"];
    lpThird: PaletteOptions["primary"];
    lpFourth: PaletteOptions["primary"];
    lpGray: PaletteOptions["primary"];
    lpWhite: PaletteOptions["primary"];
    lpTextBox: PaletteOptions["primary"];
    lpText: PaletteOptions["primary"];
    lpSideBar: PaletteOptions["primary"];
    lpRed: PaletteOptions["primary"];
    lpGreen: PaletteOptions["primary"];
  }
}

type ThemeProps = {
  children: React.ReactNode;
};

export const Theme: React.FC<ThemeProps> = ({ children }: ThemeProps) => {
  const { state: themeState } = useContext(ThemeContext);
  let color = themeState.themeColor;
  let theme: any;
  if (color === "lincrea") {
    theme = createMuiTheme({
      breakpoints: {
        values: {
          xs: 300,
          sm: 800,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
      palette: {
        primary: {
          main: "#272648",
        },
        lpFirst: {
          light: "#272648",
          main: "#272648",
          dark: "#191A2B",
        },
        lpSecond: {
          light: "#E8E4FD",
          main: "#A6B0FD",
          dark: "#7178AD",
        },
        lpThird: {
          light: "#E5FFFC",
          main: "#E5FFFC",
          dark: "#E5FFFC",
        },
        lpFourth: {
          light: "#E5FFFC",
          main: "#E5FFFC",
          dark: "#E5FFFC",
        },
        lpWhite: {
          main: "#ffffff",
          dark: "#F5F8FA",
        },
        lpGray: {
          light: "#E3E3E3",
          main: "#989BA0",
        },
        lpRed: {
          light: "#F9464C",
          main: "#F9464C",
          dark: "#F9464C",
        },
        lpGreen: {
          light: "#0CAC73",
          main: "#0CAC73",
          dark: "#0CAC73",
        },
        lpTextBox: {
          light: "#ffffff",
          main: "#fcfeff",
          dark: "#F1F4FF",
        },
        lpText: {
          main: "#ffffff",
          dark: "#ffffff",
        },
        lpSideBar: {
          main: "#272648",
          dark: "#e22119",
        },
      },
    });
    document.body.style.backgroundColor = "#F1F4FF";
  } else if (color === "basic") {
    theme = createMuiTheme({
      breakpoints: {
        values: {
          xs: 300,
          sm: 800,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
      palette: {
        primary: {
          main: "#272648",
        },
        lpFirst: {
          light: "#363563",
          main: "#272648",
          dark: "#161628",
        },
        lpSecond: {
          light: "#D8D6FC",
          main: "#7E7CC4",
          dark: "#7271AD",
        },
        lpThird: {
          light: "#E5FFFC",
          main: "#E5FFFC",
          dark: "#E5FFFC",
        },
        lpFourth: {
          light: "#E5FFFC",
          main: "#E5FFFC",
          dark: "#E5FFFC",
        },
        lpWhite: {
          main: "#ffffff",
          dark: "#F5F8FA",
        },
        lpGray: {
          light: "#E3E3E3",
          main: "#989BA0",
        },
        lpRed: {
          light: "#E76A7C",
          main: "#E76A7C",
          dark: "#E76A7C",
        },
        lpGreen: {
          light: "#0CAC73",
          main: "#0CAC73",
          dark: "#0CAC73",
        },
        lpTextBox: {
          light: "#ffffff",
          main: "#fcfeff",
          dark: "#F1F4FF",
        },
        lpText: {
          main: "#ffffff",
          dark: "#ffffff",
        },
        lpSideBar: {
          main: "#272648",
          dark: "#161628",
        },
      },
    });
    document.body.style.backgroundColor = "#F1F4FF";
  } else if (color === "purple") {
    theme = createMuiTheme({
      breakpoints: {
        values: {
          xs: 300,
          sm: 800,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
      palette: {
        primary: {
          main: "#44295D",
        },
        lpFirst: {
          light: "#402D5C",
          main: "#44295D",
          dark: "#38254F",
        },
        lpSecond: {
          light: "#F8EDFF",
          main: "#A49EFB",
          dark: "#8B86BF",
        },
        lpThird: {
          light: "#E5FFFC",
          main: "#E5FFFC",
          dark: "#E5FFFC",
        },
        lpFourth: {
          light: "#E5FFFC",
          main: "#E5FFFC",
          dark: "#E5FFFC",
        },
        lpWhite: {
          main: "#ffffff",
          dark: "#F5F8FA",
        },
        lpGray: {
          light: "#E3E3E3",
          main: "#989BA0",
        },
        lpRed: {
          light: "#E76A7C",
          main: "#E76A7C",
          dark: "#E76A7C",
        },
        lpGreen: {
          light: "#0CAC73",
          main: "#0CAC73",
          dark: "#0CAC73",
        },
        lpTextBox: {
          light: "#FDFDFD",
          main: "#F5F8FA",
          dark: "#F0F3F6",
        },
        lpText: {
          main: "#1abc9c",
          dark: "#F5F8FA",
        },
        lpSideBar: {
          main: "#44295D",
          dark: "#38254F",
        },
      },
    });
    document.body.style.backgroundColor = "#f0f0f7";
  } else if (color === "pink") {
    theme = createMuiTheme({
      breakpoints: {
        values: {
          xs: 300,
          sm: 800,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
      palette: {
        primary: {
          main: "#EBCAD7",
        },
        lpFirst: {
          light: "#F3DDE5",
          main: "#EBCAD7",
          dark: "#E2B4CA",
        },
        lpSecond: {
          light: "#F7E5EE",
          main: "#FF9BB0",
          dark: "#FB9DCB",
        },
        lpThird: {
          light: "#E5FFFC",
          main: "#E5FFFC",
          dark: "#E5FFFC",
        },
        lpFourth: {
          light: "#E5FFFC",
          main: "#E5FFFC",
          dark: "#E5FFFC",
        },
        lpWhite: {
          main: "#ffffff",
          dark: "#F5F8FA",
        },
        lpGray: {
          light: "#E3E3E3",
          main: "#989BA0",
        },
        lpRed: {
          light: "#E76A7C",
          main: "#E76A7C",
          dark: "#E76A7C",
        },
        lpGreen: {
          light: "#0CAC73",
          main: "#0CAC73",
          dark: "#0CAC73",
        },
        lpTextBox: {
          light: "#ffffff",
          main: "#FCF6FE",
          dark: "#FBEFFF",
        },
        lpText: {
          main: "#594a4e",
          dark: "#643A28",
        },
        lpSideBar: {
          main: "#EBCAD7",
          dark: "#E2B4CA",
        },
      },
    });
    document.body.style.backgroundColor = "#FFF4FB";
  } else if (color === "mint") {
    theme = createMuiTheme({
      breakpoints: {
        values: {
          xs: 300,
          sm: 800,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
      palette: {
        primary: {
          main: "#BCEDCF",
        },
        lpFirst: {
          light: "#D7FFE3",
          main: "#BCEDCF",
          dark: "#7FD4AB",
        },
        lpSecond: {
          light: "#E5FFEB",
          main: "#BCEDCF",
          dark: "#50A876",
        },
        lpThird: {
          light: "#E5FFFC",
          main: "#E5FFFC",
          dark: "#E5FFFC",
        },
        lpFourth: {
          light: "#E5FFFC",
          main: "#E5FFFC",
          dark: "#E5FFFC",
        },
        lpWhite: {
          main: "#ffffff",
          dark: "#F5F8FA",
        },
        lpGray: {
          light: "#E3E3E3",
          main: "#989BA0",
        },
        lpRed: {
          light: "#E76A7C",
          main: "#E76A7C",
          dark: "#E76A7C",
        },
        lpGreen: {
          light: "#0CAC73",
          main: "#0CAC73",
          dark: "#0CAC73",
        },
        lpTextBox: {
          light: "#ffffff",
          main: "#FBFFF8",
          dark: "#F6F9F3",
        },
        lpText: {
          main: "#594a4e",
          dark: "#643A28",
        },
        lpSideBar: {
          main: "#BCEDCF",
          dark: "#7FD4AB",
        },
      },
    });
    document.body.style.backgroundColor = "#F7FEF5";
  }

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
