import React, { ReactNode, useReducer } from "react";

type StateType = {
  loader?: boolean;
};

export enum ActionEnum {
  SET_LOADER_TRUE = "SET_LOADER_TRUE",
  SET_LOADER_FALSE = "SET_LOADER_FALSE",
}

type ActionType = {
  type: ActionEnum;
  payload?: any;
};
type ContextType = {
  state: StateType;
  dispatch: React.Dispatch<ActionType>;
};
const initialState = {
  loader: false,
};

export const reducer = (state: StateType, action: ActionType) => {
  switch (action.type) {
    case ActionEnum.SET_LOADER_TRUE:
      return {
        ...state,
        loader: true,
      };
    case ActionEnum.SET_LOADER_FALSE:
      return {
        ...state,
        loader: false,
      };
    default:
      return { ...state };
  }
};
interface IProps {
  children: ReactNode;
}
export const LoaderContext = React.createContext({} as ContextType);
export const LoaderProvider = ({ children }: IProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <LoaderContext.Provider value={{ state, dispatch }}>
      {children}
    </LoaderContext.Provider>
  );
};
