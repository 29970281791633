import "date-fns";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  datapickerBox: {
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    "& .MuiGrid-container": {
      flex: 1,
    },
  },
  label: {},
  // inputGroup: {
  //   display: "flex",
  // },
  // input: {
  //   // marginTop: "5px",
  //   width: "100%",
  //   height: "35px",
  //   padding: "10px 15px",
  //   background: theme.palette.lpTextBox.main,
  //   borderRadius: "3px",
  //   border: "1px solid #ddd",
  //   boxSizing: "border-box",
  //   "&:hover": {
  //     background: theme.palette.lpTextBox.dark,
  //   },
  //   "&:focus": {
  //     outline: "0",
  //     border: "1px solid " + theme.palette.lpSecond.main,
  //     background: theme.palette.lpTextBox.light,
  //   },
  // },
  errorInput: {
    // marginTop: "5px",
    width: "100%",
    padding: "10px 15px",
    background: theme.palette.lpTextBox.main,
    borderRadius: "3px",
    border: "1px solid red",
    boxSizing: "border-box",
    "&:hover": {
      background: theme.palette.lpTextBox.dark,
    },
    "&:focus": {
      outline: "0",
      border: "1px solid " + theme.palette.lpSecond.main,
      background: theme.palette.lpTextBox.light,
    },
  },
  error: {
    color: "red",
  },
  datePicker: {
    marginTop: "0px",
    marginBottom: "0px",
    "& fieldset": {
      border: "none",
    },
    "& input": {
      // padding: "10px 15px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "0px 0px 0px 10px",
    },
    // "& .makeStyles-datePicker-53": {
    //   width: "200px",
    // },
    "& .MuiIconButton-root": {
      padding: "0px",
    },
    "& .Mui-disabled": {
      "&:read-only": {
        backgroundColor: "rgb(234, 233, 233)",
        "&:hover": {
          backgroundColor: "rgb(234, 233, 233)",
        },
        "&:focus": {
          backgroundColor: "rgb(234, 233, 233)",
        },
      },
    },
    "& .MuiInputBase-root": {
      // marginTop: "5px",
      // width: "100px",
      maxWidth: "400px",
      height: "37px",
      background: theme.palette.lpTextBox.main,
      borderRadius: "3px",
      border: "1px solid #ddd",
      boxSizing: "border-box",
      "&:hover": {
        background: theme.palette.lpTextBox.dark,
      },
      "&:focus": {
        outline: "0",
        border: "1px solid " + theme.palette.lpSecond.main,
        background: theme.palette.lpTextBox.light,
      },
    },
  },
}));

type DatePickerProps = {
  id?: string;
  title?: string;
  value: Date;
  required?: boolean;
  labelWidth?: string;
  width?: string;
  disabled?: boolean;
  onChange: any;
};

export const DatePicker: React.FC<DatePickerProps> = ({
  id = "",
  title = "",
  value = new Date(),
  required = false,
  labelWidth = "100px",
  width = "150px",
  disabled = false,
  onChange,
}: DatePickerProps) => {
  const classes = useStyles();

  return (
    <div className={classes.datapickerBox}>
      <label className={classes.label} style={{ width: labelWidth }}>
        {title}
        {required && <span style={{ color: "red" }}> *</span>}
      </label>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container>
          <KeyboardDatePicker
            style={{ width: width }}
            className={classes.datePicker}
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            format="yyyy/MM/dd"
            margin="normal"
            id="date-picker-inline"
            value={value}
            disabled={disabled}
            onChange={onChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            autoOk={true}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  );
};
