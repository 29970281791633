export const ApprovalTypeCode = {
  /** 配分者 */
  DISTRIBUTOR: "1",
  /** 第一承認者 */
  FIRST_SUPERIOR: "2",
  /** 最終承認者 */
  SECOND_SUPERIOR: "3",
} as const;
export type ApprovalTypeCode =
  (typeof ApprovalTypeCode)[keyof typeof ApprovalTypeCode];

export const ApprovalTypeName = {
  /** 配分者 */
  DISTRIBUTOR: "配分者",
  /** 第一承認者 */
  FIRST_SUPERIOR: "第１承認",
  /** 最終承認者 */
  SECOND_SUPERIOR: "最終承認",
} as const;
export type ApprovalTypeName =
  (typeof ApprovalTypeName)[keyof typeof ApprovalTypeName];
