import React, { useContext, useState } from "react";
import {
  TextBox,
  ViewWrapper,
  Card,
  SelectBox,
  MessageBar,
} from "../../components";
import {
  ThemeContext,
  ActionEnum as ThemeActionEnum,
} from "../../stores/ThemeStore";
import {
  LoginContext,
  ActionEnum as LoginActionEnum,
} from "../../stores/LoginStore";
import { PutApi } from "../../utils";

export const Settings = () => {
  const { state: themeState, dispatch: themeDispatch } =
    useContext(ThemeContext);
  const { state: loginState, dispatch: loginDispatch } =
    useContext(LoginContext);
  const [messageBarMessage, setMessageBarMessage] = useState("");
  const [messageBarOpen, setMessageBarOpen] = useState(false);

  const handlerThemeChange = async (e: any) => {
    const body = {
      theme: e.target.value,
    };
    const result = await PutApi("/users/me", body);
    console.log(result);
    if (result.statusCode >= 400) {
      setMessageBarMessage(result.message);
      setMessageBarOpen(true);
    } else {
      console.log(body.theme);
      themeDispatch({
        type: ThemeActionEnum.CHANGE_THEME_COLOR,
        payload: { themeColor: body.theme },
      });
    }
  };

  const handlerBankAccountChange = async (e: any) => {
    const body = {
      defaultBankAccount: e.target.value,
    };
    const result = await PutApi("/users/me", body);
    if (result.statusCode >= 400) {
      setMessageBarMessage(result.message);
      setMessageBarOpen(true);
    } else {
      loginDispatch({
        type: LoginActionEnum.SET_BANK_ACCOUNT,
        payload: { defaultBankAccount: body.defaultBankAccount },
      });
    }
  };
  return (
    <ViewWrapper>
      <MessageBar
        open={messageBarOpen}
        setOpen={setMessageBarOpen}
        severity={"error"}
        message={messageBarMessage}
      />

      <Card title={"基本情報"} titleLayout={true}>
        <TextBox
          title={"bs番号"}
          maxWidth="200px"
          readOnly={true}
          value={loginState.loginUserInfo?.bs}
        />
        <TextBox
          title={"名前"}
          maxWidth="400px"
          readOnly={true}
          value={loginState.loginUserInfo?.name}
        />
        <TextBox
          title={"部署"}
          maxWidth="400px"
          readOnly={true}
          value={loginState.loginUserInfo?.dept}
        />
        <TextBox
          title={"メールアドレス"}
          maxWidth="400px"
          readOnly={true}
          value={loginState.loginUserInfo?.email}
        />
      </Card>
      <Card title={"振込先口座設定"} titleLayout={true}>
        <SelectBox
          title={"振込先口座"}
          maxWidth={"150px"}
          options={
            loginState.loginUserInfo?.availableBankAccount === "1"
              ? [
                  {
                    code: "0",
                    name: "給与口座",
                  },
                  {
                    code: "1",
                    name: "その他口座",
                  },
                ]
              : loginState.loginUserInfo?.availableBankAccount === "0"
              ? [
                  {
                    code: "0",
                    name: "給与口座",
                  },
                ]
              : []
          }
          value={loginState.loginUserInfo?.defaultBankAccount}
          onChange={handlerBankAccountChange}
        />
        <p style={{ paddingLeft: "8px" }}>
          口座登録/登録削除は、人事総務本部までお問い合わせください。
        </p>
      </Card>
      {/* <Card title={"環境設定"} titleLayout={true}>
        <SelectBox
          title={"テーマ"}
          maxWidth={"150px"}
          options={[
            {
              code: "lincrea",
              name: "lincrea",
            },
            {
              code: "purple",
              name: "purple",
            },
            {
              code: "pink",
              name: "pink",
            },
            {
              code: "mint",
              name: "mint",
            },
          ]}
          value={themeState.themeColor}
          onChange={handlerThemeChange}
        />
        <p style={{ paddingLeft: "8px" }}>
          システムのテーマカラーをお選びください。
        </p>
      </Card> */}
    </ViewWrapper>
  );
};
