import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { SideBar, MenuAppBar, MessageBar } from "..";
import { LoaderContext } from "../../stores/LoaderStore";
import {
  ActionEnum as MenuActionEnum,
  MenuContext,
} from "../../stores/MenuStore";
import { NotificationContext } from "../../stores/NotificationStore";
import clsx from "clsx";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { useHistory } from "react-router-dom";
import { Pages } from "../SideBar/Pages";

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  contents: {
    margin: "0px",
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      padding: "65px 6px 0px 6px",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "67px 32px 0px 32px",
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    zIndex: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    height: "100%",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    height: "100%",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
    },
  },
}));

type MainLayoutProps = {
  width: "xs" | "sm" | "md" | "lg" | "xl";
  children: React.ReactNode;
};

const MainLayout: React.FC<MainLayoutProps> = ({
  width,
  children,
}: MainLayoutProps) => {
  const classes = useStyles();
  const history = useHistory();
  const { state: loaderState } = useContext(LoaderContext);
  const { dispatch: menuDispatch } = useContext(MenuContext);
  const { state: notificationState } = useContext(NotificationContext);
  const [open, setOpen] = React.useState(isWidthUp("sm", width) ? true : false);

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const pathName = history.location.pathname.split("/");
    for (const page of Pages) {
      if (true) {
        if (page.url && page.url === "/" + pathName[1] + "/" + pathName[2]) {
          menuDispatch({
            type: MenuActionEnum.SELECT_MENU,
            payload: {
              menu: {
                name: page.name,
                disp: true,
              },
            },
          });
        } else if (page.children) {
          for (const child of page.children) {
            if (child.url === "/" + pathName[1] + "/" + pathName[2]) {
              menuDispatch({
                type: MenuActionEnum.SELECT_MENU,
                payload: {
                  menu: {
                    name: page.name,
                    children: child.name,
                    disp: true,
                  },
                },
              });
            }
          }
        }
      }
    }
  }, [history.location.pathname]);

  const [messageBarOpen, setMessageBarOpen] = useState(false);
  useEffect(() => {
    if (notificationState.notification?.message) {
      setMessageBarOpen(true);
    }
  }, [notificationState]);

  return (
    <Fragment>
      {loaderState.loader && (
        <div id="loading">
          <div className="loader"></div>
        </div>
      )}

      <MessageBar
        open={messageBarOpen}
        setOpen={setMessageBarOpen}
        severity={notificationState.notification?.severity}
        message={notificationState.notification?.message}
      />

      {isWidthUp("sm", width) ? (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <SideBar />
        </Drawer>
      ) : (
        <SwipeableDrawer
          anchor={"left"}
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          <SideBar />
        </SwipeableDrawer>
      )}

      <div
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <MenuAppBar open={open} onClick={() => setOpen(!open)} />
        <div className={classes.contents}>{children}</div>
      </div>
    </Fragment>
  );
};

export default withWidth()(MainLayout);
