import React from "react";
import Modal from "react-modal";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { ImagePreview } from "..";

const modalStyle: Modal.Styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.55)",
  },
  content: {
    // width: "80%",
    margin: "auto",
    padding: "8px",
  },
};

type ModalUserListProps = {
  isOpen: boolean;
  imageId: string;
  onClickClose: () => void;
};

export const ImageModal: React.FC<ModalUserListProps> = ({
  isOpen = false,
  imageId = "",
  onClickClose = () => {},
}: ModalUserListProps) => {
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      style={modalStyle}
      onRequestClose={onClickClose}
    >
      <IconButton
        aria-label="delete"
        onClick={onClickClose}
        style={{ position: "fixed", overflow: "overlay" }}
      >
        <CloseIcon />
      </IconButton>
      <ImagePreview onClick={() => {}} id={imageId} key={imageId} />
      {/* <AmplifyS3Image imgKey={imageId} className={classes.image} /> */}
    </Modal>
  );
};
