import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useEffect, useState } from "react";
import {
  ViewWrapper,
  Button,
  Table,
  Card,
  MessageBar,
  ModalUserList,
  TextBox,
} from "../../components";
import { useHistory } from "react-router-dom";
import { GetApi, PostApi } from "../../utils";
import {
  LoaderContext,
  ActionEnum as LoaderActionEnum,
} from "../../stores/LoaderStore";
import { LoginContext } from "../../stores/LoginStore";
import { SelectBoxOption } from "../../components/SelectBox/SelectBox";
import {
  NotificationContext,
  ActionEnum as NotificationActionEnum,
} from "../../stores/NotificationStore";
import { Grid } from "@material-ui/core";
import currencySymbol from "../../assets/images/currencySymbol.png";

const useStyles = makeStyles((theme) => ({
  tableTitleGroup: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: theme.spacing(1),
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
  },
  moneyGroup: {
    display: "flex",
  },
  money: {
    padding: "0 20px 0 20px",
    fontSize: "50px",
    color: "#A3A0F5",
  },
}));

type Distribution = {
  bs: string;
  name: string;
  dept: string;
  amounts0: string | number;
  amounts1: string | number;
  expire0: string;
  expire1: string;
  purpose: string;
};

export const TransferCreate = () => {
  const classes = useStyles();
  const history = useHistory();
  const { dispatch: loaderDispatch } = useContext(LoaderContext);
  const { state: loginState } = useContext(LoginContext);
  const { dispatch: notificationDispatch } = useContext(NotificationContext);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [disabledHandover, setDisabledHandover] = useState(false);
  const [options, setOptions] = useState<SelectBoxOption[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchEmployee, setSearchEmployee] = useState("");
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("");
  const [distributionData, setDistributionData] = useState<Distribution[]>([]);
  const [modalSelectedDistributorList, setModalSelectedDistributorList] =
    useState<string[]>([]);
  const [messageBarMessage, setMessageBarMessage] = useState("");
  const [messageBarOpen, setMessageBarOpen] = useState(false);
  const [moneyTicket, setMoneyTicket] = useState(0);
  const [moneyTicket2, setMoneyTicket2] = useState(0);
  const [moneyTicketAfter, setMoneyTicketAfter] = useState(0);
  const [moneyTicket2After, setMoneyTicket2After] = useState(0);
  const [moneyTicketUse, setMoneyTicketUse] = useState(0);
  const [moneyTicket2Use, setMoneyTicket2Use] = useState(0);
  const [moneyTicketExpire1, setMoneyTicketExpire1] = useState("");
  const [moneyTicketExpire2, setMoneyTicketExpire2] = useState("");

  const regist = () => {
    // if (message.length === 0) {
    //   setMessageBarMessage("メッセージは必須です。");
    //   setMessageBarOpen(true);
    //   setMessageError("メッセージは必須です。");
    //   return;
    // } else {
    //   setMessageError("");
    // }

    if (distributionData.length === 0) {
      setMessageBarMessage("わたす人を選択してください。");
      setMessageBarOpen(true);
      return;
    }

    for (const data of distributionData) {
      const amounts0 = String(data.amounts0).replaceAll(",", "");
      const amounts1 = String(data.amounts1).replaceAll(",", "");
      if (isNaN(Number(amounts0))) {
        setMessageBarMessage("金額は数値を入力してください。");
        setMessageBarOpen(true);
        return;
      }
      if (isNaN(Number(amounts1))) {
        setMessageBarMessage("金額は数値を入力してください。");
        setMessageBarOpen(true);
        return;
      }
      if (Number(amounts0) === 0 && Number(amounts1) === 0) {
        setMessageBarMessage(
          `${data.name} にわたす金額を 0 より大きい値にしてください。`
        );
        setMessageBarOpen(true);
        return;
      }
    }

    if (
      distributionData.filter(
        (user) => user.bs === loginState.loginUserInfo?.bs
      ).length > 0
    ) {
      setMessageBarMessage("自身を指定することはできません。");
      setMessageBarOpen(true);
      return;
    }

    if (
      distributionData.filter(
        (user1) =>
          distributionData.filter((user2) => user1.bs === user2.bs).length > 1
      ).length > 0
    ) {
      setMessageBarMessage("同じユーザが含まれています。");
      setMessageBarOpen(true);
      return;
    }

    const putData = async () => {
      loaderDispatch({
        type: LoaderActionEnum.SET_LOADER_TRUE,
      });

      const body = [];
      for (const data of distributionData) {
        const amounts0 = Number(String(data.amounts0).replaceAll(",", ""));
        const amounts1 = Number(String(data.amounts1).replaceAll(",", ""));
        if (amounts0 > 0) {
          body.push({
            bs: data.bs,
            amounts: amounts0,
            expire: data.expire0,
            purpose: message,
          });
        }
        if (amounts1 > 0) {
          body.push({
            bs: data.bs,
            amounts: amounts1,
            expire: data.expire1,
            purpose: message,
          });
        }
      }

      const result = await PostApi("/money-ticket/transfer", body);

      loaderDispatch({
        type: LoaderActionEnum.SET_LOADER_FALSE,
      });

      if (result.statusCode >= 400) {
        setMessageBarMessage(result.message);
        setMessageBarOpen(true);
      } else {
        notificationDispatch({
          type: NotificationActionEnum.SET_NOTIFICATION,
          payload: {
            message: "金券をわたしました。",
            severity: "success",
          },
        });
        history.push("/money-ticket/transfer");
      }
    };

    putData();
  };

  const handleSearchEmployee = (e: any) => {
    setSearchEmployee(e.target.value);
  };

  const handleAddDistributionData = (row: any) => {
    if (
      distributionData.filter((x: Distribution) => x.bs === row.bs).length > 0
    ) {
      return;
    }
    if (row.bs === loginState.loginUserInfo?.bs) {
      return;
    }

    setDistributionData((prevState: any) => [
      ...prevState,
      {
        bs: row.bs,
        name: row.name,
        dept: row.dept,
        amounts0: 0,
        amounts1: 0,
        expire0: options[0].code,
        expire1: options.length > 1 ? options[1].code : "",
        textType0: "text",
        textType1: "text",
      },
    ]);
    setModalSelectedDistributorList((prevState: any) => [...prevState, row.bs]);
  };

  const onDeleteRow = (row: any) => {
    let totalAmounts1 = 0;
    let totalAmounts2 = 0;
    setDistributionData(
      distributionData
        .filter((d: any) => d.bs !== row.bs)
        .map((d: any) => {
          if (!!d[`amounts0`]) {
            const amounts = d[`amounts0`].replaceAll(",", "");
            totalAmounts1 += Number(amounts);
          }
          if (!!d[`amounts1`]) {
            const amounts = d[`amounts1`].replaceAll(",", "");
            totalAmounts2 += Number(amounts);
          }
          return d;
        })
    );
    setMoneyTicketUse(totalAmounts1);
    setMoneyTicket2Use(totalAmounts2);
    setModalSelectedDistributorList((prevState: any) =>
      prevState.filter((user: any) => user !== row.bs)
    );
  };

  const onChangeMessage = (e: any) => {
    setMessage(e.target.value);
  };

  const onTextChange = (e: any, index: number, row: any) => {
    const value = e.target.value.replace(/[０-９]/g, function (s: string) {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
    let totalAmounts = 0;
    setDistributionData(
      distributionData.map((d: any) => {
        if (d.bs === row.bs) {
          if (!isNaN(Number(value))) {
            d[`amounts${index.toString()}`] = value;
            totalAmounts += Number(value);
          }
        } else {
          if (!!d[`amounts${index.toString()}`]) {
            const amounts = d[`amounts${index.toString()}`].replaceAll(",", "");
            totalAmounts += Number(amounts);
          }
        }
        return d;
      })
    );
    if (index === 0) {
      setMoneyTicketUse(totalAmounts);
    } else {
      setMoneyTicket2Use(totalAmounts);
    }
  };
  const onTextFocus = (e: any, index: number, row: any) => {
    setDistributionData(
      distributionData.map((d: any) => {
        if (d.bs === row.bs) {
          const amounts = e.target.value.replaceAll(",", "");
          if (!isNaN(Number(amounts))) {
            d[`amounts${index.toString()}`] = amounts;
          }
          d[`textType${index.toString()}`] = "number";
        }
        return d;
      })
    );
  };
  const onTextBlur = (e: any, index: number, row: any) => {
    setDistributionData(
      distributionData.map((d: any) => {
        if (d.bs === row.bs) {
          const amounts = e.target.value.replaceAll(",", "");
          if (!isNaN(Number(amounts))) {
            d[`amounts${index.toString()}`] = Number(amounts).toLocaleString();
          }
          d[`textType${index.toString()}`] = "text";
        }
        return d;
      })
    );
  };
  useEffect(() => {
    setMoneyTicketAfter(moneyTicket - moneyTicketUse);
  }, [moneyTicket, moneyTicketUse]);

  useEffect(() => {
    setMoneyTicket2After(moneyTicket2 - moneyTicket2Use);
  }, [moneyTicket2, moneyTicket2Use]);

  useEffect(() => {
    const fetchMoneyTicketData = async () => {
      const result = await GetApi("/money-ticket/balance");

      console.log(result);
      if (result.statusCode >= 400) {
        setDisabledHandover(true);
        setMessageBarMessage(result.message);
        setMessageBarOpen(true);
      }

      let options: SelectBoxOption[] = [];
      let totalAmounts = 0;
      for (const data of result.data) {
        totalAmounts += data.amounts;
      }
      if (totalAmounts === 0) {
        setDisabledHandover(true);
        setMessageBarMessage("わたすことができる金券がありません。");
        setMessageBarOpen(true);
      }
      result.data.map((d: any) =>
        options.push({
          code: d.expire,
          name: d.expire,
        })
      );
      setOptions(options);

      setMoneyTicket(result.data.length > 0 ? result.data[0].amounts : 0);
      setMoneyTicketAfter(result.data.length > 0 ? result.data[0].amounts : 0);
      setMoneyTicketExpire1(
        result.data.length > 0 ? `${result.data[0].expire}` : ""
      );
      setMoneyTicket2(result.data.length > 1 ? result.data[1].amounts : 0);
      setMoneyTicket2After(result.data.length > 1 ? result.data[1].amounts : 0);
      setMoneyTicketExpire2(
        result.data.length > 1 ? `${result.data[1].expire}` : ""
      );

      setLoading(false);
    };

    fetchMoneyTicketData();
  }, []);

  return (
    <ViewWrapper>
      <Card loading={loading} title={"わたす"} titleLayout={true}>
        <TextBox
          id={"message"}
          title={"メッセージ"}
          required={false}
          labelWidth={"150px"}
          value={message}
          error={messageError}
          onChange={onChangeMessage}
        />
        <Grid container>
          <Grid item xs={3} md={2}>
            {"有効期限"}
          </Grid>
          <Grid item xs={3} md={2}>
            {"わたす前"}
          </Grid>
          <Grid item xs={3} md={2}>
            {"わたす金額"}
          </Grid>
          <Grid item xs={3} md={2}>
            {"わたした後"}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3} md={2}>
            {moneyTicketExpire1}
          </Grid>
          <Grid item xs={3} md={2}>
            <img
              src={currencySymbol}
              alt=""
              width={"9px"}
              style={{
                position: "relative",
                top: "2px",
                right: "2px",
              }}
            />
            {moneyTicket.toLocaleString()}
          </Grid>
          <Grid item xs={3} md={2}>
            <img
              src={currencySymbol}
              alt=""
              width={"9px"}
              style={{
                position: "relative",
                top: "2px",
                right: "2px",
              }}
            />
            {moneyTicketUse.toLocaleString()}
          </Grid>
          <Grid item xs={3} md={2}>
            <img
              src={currencySymbol}
              alt=""
              width={"9px"}
              style={{
                position: "relative",
                top: "2px",
                right: "2px",
              }}
            />
            {moneyTicketAfter.toLocaleString()}
          </Grid>
        </Grid>
        {moneyTicketExpire2 && (
          <Grid container>
            <Grid item xs={3} md={2}>
              {moneyTicketExpire2}
            </Grid>
            <Grid item xs={3} md={2}>
              <img
                src={currencySymbol}
                alt=""
                width={"9px"}
                style={{
                  position: "relative",
                  top: "2px",
                  right: "2px",
                }}
              />
              {moneyTicket2.toLocaleString()}
            </Grid>
            <Grid item xs={3} md={2}>
              <img
                src={currencySymbol}
                alt=""
                width={"9px"}
                style={{
                  position: "relative",
                  top: "2px",
                  right: "2px",
                }}
              />
              {moneyTicket2Use.toLocaleString()}
            </Grid>
            <Grid item xs={3} md={2}>
              <img
                src={currencySymbol}
                alt=""
                width={"9px"}
                style={{
                  position: "relative",
                  top: "2px",
                  right: "2px",
                }}
              />
              {moneyTicket2After.toLocaleString()}
            </Grid>
          </Grid>
        )}
        <div className={classes.tableTitleGroup}>
          <Button
            title="わたす人を追加"
            variant="contained"
            disabled={disabledHandover}
            onClick={() => setIsOpen(true)}
          />
        </div>
        <Table
          headers={[
            {
              name: "",
              disp: "わたす人",
              type: "custom",
              component: (
                rowIndex: any,
                index: any,
                header: any,
                rowData: any
              ) => {
                return (
                  <td
                    key={index}
                    id={String(header.name) + "_" + String(rowIndex)}
                  >
                    <p
                      style={{
                        color: "gray",
                        margin: "2px",
                        fontSize: "6px",
                      }}
                    >
                      {rowData["bs"]}
                    </p>
                    <p>{rowData["name"]}</p>
                    <p>{rowData["dept"]}</p>
                    <p style={{ margin: "2px" }}>{rowData["type"]}</p>
                  </td>
                );
              },
            },
            {
              name: "",
              disp: "わたす金額",
              type: "custom",
              component: (
                rowIndex: any,
                index: any,
                header: any,
                rowData: any
              ) => {
                return (
                  <td key={index}>
                    {options.map((option, index) => {
                      return (
                        <TextBox
                          id={
                            +"amounts_" + String(rowIndex) + "_" + String(index)
                          }
                          title={`期限：${option.name}`}
                          labelWidth={"120px"}
                          value={rowData[`amounts${index}`]}
                          maxWidth={"100px"}
                          textAlign={"right"}
                          onChange={(e: any) => onTextChange(e, index, rowData)}
                          onFocus={(e: any) => onTextFocus(e, index, rowData)}
                          onBlur={(e: any) => onTextBlur(e, index, rowData)}
                          type={rowData[`textType${index}`]}
                        />
                      );
                    })}
                  </td>
                );
              },
            },
            {
              name: "delete",
              disp: "削除",
              type: "delete",
            },
          ]}
          data={distributionData}
          onDeleteRow={onDeleteRow}
        />
      </Card>

      {!loading && (
        <div className={classes.buttonGroup}>
          <Button
            title="決定"
            variant="contained"
            onClick={regist}
            disabled={disabledHandover}
          />
          <Button
            title="キャンセル"
            variant="outlined"
            onClick={() => history.push("/money-ticket/transfer")}
          />
        </div>
      )}

      <ModalUserList
        value={searchEmployee}
        isOpen={modalIsOpen}
        selectedUsers={modalSelectedDistributorList}
        onChange={handleSearchEmployee}
        onRowDoubleClick={handleAddDistributionData}
        onClickClose={() => setIsOpen(false)}
      />

      <MessageBar
        open={messageBarOpen}
        setOpen={setMessageBarOpen}
        severity={"error"}
        message={messageBarMessage}
      />
    </ViewWrapper>
  );
};
