import React from "react";
import Modal from "react-modal";

type ReactModalProps = {
  isOpen: boolean;
  onClickClose: () => void;
  width?: string;
  children: React.ReactNode;
};

export const ReactModal: React.FC<ReactModalProps> = ({
  isOpen = false,
  onClickClose = () => {},
  children,
  width = "80%",
}: ReactModalProps) => {
  const modalStyle: Modal.Styles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,0.55)",
    },
    content: {
      width: width,
      margin: "auto",
      padding: "16px",
      zIndex: 3,
    },
  };

  return (
    <div style={{ zIndex: 2 }}>
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        style={modalStyle}
        onRequestClose={onClickClose}
      >
        {children}
      </Modal>
    </div>
  );
};
