import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  ViewWrapper,
  Button,
  Table,
  Card,
  MoneyTicket,
  ImageModal,
  MoneyTicketStatusSteper,
  ImagePreview,
  MessageBar,
} from "../../components";
import { useHistory } from "react-router-dom";
import { GetApi, PutApi } from "../../utils";
import Typography from "@material-ui/core/Typography";
import {
  ActionEnum as LoaderActionEnum,
  LoaderContext,
} from "../../stores/LoaderStore";
import { LoginContext } from "../../stores/LoginStore";
import { judgmentBackgroundColor } from "../../definitions/const";
import withWidth from "@material-ui/core/withWidth";
import { useGetApi } from "../../hooks/useGetApi";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: "scroll",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
  },
  title: {
    marginTop: theme.spacing(2),
    color: theme.palette.grey[400],
  },
}));

const ApplyDetail = (props: any) => {
  // クエリパラメータの取得
  const { id, setId, setTabValue } = props;
  // 定数定義
  const classes = useStyles();
  const history = useHistory();
  const { state: loginState } = useContext(LoginContext);
  const { dispatch: loaderDispatch } = useContext(LoaderContext);
  const [createdAt, setCreatedAt] = useState("");
  const [paymentAt, setPaymentAt] = useState("");
  const [totalamounts, setTotalamounts] = useState(0);
  const [images, setImages] = useState([]);
  const [applyId, setApplyId] = useState("");
  const [applyCategory, setApplyCategory] = useState("");
  const [usageClassification, setUsageClassification] = useState("");
  const [usageClassificationDetail, setUsageClassificationDetail] =
    useState("");
  const [peopleNumber, setPeopleNumber] = useState("");
  const [distributorNumberReason, setDistributorNumberReason] = useState("");
  const [online, setOnline] = useState("");
  const [useDate, setUseDate] = useState("");
  const [useDateReason, setUseDateReason] = useState("");
  const [applyUser, setApplyUser] = useState("");
  const [approvalList, setApprovalList] = useState<any[]>([]);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageId, setImageId] = useState("");
  const [receiptTotalAmounts, setReceiptTotalAmounts] = useState("");
  const [status, setStatus] = useState("");
  const [enableReapplication, setEnableReapplication] = useState(false);
  const [messageBarMessage, setMessageBarMessage] = useState("");
  const [messageBarOpen, setMessageBarOpen] = useState(false);
  const [comment, setComment] = useState("");
  const { data: applyData, isLoading: isLoadingApplyData } = useGetApi({
    type: "get",
    url: `/money-ticket/apply/${id}`,
  });

  const onClickClose = () => {
    setImageModalOpen(false);
  };

  const handlerCancelClick = async () => {
    loaderDispatch({
      type: LoaderActionEnum.SET_LOADER_TRUE,
    });
    const body = {};
    const result = await PutApi("/money-ticket/apply/" + id + "/cancel", body);

    loaderDispatch({
      type: LoaderActionEnum.SET_LOADER_FALSE,
    });

    // 処理成功
    if (result.statusCode >= 400) {
      setMessageBarMessage(result.message);
      setMessageBarOpen(true);
    } else {
      setStatus("取消し");
      setApprovalList(
        approvalList.map((user: any) => {
          if (user.authorizer_bs === loginState.loginUserInfo?.bs) {
            user.judgment = "取消し";
          }
          return user;
        })
      );
      setEnableReapplication(true);
    }
  };

  const onClickReapplication = () => {
    history.push(`/money-ticket/apply/create?selectedId=${id}`);
  };

  useEffect(() => {
    const getData = async () => {
      if (id) {
        const result = await GetApi("/money-ticket/apply/" + id);

        // 処理成功
        if (result.statusCode >= 400) {
          setMessageBarMessage(result.message);
          setMessageBarOpen(true);
        }

        const applyData = result.data;

        setApplyCategory(applyData.apply_category);
        setUsageClassification(applyData.apply_usage_classification);
        setUsageClassificationDetail(
          applyData.apply_usage_classification_detail || "-"
        );
        setPeopleNumber(applyData.people_number || "-");
        setDistributorNumberReason(applyData.distributor_number_reason || "");
        setOnline(applyData.online || "-");
        setUseDate(applyData.use_date || "-");
        setUseDateReason(applyData.use_date_reason || "");
        setTotalamounts(applyData.amounts);
        setReceiptTotalAmounts(applyData.receipt_total_amounts);
        setCreatedAt(applyData.created_at);
        setImages(
          !applyData.receipt_images ? [] : applyData.receipt_images.split(",")
        );
        setStatus(applyData.apply_status);
        setApplyId(applyData.display_apply_id);
        setPaymentAt(applyData.payment_at);
        setComment(applyData.comment);
        setApplyUser(
          applyData.applicant_name + "(" + applyData.applicant_dept + ")"
        );

        setApprovalList(applyData.authorizer);

        const now = moment().utc().add(9, "h").format("YYYY-MM-DD");

        if (
          applyData.deadline_for_reapplication &&
          now <= applyData.deadline_for_reapplication
        ) {
          setEnableReapplication(true);
        } else {
          setEnableReapplication(false);
        }
      } else {
        history.push("/notfound");
      }
    };

    getData();
  }, [id, history]);

  useEffect(() => {}, [id]);

  return (
    <ViewWrapper>
      <ImageModal
        isOpen={imageModalOpen}
        onClickClose={onClickClose}
        imageId={imageId}
      />

      <MessageBar
        open={messageBarOpen}
        setOpen={setMessageBarOpen}
        severity={"error"}
        message={messageBarMessage}
      />

      <div className={`${classes.container}`}>
        <Card title={"金券精算申請　明細"} titleLayout={true}>
          <MoneyTicket
            loading={isLoadingApplyData}
            maxWidth={"300px"}
            expire={"金券使用額"}
            amounts={Number(totalamounts).toLocaleString()}
          ></MoneyTicket>
          <Card loading={isLoadingApplyData}>
            <Fragment>
              <div className={classes.title}>ステータス</div>
              <MoneyTicketStatusSteper status={status} />

              <div className={classes.title}>申請情報</div>
              <Typography variant="body2" gutterBottom>
                {applyId}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {applyUser}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {moment(createdAt).format("YYYY年MM月DD日 HH時mm分")}
              </Typography>

              <div className={classes.title}>申請種別</div>
              <Typography variant="body2" gutterBottom>
                {applyCategory}
              </Typography>

              <div className={classes.title}>利用区分</div>
              <Typography variant="body2" gutterBottom>
                {usageClassification}
              </Typography>

              <div className={classes.title}>摘要</div>
              <Typography variant="body2" gutterBottom>
                {usageClassificationDetail}
              </Typography>

              <div className={classes.title}>使用日</div>
              <Typography variant="body2" gutterBottom>
                {useDate === "-"
                  ? "-"
                  : moment(useDate).format("YYYY年MM月DD日")}
              </Typography>
              {useDateReason && (
                <Typography
                  variant="body2"
                  gutterBottom
                  style={{ color: "blue" }}
                >
                  土日祝申請理由：{useDateReason}
                </Typography>
              )}

              <div className={classes.title}>人数</div>
              <Typography variant="body2" gutterBottom>
                {peopleNumber}
              </Typography>
              {distributorNumberReason && (
                <Typography
                  variant="body2"
                  gutterBottom
                  style={{ color: "blue" }}
                >
                  １人申請理由：{distributorNumberReason}
                </Typography>
              )}

              <div className={classes.title}>リアル/オンライン</div>
              <Typography variant="body2" gutterBottom>
                {online}
              </Typography>
              {comment && (
                <Fragment>
                  <div className={classes.title}>備考欄</div>
                  <Typography variant="body2" gutterBottom>
                    <p style={{ whiteSpace: "pre", margin: "0px" }}>
                      {comment}
                    </p>
                  </Typography>
                </Fragment>
              )}

              <div className={classes.title}>入金予定日</div>
              <Typography variant="body2" gutterBottom>
                {!paymentAt
                  ? "未定"
                  : moment(paymentAt).format("YYYY年MM月DD日")}
              </Typography>

              {images.length > 0 && images[0] !== "" && (
                <Fragment>
                  <div className={classes.title}>領収書合計金額</div>
                  <p style={{ color: "red" }}>
                    CHECK!　添付されている領収書の合計金額が入力されているか確認
                  </p>
                  <MoneyTicket
                    maxWidth={"300px"}
                    expire={""}
                    amounts={Number(receiptTotalAmounts).toLocaleString()}
                  />
                </Fragment>
              )}

              {images.length > 0 && images[0] !== "" && (
                <Fragment>
                  <div className={classes.title}>領収書</div>
                  <p style={{ color: "red" }}>
                    CHECK!　領収書の日付が申請日の３ヶ月以内かを確認
                  </p>
                </Fragment>
              )}
              {images.length > 0 &&
                images.map((image: any) => (
                  <ImagePreview
                    onClick={() => {
                      setImageId(image);
                      setImageModalOpen(true);
                    }}
                    id={image}
                    key={image}
                  />
                ))}
              <Table
                headers={[
                  {
                    name: "apply_category",
                    disp: "判定者",
                    type: "custom",
                    component: (
                      rowIndex: any,
                      index: any,
                      header: any,
                      rowData: any
                    ) => {
                      return (
                        <td
                          key={index}
                          id={String(header.name) + "_" + String(rowIndex)}
                        >
                          <p style={{ margin: "2px" }}>
                            {rowData["approval_type"]}
                          </p>
                          <p style={{ margin: "2px" }}>{rowData["name"]}</p>
                          <p
                            style={{
                              color: "gray",
                              margin: "2px",
                              fontSize: "6px",
                            }}
                          >
                            {rowData["dept"]}
                          </p>
                        </td>
                      );
                    },
                  },
                  {
                    name: "amounts",
                    disp: "金額",
                    type: "moneyText",
                  },
                  {
                    name: "apply_category",
                    disp: "判定情報",
                    type: "custom",
                    component: (
                      rowIndex: any,
                      index: any,
                      header: any,
                      rowData: any
                    ) => {
                      return (
                        <td
                          key={index}
                          id={String(header.name) + "_" + String(rowIndex)}
                        >
                          <div
                            id={String(header.name) + "_" + String(rowIndex)}
                            style={{
                              maxWidth: "70px",
                              background:
                                judgmentBackgroundColor[rowData["judgment"]],
                              borderRadius: "20px 20px 20px 20px",
                              color: "white",
                              padding: "4px",
                              textAlign: "center",
                            }}
                          >
                            {rowData["judgment"]}
                          </div>
                          <p style={{ margin: "2px" }}>
                            {!rowData["judgmented_at"]
                              ? ""
                              : moment(rowData["judgmented_at"]).format(
                                  "YYYY年MM月DD日 HH時mm分"
                                )}
                          </p>
                          <p style={{ margin: "2px" }}>{rowData["comment"]}</p>
                        </td>
                      );
                    },
                  },
                ]}
                data={approvalList}
              />
            </Fragment>
          </Card>
          {!isLoadingApplyData && (
            <Fragment>
              <div className={classes.buttonGroup}>
                {(status === "配分者確認待ち" ||
                  status === "第１承認待ち" ||
                  status === "最終承認待ち") && (
                  <Button
                    title="取消し"
                    variant="contained"
                    onClick={handlerCancelClick}
                  />
                )}

                {status === "差し戻し" && enableReapplication && (
                  <Button
                    title="申請内容を再利用"
                    variant="contained"
                    onClick={onClickReapplication}
                  />
                )}

                {status === "取消し" && enableReapplication && (
                  <Button
                    title="申請内容を再利用"
                    variant="contained"
                    onClick={onClickReapplication}
                  />
                )}

                {status === "修正依頼" && (
                  <Button
                    title="修正して再申請"
                    variant="contained"
                    onClick={onClickReapplication}
                  />
                )}

                <Button
                  title="閉じる"
                  variant="outlined"
                  onClick={() => {
                    setId("");
                    setTabValue(0);
                    history.push("/money-ticket/apply");
                  }}
                />
              </div>
            </Fragment>
          )}
        </Card>
      </div>
    </ViewWrapper>
  );
};

export default withWidth()(ApplyDetail);
