import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { ViewWrapper, Table, Card, SelectBox } from "../../components";
import { useHistory } from "react-router-dom";
import { ApprovalDetail } from ".";
import queryString from "query-string";
import Tabs from "@material-ui/core/Tabs";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import { statusBackgroundColor } from "../../definitions/const";
import { judgmentOptions } from "../../definitions/selectors";
import { useGetApi } from "../../hooks/useGetApi";
const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "30% 70%",
    height: "100%",
  },
  mainContainerWithSide: {
    gridColumn: "1/2",
  },
  mainContainer: {
    gridColumn: "1/3",
  },
  sideContainerNone: {
    display: "none",
  },
  sideContainer: {
    overflow: "overlay",
    padding: "16px",
    left: "32px",
    position: "relative",
    height: "100%",
    gridColumn: "2/3",
    background: theme.palette.lpWhite.main,
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, .3)",
  },
  status: {
    margin: "8px",
  },
}));

const Approval = (props: any) => {
  // クエリパラメータの取得
  const qs = queryString.parse(props.location.search);
  const width = props.width;
  const classes = useStyles();
  const history = useHistory();
  const [status, setStatus] = useState(
    !qs.status ? "UNDECIDED" : String(qs.status)
  );
  const [id, setId] = useState(!qs.selectedId ? "" : qs.selectedId);
  const [tabValue, setTabValue] = React.useState(0);
  const [queries, setQueries] = useState({ offset: 0, status: status });
  const {
    data: historyData,
    setData: setHistoryData,
    isLoading: isLoadingHistoryData,
    moreScroll,
  } = useGetApi({
    type: "scroll",
    url: "/money-ticket/approval",
    queries: queries,
    id: "apply_id",
  });

  const handleChangeTabValue = (event: any, newValue: any) => {
    setTabValue(newValue);
  };

  const onRowClick = (row: any) => {
    setId(row.apply_id);
    setTabValue(1);
    history.push("/money-ticket/approval?selectedId=" + row.apply_id);
  };

  const onScrolled = async () => {
    setQueries({
      offset: historyData.length,
      status: status,
    });
  };

  const onSelectChange = (e: any) => {
    setStatus(e.target.value);
    setId("");
    setQueries({
      offset: 0,
      status: e.target.value,
    });
  };

  return (
    <ViewWrapper>
      <div className={classes.container}>
        <div
          className={
            id && isWidthUp("sm", width)
              ? classes.mainContainerWithSide
              : classes.mainContainer
          }
        >
          <div className={classes.status}>
            <SelectBox
              title={"未判定/判定済み"}
              value={status}
              options={judgmentOptions}
              onChange={onSelectChange}
            />
          </div>

          {isWidthDown("xs", width) && (
            <Box m={1}>
              <Paper square>
                <Tabs
                  centered
                  variant="fullWidth"
                  value={tabValue}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleChangeTabValue}
                  aria-label="disabled tabs example"
                >
                  <Tab label="一覧" />
                  <Tab label="詳細" />
                </Tabs>
              </Paper>
            </Box>
          )}

          {(isWidthDown("xs", width) && tabValue === 0) ||
          isWidthUp("sm", width) ? (
            <Card
              title={"あなたが承認する/した金券精算申請"}
              titleLayout={true}
            >
              <Table
                loading={isLoadingHistoryData}
                cursor
                headers={[
                  {
                    name: "apply_category",
                    disp: "申請種別",
                    type: "custom",
                    component: (
                      rowIndex: any,
                      index: any,
                      header: any,
                      rowData: any
                    ) => {
                      return (
                        <td
                          key={index}
                          id={String(header.name) + "_" + String(rowIndex)}
                        >
                          <p
                            style={{
                              color: "gray",
                              margin: "2px",
                              fontSize: "6px",
                            }}
                          >
                            {!rowData["created_at"]
                              ? ""
                              : moment(rowData["created_at"]).format(
                                  "YYYY年MM月DD日 HH時mm分"
                                )}
                          </p>
                          <p style={{ margin: "2px" }}>
                            {rowData["display_apply_id"]}
                          </p>
                          <p style={{ margin: "2px" }}>
                            {rowData["apply_category"]} /{" "}
                            {rowData["apply_usage_classification"]}
                          </p>
                          <p
                            style={{
                              margin: "2px",
                            }}
                          >
                            {rowData["name"]}
                          </p>
                          <p
                            style={{
                              color: "gray",
                              margin: "2px",
                              fontSize: "6px",
                            }}
                          >
                            {rowData["applicant_department_name"]}
                          </p>
                        </td>
                      );
                    },
                  },
                  {
                    name: "amounts",
                    disp: "金額",
                    type: "coloredMoneyText",
                  },
                  {
                    name: "apply_status",
                    disp: "ステータス",
                    type: "label",
                    maxWidth: "100px",
                    background: statusBackgroundColor,
                  },
                ]}
                data={historyData}
                onRowClick={onRowClick}
                onScrolled={onScrolled}
                moreScroll={moreScroll}
              />
            </Card>
          ) : (
            <div>
              {id ? (
                <ApprovalDetail
                  id={id}
                  setId={setId}
                  data={historyData}
                  setData={setHistoryData}
                  setTabValue={setTabValue}
                />
              ) : (
                <div>データが選択されていません。</div>
              )}
            </div>
          )}
        </div>

        {isWidthUp("sm", width) && (
          <div
            className={
              id
                ? `${classes.sideContainer} SideDetail`
                : classes.sideContainerNone
            }
          >
            <ApprovalDetail
              id={id}
              setId={setId}
              data={historyData}
              setData={setHistoryData}
              setTabValue={setTabValue}
            />
          </div>
        )}
      </div>
    </ViewWrapper>
  );
};

export default withWidth()(Approval);
