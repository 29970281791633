import React, { ReactNode, useReducer } from "react";

type StateType = {
  loginUserInfo?: {
    bs: string;
    dept: string;
    jobTitle?: string;
    name: string;
    email: string;
    dept_code: string;
    position: string;
    availableBankAccount: string;
    defaultBankAccount: string;
    auth: {
      generalAffairs: boolean;
      officerEntertainmentExpenses: boolean;
      balanceEditing: boolean;
    };
  };
};

export enum ActionEnum {
  LOGIN = "LOGIN",
  SET_BANK_ACCOUNT = "SET_BANK_ACCOUNT",
}

type ActionType = {
  type: ActionEnum;
  payload?: any;
};

type ContextType = {
  state: StateType;
  dispatch: React.Dispatch<ActionType>;
};

const initialState = {};

export const reducer = (state: StateType, action: ActionType) => {
  switch (action.type) {
    case ActionEnum.LOGIN:
      return {
        ...state,
        loginUserInfo: action.payload?.loginUserInfo,
      };
    case ActionEnum.SET_BANK_ACCOUNT:
      return {
        ...state,
        loginUserInfo: {
          ...state.loginUserInfo,
          defaultBankAccount: action.payload?.defaultBankAccount,
        },
      };
    default:
      return { ...state };
  }
};
interface IProps {
  children: ReactNode;
}
export const LoginContext = React.createContext({} as ContextType);
export const LoginProvider = ({ children }: IProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <LoginContext.Provider value={{ state, dispatch }}>
      {children}
    </LoginContext.Provider>
  );
};
