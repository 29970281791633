import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1),
    // padding: theme.spacing(2),
    // boxShadow: "0 1px 1px 0 rgba(0, 0, 0, .3)",
    // borderRadius: "0px",
    // border: "1px solid rgba(0, 0, 0, .1)",
    // background: theme.palette.lpWhite.main,
  },
  cardMain: {
    // margin: theme.spacing(1),
    padding: theme.spacing(2),
    boxShadow: "0 1px 1px 0 rgba(0, 0, 0, .3)",
    borderRadius: "0px",
    border: "1px solid rgba(0, 0, 0, .1)",
    background: theme.palette.lpWhite.main,
  },
  title: {
    // padding: theme.spacing(1),
    color: theme.palette.lpWhite.main,
    background: theme.palette.lpFirst.main,
    position: "absolute",
    borderLeft: "31px solid rgba(226,33, 25)",
    borderBottom: "31px solid transparent",
  },
  title2: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    color: theme.palette.lpWhite.main,
    background: theme.palette.lpFirst.main,
    // borderStyle: "solid",
    // borderWidth: "10px 40px 0 0",
    // borderColor: "#ff0088 transparent transparent transparent",
    // position: "absolute",
    // top: 0;
    // left: 0;
    // borderLeft: "20px solid #5bc8ac",
    // borderBottom: "20px solid transparent",
    // borderBottom: "20px solid transparent",
    // borderLeft: "20px solid #6aa7cd",
    // borderTop: "20px solid #F0897F",
    // borderRight: "20px solid transparent",
  },
}));

type CardProps = {
  title?: string;
  titleLayout?: boolean;
  width?: string;
  maxWidth?: string;
  loading?: boolean;
  children: React.ReactNode;
  style?: object;
};

export const Card: React.FC<CardProps> = ({
  title = "　",
  titleLayout = false,
  width = "",
  maxWidth = "",
  loading = false,
  children,
  style = {},
}: CardProps) => {
  const classes = useStyles();

  return (
    <div className={classes.card}>
      {titleLayout && (
        <Fragment>
          <div className={classes.title}></div>
          <div className={classes.title2}>{title}</div>
        </Fragment>
      )}
      <div
        className={classes.cardMain}
        style={style}
        //style={{ width: width, maxWidth: maxWidth }}
      >
        {loading ? <Skeleton variant="rect" height={100} /> : children}
      </div>
    </div>
  );
};
