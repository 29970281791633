import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { ViewWrapper, Button, Table, Card, MessageBar } from "../../components";
import { useHistory } from "react-router-dom";
import { GetApi } from "../../utils";

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export const ImportHistory = () => {
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [messageBarMessage, setMessageBarMessage] = useState("");
  const [messageBarOpen, setMessageBarOpen] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);

  const onRowClick = (row: any) => {
    history.push("/money-ticket/import/history/" + row.import_id);
  };

  useEffect(() => {
    const fetchHistoryData = async () => {
      const result = await GetApi("/money-ticket/import");

      if (result.statusCode >= 400) {
        setMessageBarMessage(result.message);
        setMessageBarOpen(true);
        return;
      }
      setData(result.data);
      setTableLoading(false);
    };

    fetchHistoryData();
  }, []);

  const onClickBack = () => {
    history.push("/money-ticket/import");
  };

  return (
    <ViewWrapper>
      <MessageBar
        open={messageBarOpen}
        setOpen={setMessageBarOpen}
        severity={"error"}
        message={messageBarMessage}
      />
      <Card title={"インポート履歴"} titleLayout={true}>
        <h2>インポート履歴</h2>
        <Table
          loading={tableLoading}
          cursor
          headers={[
            {
              name: "contents",
              disp: "内容",
              type: "text",
            },
            {
              name: "expire",
              disp: "金券有効期限",
              type: "text",
            },
            {
              name: "amounts",
              disp: "インポート金額",
              type: "moneyText",
            },
            {
              name: "created_at",
              disp: "登録日",
              type: "datetime",
            },
            {
              name: "name",
              disp: "実施者",
              type: "text",
            },
          ]}
          data={data}
          onRowClick={onRowClick}
        />
      </Card>
      <div className={classes.buttonGroup}>
        <Button title="戻る" variant="outlined" onClick={onClickBack} />
      </div>
    </ViewWrapper>
  );
};
