import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { ViewWrapper, Button, Table, Card, MessageBar } from "../../components";
import { useHistory } from "react-router-dom";
import { GetApi } from "../../utils";
import { BalanceEditingDetail } from ".";
import queryString from "query-string";
import Tabs from "@material-ui/core/Tabs";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "50% 50%",
    height: "100%",
  },
  mainContainerWithSide: {
    gridColumn: "1/2",
  },
  mainContainer: {
    gridColumn: "1/3",
  },
  sideContainerNone: {
    display: "none",
  },
  sideContainer: {
    overflow: "scroll",
    padding: "0px 8px",
    left: "32px",
    position: "relative",
    height: "100%",
    gridColumn: "2/3",
    background: theme.palette.lpWhite.main,
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, .3)",
  },
}));

const BalanceEditing = (props: any) => {
  const qs = queryString.parse(props.location.search);
  const classes = useStyles();
  const history = useHistory();
  const width = props.width;
  const [data, setData] = useState<{}[]>([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [moreScroll, setMoreScroll] = useState(false);
  const [id, setId] = useState(!qs.selectedId ? "" : qs.selectedId);
  const [messageBarMessage, setMessageBarMessage] = useState("");
  const [messageBarOpen, setMessageBarOpen] = useState(false);
  const [tabValue, setTabValue] = React.useState(0);

  // 履歴データ取得
  const fetchHistoryData = async () => {
    const result = await GetApi("/money-ticket/balance-editing");

    console.log(result);
    if (result.statusCode >= 400) {
      setMessageBarMessage(result.message);
      setMessageBarOpen(true);
      return;
    }

    setMoreScroll(result.data.length !== 0 ? true : false);
    setData(result.data);
    setTableLoading(false);
  };

  const handleChangeTabValue = (
    event: React.ChangeEvent<{}>,
    newValue: any
  ) => {
    setTabValue(newValue);
  };

  const onRowClick = (row: any) => {
    setId(row.id);
    setTabValue(1);
    history.push("/money-ticket/balance-editing?selectedId=" + row.id);
  };

  const onScrolled = async () => {
    const fetchHistoryData = async () => {
      if (!moreScroll) {
        return;
      }
      const result = await GetApi("/money-ticket/apply", {
        offset: data.length,
      });

      if (result.statusCode >= 400) {
        setMessageBarMessage(result.message);
        setMessageBarOpen(true);
        return;
      }

      let dispData = result.data.map((d: any) => {
        if (d.type === "金券申請") {
          d.icon = "fa fa-file-text";
          d.iconColor = "purple";
        } else if (d.type === "受け取り") {
          d.icon = "fa fa-plus-square";
          d.iconColor = "green";
        } else if (d.type === "受け渡し") {
          d.icon = "fa fa-minus-square";
          d.iconColor = "red";
        } else if (d.type === "インポート") {
          d.icon = "fa fa-plus-square";
          d.iconColor = "green";
        }
        return d;
      });

      const _temp = dispData.filter(
        (element: any) => data.findIndex((e: any) => e.id === element.id) < 0
      );
      const _tempData = [...data, ..._temp];

      setMoreScroll(result.data.length !== 0 ? true : false);
      setData(_tempData);
    };
    await fetchHistoryData();
  };

  useEffect(() => {
    fetchHistoryData();
  }, []);

  return (
    <ViewWrapper>
      <div className={classes.container}>
        <div
          className={
            id && isWidthUp("sm", width)
              ? classes.mainContainerWithSide
              : classes.mainContainer
          }
        >
          <div>
            <Button
              title={"金券残高編集"}
              onClick={() =>
                history.push("/money-ticket/balance-editing/create")
              }
            />
          </div>

          {isWidthDown("xs", width) && (
            <Box m={1}>
              <Paper square>
                <Tabs
                  centered
                  variant="fullWidth"
                  value={tabValue}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleChangeTabValue}
                  aria-label="disabled tabs example"
                >
                  <Tab label="一覧" />
                  <Tab label="詳細" />
                </Tabs>
              </Paper>
            </Box>
          )}

          {(isWidthDown("xs", width) && tabValue === 0) ||
          isWidthUp("sm", width) ? (
            <Card title={"編集履歴"} titleLayout={true}>
              <Table
                loading={tableLoading}
                cursor
                headers={[
                  {
                    name: "created_at",
                    disp: "編集日付",
                    type: "datetime",
                  },
                  {
                    name: "type_name",
                    disp: "理由区分",
                    type: "text",
                  },
                  {
                    name: "owner_name",
                    disp: "対象者",
                    type: "text",
                  },
                  {
                    name: "amounts",
                    disp: "編集金額",
                    type: "moneyText",
                  },
                  {
                    name: "operator_name",
                    disp: "編集者",
                    type: "text",
                  },
                ]}
                data={data}
                onRowClick={onRowClick}
                onScrolled={onScrolled}
                moreScroll={moreScroll}
              />
            </Card>
          ) : (
            <Card>
              <div>
                {id ? (
                  <BalanceEditingDetail
                    id={id}
                    setId={setId}
                    setTabValue={setTabValue}
                    data={data}
                    setData={setData}
                  />
                ) : (
                  <div>データが選択されていません。</div>
                )}
              </div>
            </Card>
          )}
        </div>
        {isWidthUp("sm", width) && (
          <div
            className={id ? classes.sideContainer : classes.sideContainerNone}
          >
            {id ? (
              <BalanceEditingDetail
                id={id}
                setId={setId}
                setTabValue={setTabValue}
                data={data}
                setData={setData}
              />
            ) : (
              <div></div>
            )}
          </div>
        )}
      </div>

      <MessageBar
        open={messageBarOpen}
        setOpen={setMessageBarOpen}
        severity={"error"}
        message={messageBarMessage}
      />
    </ViewWrapper>
  );
};

export default withWidth()(BalanceEditing);
