import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, withRouter } from "react-router-dom";
import { LoginContext } from "../../stores/LoginStore";
import {
  MenuContext,
  ActionEnum as MenuActionEnum,
} from "../../stores/MenuStore";
import { Pages } from "./Pages";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import lincrealogo from "../../assets/images/lincrealogo.png";
import appLogo from "../../assets/images/appLogo.png";
import kinken from "../../assets/images/kinken.png";

const useStyles = makeStyles((theme) => ({
  sideBar: {
    maxWidth: "200px",
    height: "100%",
    padding: "0",
    background: theme.palette.lpSideBar.main,
    "& input": {
      display: "none",
    },
    "& ul": {
      margin: "0",
      padding: "0",
      background: theme.palette.lpSideBar.main,
      listStyle: "none",
    },
  },
  label: {
    display: "block",
    padding: "12px",
    color: theme.palette.lpText.dark,
    background: theme.palette.lpSideBar.main,
    cursor: "pointer",
    //alignItems: "center",
    borderBottom: "1px solid rgba(255,255,255,.05)",
    transition: "all 0.5s",
    "&:hover": {
      color: theme.palette.lpText.main,
      background: theme.palette.lpSideBar.dark,
      borderLeft: "solid 2px " + theme.palette.lpText.main,
      paddingLeft: "20px",
    },
  },
  li: {
    display: "block",
    textDecoration: "none",
    color: theme.palette.lpText.dark,
    overflowY: "hidden",
    padding: "0 12px",
    transition: "all 0.5s",
    "&:hover": {
      color: theme.palette.lpText.main,
      background: theme.palette.lpSideBar.dark,
      borderLeft: "solid 2px " + theme.palette.lpText.main,
      paddingLeft: "20px",
    },
  },
  liClose: {
    maxHeight: "0",
  },
  liOpen: {
    padding: "12px",
    //maxHeight: "100px",
    opacity: "1",
  },
  liFocus: {
    color: theme.palette.lpText.main,
    background: theme.palette.lpSideBar.dark,
    borderLeft: "solid 2px " + theme.palette.lpText.main,
    paddingLeft: "20px",
  },
  labelFocus: {
    color: theme.palette.lpText.main,
    background: theme.palette.lpSideBar.dark,
    borderLeft: "solid 2px " + theme.palette.lpText.main,
    paddingLeft: "20px",
  },
  menu: {
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  menuItem: {
    display: "inline-flex",
  },
  title: {
    padding: "0 0 0 10px",
  },
  icon: {
    height: "20px",
    verticalAlign: "baseline",
  },
  portalName: {
    display: "block",
    position: "relative",
    margin: "0 0 0px 0",
    padding: "10px",
    color: theme.palette.lpWhite.main,
    background: theme.palette.lpSideBar.main,
    cursor: "pointer",
    alignItems: "center",
    fontSize: "14px",
  },
  portalNameLine: {
    display: "block",
    position: "relative",
    margin: "0 0 0px 0",
    padding: "3px",
    color: theme.palette.lpWhite.main,
    background: theme.palette.lpSideBar.main,
    cursor: "pointer",
    alignItems: "center",
    borderTop: "3px solid rgba(226,33,25,1)",
    borderBottom: "1px solid rgba(255,255,255,.7)",
    fontSize: "14px",
  },
  arrow: {
    display: "block",
    width: "8px",
    height: "8px",
    borderTop: "1px solid #b0b0b0",
    borderRight: "1px solid #b0b0b0",
    transform: "rotate(45deg)",
    transition: "all 0.3s",
    "& rotate": {
      transform: "rotate(135deg)",
    },
  },
}));

const SideBarFunc = () => {
  const classes = useStyles();
  const history = useHistory();
  const { state: loginState } = useContext(LoginContext);
  const { state: menuState, dispatch: menuDispatch } = useContext(MenuContext);

  const getDisplay = (auth: string): boolean => {
    return !loginState.loginUserInfo
      ? false
      : auth === "all" ||
          (auth === "generalAffairs" &&
            loginState.loginUserInfo?.auth.generalAffairs) ||
          (auth === "officerEntertainmentExpenses" &&
            loginState.loginUserInfo?.auth.officerEntertainmentExpenses) ||
          (auth === "balanceEditing" &&
            loginState.loginUserInfo?.auth.balanceEditing);
  };
  // /* eslint-disable react-hooks/exhaustive-deps */
  // useEffect(() => {
  //   const pathName = history.location.pathname.split("/");
  //   for (const page of Pages) {
  //     if (!state.menu.name) {
  //       console.log(pathName);
  //       if (page.url && page.url === "/" + pathName[1]) {
  //         dispatch({
  //           type: ActionEnum.SELECT_MENU,
  //           payload: {
  //             menu: {
  //               name: page.name,
  //               disp: true,
  //             },
  //           },
  //         });
  //       } else if (page.children) {
  //         for (const child of page.children) {
  //           if (child.url === "/" + pathName[1] + "/" + pathName[2]) {
  //             dispatch({
  //               type: ActionEnum.SELECT_MENU,
  //               payload: {
  //                 menu: {
  //                   name: page.name,
  //                   children: child.name,
  //                   disp: true,
  //                 },
  //               },
  //             });
  //           }
  //         }
  //       }
  //     }
  //   }
  // }, []);

  return (
    <div className={classes.sideBar}>
      <div className={classes.portalName}>
        <img
          src={appLogo}
          alt=""
          width={"140px"}
          style={{
            position: "relative",
            top: "2px",
            right: "0px",
          }}
        />
      </div>
      <div className={classes.portalNameLine}></div>

      {Pages.map((page: any) => {
        return !page.children ? (
          <div key={page.name}>
            {getDisplay(page.auth) && (
              <div>
                <label
                  className={
                    classes.label +
                    " " +
                    (menuState.menu.name === page.name
                      ? classes.labelFocus
                      : "")
                  }
                  onClick={() => {
                    menuDispatch({
                      type: MenuActionEnum.SELECT_MENU,
                      payload: {
                        menu: {
                          name: page.name,
                          disp: true,
                        },
                      },
                    });
                    history.push(page.url);
                  }}
                >
                  <div className={classes.menu}>
                    <div className={classes.menuItem}>
                      <div>
                        <i className={"fa " + page.icon} aria-hidden="true"></i>
                      </div>
                      <div className={classes.title}>{page.name}</div>
                    </div>
                  </div>
                </label>
              </div>
            )}
          </div>
        ) : (
          <div key={page.name}>
            {getDisplay(page.auth) && (
              <div>
                <label
                  className={classes.label}
                  onClick={() =>
                    menuDispatch({
                      type: MenuActionEnum.SELECT_MENU,
                      payload: {
                        menu: {
                          name: page.name,
                          disp: false,
                        },
                      },
                    })
                  }
                >
                  <div className={classes.menu}>
                    <div className={classes.menuItem}>
                      <div>
                        <i className={"fa " + page.icon} aria-hidden="true"></i>
                      </div>
                      <div className={classes.title}>{page.name}</div>
                    </div>
                    {/* <a className={classes.arrow}></a> */}
                    <div className={classes.menuItem}>
                      <div className={classes.icon}>
                        {page.name === menuState.menu.name ? (
                          <ExpandMoreIcon className={classes.icon} />
                        ) : (
                          <ChevronRightIcon className={classes.icon} />
                        )}
                      </div>
                    </div>
                  </div>
                </label>
                <input type="radio" name="radio" />
                <ul>
                  {page.children.map((child: any) => {
                    return (
                      <React.Fragment>
                        {getDisplay(child.auth) && (
                          <li
                            key={child.name}
                            className={
                              classes.li +
                              " " +
                              (menuState.menu.name === page.name
                                ? classes.liOpen
                                : classes.liClose) +
                              " " +
                              (menuState.menu.children === child.name
                                ? classes.liFocus
                                : "")
                            }
                            onClick={() => {
                              menuDispatch({
                                type: MenuActionEnum.SELECT_MENU,
                                payload: {
                                  menu: {
                                    name: page.name,
                                    children: child.name,
                                    disp: true,
                                  },
                                },
                              });
                              history.push(child.url);
                            }}
                          >
                            {`　　　${child.name}`}
                          </li>
                        )}
                      </React.Fragment>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        );
      })}
      <img
        src={kinken}
        alt=""
        width={"200px"}
        style={{
          position: "relative",
          top: "50px",
          right: "0px",
          height: "150px",
        }}
      />
    </div>
  );
};

export const SideBar = withRouter(SideBarFunc);
