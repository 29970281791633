import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  MessageBar,
  ModalUserList,
  Table,
  ViewWrapper,
} from "../../components";
import { LoginContext } from "../../stores/LoginStore";
import { DeleteApi, GetApi, PostApi } from "../../utils";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "300px auto",
    gridTemplateRows: "65px auto",
    height: "100%",
  },
  tableTitleGroup: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: theme.spacing(1),
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

type AuthData = {
  bs: string;
  name: string;
  auth_read: boolean;
  auth_approval: boolean;
  auth_import: boolean;
  auth_officer_entertainment_expenses: boolean;
  auth_balance_editing: boolean;
  auth_setting: boolean;
};

export const GeneralAffairsSettings = () => {
  const classes = useStyles();
  const { state: loginState } = useContext(LoginContext);
  const [authSettingTableLoading, setAuthSettingTableLoading] = useState(true);
  const [authData, setAuthData] = useState<AuthData[]>([]);
  const [messageBarMessage, setMessageBarMessage] = useState("");
  const [messageBarOpen, setMessageBarOpen] = useState(false);
  const [searchEmployee, setSearchEmployee] = useState(
    loginState.loginUserInfo?.dept
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalSelectedDistributorList, setModalSelectedDistributorList] =
    useState<string[]>([]);

  const onApprovalCheckBoxChange = async (e: any, row: any) => {
    const check = e.target.checked;
    const body = {
      bs: row.bs,
      auth: {
        name: "auth_approval",
        value: check,
      },
    };
    const result = await PostApi("/money-ticket/system/authority", body);
    if (result.statusCode >= 400) {
      setMessageBarMessage(result.message);
      setMessageBarOpen(true);
    } else {
      setAuthData(
        authData.map((data: AuthData) => {
          if (data.bs === row.bs) {
            data.auth_approval = check;
          }
          return data;
        })
      );
    }
  };

  const onImportCheckBoxChange = async (e: any, row: any) => {
    const check = e.target.checked;
    const body = {
      bs: row.bs,
      auth: {
        name: "auth_import",
        value: check,
      },
    };
    const result = await PostApi("/money-ticket/system/authority", body);
    if (result.statusCode >= 400) {
      setMessageBarMessage(result.message);
      setMessageBarOpen(true);
    } else {
      setAuthData(
        authData.map((data: AuthData) => {
          if (data.bs === row.bs) {
            data.auth_import = check;
          }
          return data;
        })
      );
    }
  };

  const onBalanceEditingCheckBoxChange = async (e: any, row: any) => {
    const check = e.target.checked;
    const body = {
      bs: row.bs,
      auth: {
        name: "auth_balance_editing",
        value: check,
      },
    };
    const result = await PostApi("/money-ticket/system/authority", body);
    if (result.statusCode >= 400) {
      setMessageBarMessage(result.message);
      setMessageBarOpen(true);
    } else {
      setAuthData(
        authData.map((data: AuthData) => {
          if (data.bs === row.bs) {
            data.auth_balance_editing = check;
          }
          return data;
        })
      );
    }
  };
  const onSettingsCheckBoxChange = async (e: any, row: any) => {
    const check = e.target.checked;
    const body = {
      bs: row.bs,
      auth: {
        name: "auth_setting",
        value: check,
      },
    };
    const result = await PostApi("/money-ticket/system/authority", body);
    if (result.statusCode >= 400) {
      setMessageBarMessage(result.message);
      setMessageBarOpen(true);
    } else {
      setAuthData(
        authData.map((data: AuthData) => {
          if (data.bs === row.bs) {
            data.auth_setting = check;
          }
          return data;
        })
      );
    }
  };

  const onDeleteRow = async (row: any) => {
    const result = await DeleteApi("/money-ticket/system/authority/" + row.bs);
    if (result.statusCode >= 400) {
      setMessageBarMessage(result.message);
      setMessageBarOpen(true);
    } else {
      setAuthData(authData.filter((data: AuthData) => data.bs !== row.bs));
      setModalSelectedDistributorList((prevState: any) =>
        prevState.filter((user: any) => user !== row.bs)
      );
    }
  };

  const handleSearchEmployee = (e: any) => {
    setSearchEmployee(e.target.value);
  };

  const onClickCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleAddAuthData = async (row: any) => {
    if (authData.filter((user: AuthData) => user.bs === row.bs).length > 0) {
      return;
    }

    const body = {
      bs: row.bs,
      auth: {
        name: "auth_read",
        value: true,
      },
    };
    const result = await PostApi("/money-ticket/system/authority", body);
    if (result.statusCode >= 400) {
      setMessageBarMessage(result.message);
      setMessageBarOpen(true);
    } else {
      setAuthData((prevState: AuthData[]) => [
        ...prevState,
        {
          bs: row.bs,
          name: row.name,
          auth_read: true,
          auth_approval: false,
          auth_import: false,
          auth_officer_entertainment_expenses: false,
          auth_balance_editing: false,
          auth_setting: false,
        },
      ]);
    }
    setModalSelectedDistributorList((prevState: any) => [...prevState, row.bs]);
  };

  useEffect(() => {
    const getData = async () => {
      const result = await GetApi("/money-ticket/system/authority");

      if (result.statusCode >= 400) {
        setMessageBarMessage(result.message);
        setMessageBarOpen(true);
        return;
      }
      const data: AuthData[] = result.data.map((item: any) => {
        item.auth_approval = item.auth_approval === 1 ? true : false;
        item.auth_read = item.auth_read === 1 ? true : false;
        item.auth_import = item.auth_import === 1 ? true : false;
        item.auth_officer_entertainment_expenses =
          item.auth_officer_entertainment_expenses === 1 ? true : false;
        item.auth_balance_editing =
          item.auth_balance_editing === 1 ? true : false;
        item.auth_setting = item.auth_setting === 1 ? true : false;
        item.disabled = item.bs === loginState.loginUserInfo?.bs ? true : false;
        return item;
      });
      setAuthData(data);
      setAuthSettingTableLoading(false);
      setModalSelectedDistributorList(result.data.map((item: any) => item.bs));
      // setUpdatedAt(result.data?.updatedAt);
    };

    getData();
  }, []);

  return (
    <ViewWrapper>
      <MessageBar
        open={messageBarOpen}
        setOpen={setMessageBarOpen}
        severity={"error"}
        message={messageBarMessage}
      />

      <Card>
        <div className={classes.tableTitleGroup}>
          <Button
            title="設定対象者の追加"
            onClick={() => setModalIsOpen(true)}
          />
        </div>
        <h5>※変更は即時反映されます</h5>
        <Card title={"対象者ごとの権限設定"} titleLayout={true}>
          <Table
            loading={authSettingTableLoading}
            cursor
            headers={[
              {
                name: "bs",
                disp: "bs番号",
                type: "text",
              },
              {
                name: "name",
                disp: "名前",
                type: "text",
              },
              {
                name: "auth_read",
                disp: "読み取り権限",
                type: "checkBox",
                disabled: true,
                onCheckBoxChange: () => {},
              },
              {
                name: "auth_approval",
                disp: "総務承認権限",
                type: "checkBox",
                onCheckBoxChange: onApprovalCheckBoxChange,
              },
              {
                name: "auth_import",
                disp: "金券インポート権限",
                type: "checkBox",
                onCheckBoxChange: onImportCheckBoxChange,
              },
              // {
              //   name: "auth_officer_entertainment_expenses",
              //   disp: "役員交際費申請権限",
              //   type: "checkBox",
              //   onCheckBoxChange: onOfficerEntertainmentExpensesCheckBoxChange,
              // },
              {
                name: "auth_balance_editing",
                disp: "金券残高編集権限",
                type: "checkBox",
                onCheckBoxChange: onBalanceEditingCheckBoxChange,
              },
              {
                name: "auth_setting",
                disp: "権限設定権限",
                type: "checkBox",
                onCheckBoxChange: onSettingsCheckBoxChange,
              },
              {
                name: "delete",
                disp: "削除",
                type: "delete",
              },
            ]}
            data={authData}
            onDeleteRow={onDeleteRow}
          />
        </Card>
      </Card>
      <ModalUserList
        value={searchEmployee}
        isOpen={modalIsOpen}
        selectedUsers={modalSelectedDistributorList}
        onChange={handleSearchEmployee}
        onRowDoubleClick={handleAddAuthData}
        onClickClose={onClickCloseModal}
      />
    </ViewWrapper>
  );
};
