import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  ViewWrapper,
  Button,
  Table,
  Card,
  TextBox,
  SelectBox,
  MessageBar,
} from "../../components";
import { useHistory } from "react-router-dom";
import { PostApi } from "../../utils";
import { DropzoneArea } from "material-ui-dropzone";
import XLSX from "xlsx";
import {
  ActionEnum as LoaderActionEnum,
  LoaderContext,
} from "../../stores/LoaderStore";
import { UsersContext } from "../../stores/UsersStore";
import { SelectBoxOption } from "../../components/SelectBox/SelectBox";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export const Import = () => {
  const classes = useStyles();
  const history = useHistory();
  const [messageBarMessage, setMessageBarMessage] = useState("");
  const [messageBarOpen, setMessageBarOpen] = useState(false);

  type excel = {
    bs: string;
    amounts: number;
  };

  const { dispatch: loaderDispatch } = useContext(LoaderContext);
  const { state: usersState } = useContext(UsersContext);
  const [contents, setContents] = useState("");
  const [expire, setExpire] = useState("");
  const [amounts, setamounts] = useState(0);
  const [contentsError, setContentsError] = useState("");
  const [excelData, setExcelData] = useState<excel[]>([]);

  const regist = () => {
    if (!contents) {
      setContentsError("内容を入力してください。");
      return;
    }

    const putData = async () => {
      loaderDispatch({
        type: LoaderActionEnum.SET_LOADER_TRUE,
      });

      const body = {
        contents: contents,
        expire: expire,
        amounts: amounts,
        users: excelData,
      };

      const result = await PostApi("/money-ticket/import", body);
      loaderDispatch({
        type: LoaderActionEnum.SET_LOADER_FALSE,
      });

      if (result.statusCode >= 400) {
        setMessageBarMessage(result.message);
        setMessageBarOpen(true);
      } else {
        history.push("/money-ticket/import/history");
      }
    };

    putData();
  };

  const handleChange = (files: File[]) => {
    if (files.length !== 0) {
      loaderDispatch({
        type: LoaderActionEnum.SET_LOADER_TRUE,
      });
      files[0].arrayBuffer().then((buffer: any) => {
        const workbook = XLSX.read(buffer, { type: "buffer", bookVBA: true });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const excelData = XLSX.utils.sheet_to_json(worksheet);
        const data = excelData.map((d: any) => ({
          bs: d.bs,
          amounts: d["金額"],
        }));
        let amounts = 0;
        data.map((d: any) => (amounts += d.amounts));

        setExcelData(
          data.map((d: any) => {
            const bs = usersState.users.filter(
              (user: any) => user.bs === d["bs"]
            );
            d.name = bs.length > 0 ? bs[0].name : "";
            d.dept = bs.length > 0 ? bs[0].dept : "";
            return d;
          })
        );
        setamounts(amounts);
        loaderDispatch({
          type: LoaderActionEnum.SET_LOADER_FALSE,
        });
      });
    }
  };

  const onClickImportHistory = () => {
    history.push("/money-ticket/import/history");
  };

  const onChangeContents = (e: any) => {
    setContents(e.target.value);
  };

  const now = moment().utc().add(9, "h");
  const year = now.format("YYYY");
  const month = now.format("MM");
  const options: SelectBoxOption[] = [];
  if (month === "02" || month === "03" || month === "04") {
    options.push({
      code: moment({ years: year, months: 4 - 1, days: 1 })
        .endOf("month")
        .format("YYYY-MM-DD"),
      name: moment({ years: year, months: 4 - 1, days: 1 })
        .endOf("month")
        .format("YYYY-MM-DD"),
    });
    options.push({
      code: moment({ years: year, months: 7 - 1, days: 1 })
        .endOf("month")
        .format("YYYY-MM-DD"),
      name: moment({ years: year, months: 7 - 1, days: 1 })
        .endOf("month")
        .format("YYYY-MM-DD"),
    });
  } else if (month === "05" || month === "06" || month === "07") {
    options.push({
      code: moment({ years: year, months: 7 - 1, days: 1 })
        .endOf("month")
        .format("YYYY-MM-DD"),
      name: moment({ years: year, months: 7 - 1, days: 1 })
        .endOf("month")
        .format("YYYY-MM-DD"),
    });
    options.push({
      code: moment({ years: year, months: 10 - 1, days: 1 })
        .endOf("month")
        .format("YYYY-MM-DD"),
      name: moment({ years: year, months: 10 - 1, days: 1 })
        .endOf("month")
        .format("YYYY-MM-DD"),
    });
  } else if (month === "08" || month === "09" || month === "10") {
    options.push({
      code: moment({ years: year, months: 10 - 1, days: 1 })
        .endOf("month")
        .format("YYYY-MM-DD"),
      name: moment({ years: year, months: 10 - 1, days: 1 })
        .endOf("month")
        .format("YYYY-MM-DD"),
    });
    options.push({
      code: moment({ years: Number(year) + 1, months: 1 - 1, days: 1 })
        .endOf("month")
        .format("YYYY-MM-DD"),
      name: moment({ years: Number(year) + 1, months: 1 - 1, days: 1 })
        .endOf("month")
        .format("YYYY-MM-DD"),
    });
  } else if (month === "11" || month === "12") {
    options.push({
      code: moment({ years: Number(year) + 1, months: 1 - 1, days: 1 })
        .endOf("month")
        .format("YYYY-MM-DD"),
      name: moment({ years: Number(year) + 1, months: 1 - 1, days: 1 })
        .endOf("month")
        .format("YYYY-MM-DD"),
    });
    options.push({
      code: moment({ years: Number(year) + 1, months: 4 - 1, days: 1 })
        .endOf("month")
        .format("YYYY-MM-DD"),
      name: moment({ years: Number(year) + 1, months: 4 - 1, days: 1 })
        .endOf("month")
        .format("YYYY-MM-DD"),
    });
  } else if (month === "01") {
    options.push({
      code: moment({ years: Number(year), months: 1 - 1, days: 1 })
        .endOf("month")
        .format("YYYY-MM-DD"),
      name: moment({ years: Number(year), months: 1 - 1, days: 1 })
        .endOf("month")
        .format("YYYY-MM-DD"),
    });
    options.push({
      code: moment({ years: Number(year), months: 4 - 1, days: 1 })
        .endOf("month")
        .format("YYYY-MM-DD"),
      name: moment({ years: Number(year), months: 4 - 1, days: 1 })
        .endOf("month")
        .format("YYYY-MM-DD"),
    });
  }

  const onSelectChange = (e: any) => {
    setExpire(e.target.value);
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setExpire(options[0].code);
  }, []);

  return (
    <ViewWrapper>
      <MessageBar
        open={messageBarOpen}
        setOpen={setMessageBarOpen}
        severity={"error"}
        message={messageBarMessage}
      />

      {excelData.length === 0 && (
        <div className={classes.buttonGroup}>
          <Button
            title="インポート履歴"
            variant="contained"
            onClick={onClickImportHistory}
          />
        </div>
      )}
      <Card title={"データアップローダ"} titleLayout={true}>
        {excelData.length === 0 ? (
          <DropzoneArea onChange={handleChange} />
        ) : (
          <Fragment>
            <TextBox
              title={"タイトル"}
              value={contents}
              error={contentsError}
              onChange={onChangeContents}
            />
            <SelectBox
              value={expire}
              title={"有効期限"}
              options={options}
              onChange={(e: any) => onSelectChange(e)}
            />
            <TextBox
              title={"合計金額"}
              value={`¥ ${Number(amounts).toLocaleString()}`}
            />
            <Table
              headers={[
                {
                  name: "bs",
                  disp: "bs番号",
                  type: "text",
                },
                {
                  name: "name",
                  disp: "名前",
                  type: "text",
                },
                {
                  name: "dept",
                  disp: "部署",
                  type: "text",
                },
                {
                  name: "amounts",
                  disp: "金額",
                  type: "moneyText",
                },
              ]}
              data={excelData}
            />
          </Fragment>
        )}
      </Card>
      {excelData.length !== 0 && (
        <div className={classes.buttonGroup}>
          <Button title="取り込み実行" variant="contained" onClick={regist} />
          <Button
            title="キャンセル"
            variant="outlined"
            onClick={() => setExcelData([])}
          />
        </div>
      )}
    </ViewWrapper>
  );
};
