import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  Auth,
  IdpCallback,
  Dashboard,
  Apply,
  ApplyCreate,
  ApplyDetail,
  Approval,
  BalanceEditing,
  BalanceEditingCreate,
  GeneralAffairs,
  GeneralAffairsSettings,
  GeneralSearch,
  Import,
  ImportHistory,
  ImportHistoryDetail,
  Transfer,
  TransferCreate,
  NotFoundPage,
  Settings,
  UsageData,
} from "./views";
import MainLayout from "./components/MainLayout/MainLayout";
import { Theme } from "./Theme";
import {
  GlobalProvider,
  LoaderProvider,
  MenuProvider,
  JudgmentProvider,
  LoginProvider,
  UsersProvider,
  ThemeProvider,
  NotificationProvider,
} from "./stores";

export const App = (props: any) => {
  return (
    <GlobalProvider>
      <NotificationProvider>
        <LoginProvider>
          <UsersProvider>
            <ThemeProvider>
              <LoaderProvider>
                <MenuProvider>
                  <JudgmentProvider>
                    <Router>
                      <Switch>
                        <Route
                          exact
                          path="/idpcallback"
                          component={IdpCallback}
                        />
                        <Auth>
                          <Theme>
                            <Switch>
                              <MainLayout>
                                <Switch>
                                  <Route
                                    exact
                                    path="/dashboard"
                                    component={Dashboard}
                                  />
                                  <Route
                                    exact
                                    path="/money-ticket/apply"
                                    component={Apply}
                                  />
                                  <Route
                                    exact
                                    path="/money-ticket/apply/create"
                                    component={ApplyCreate}
                                  />
                                  <Route
                                    exact
                                    path="/money-ticket/approval"
                                    component={Approval}
                                  />
                                  <Route
                                    exact
                                    path="/money-ticket/import"
                                    component={Import}
                                  />
                                  <Route
                                    exact
                                    path="/money-ticket/import/history"
                                    component={ImportHistory}
                                  />
                                  <Route
                                    path="/money-ticket/import/history/:id"
                                    component={ImportHistoryDetail}
                                  />
                                  <Route
                                    path="/money-ticket/apply/:id"
                                    component={ApplyDetail}
                                  />
                                  <Route
                                    exact
                                    path="/money-ticket/transfer"
                                    component={Transfer}
                                  />
                                  <Route
                                    exact
                                    path="/money-ticket/transfer/create"
                                    component={TransferCreate}
                                  />
                                  <Route
                                    exact
                                    path="/money-ticket/settings"
                                    component={GeneralAffairsSettings}
                                  />
                                  <Route
                                    exact
                                    path="/settings"
                                    component={Settings}
                                  />
                                  <Route
                                    exact
                                    path="/money-ticket/general-affairs"
                                    component={GeneralAffairs}
                                  />
                                  <Route
                                    exact
                                    path="/money-ticket/usage-data"
                                    component={UsageData}
                                  />
                                  <Route
                                    exact
                                    path="/money-ticket/balance-editing"
                                    component={BalanceEditing}
                                  />
                                  <Route
                                    exact
                                    path="/money-ticket/balance-editing/create"
                                    component={BalanceEditingCreate}
                                  />
                                  
                                  <Route
                                    exact
                                    path="/money-ticket/general-search"
                                    component={GeneralSearch}
                                  />

                                  <Route component={NotFoundPage} />
                                </Switch>
                              </MainLayout>
                            </Switch>
                          </Theme>
                        </Auth>
                      </Switch>
                    </Router>
                  </JudgmentProvider>
                </MenuProvider>
              </LoaderProvider>
            </ThemeProvider>
          </UsersProvider>
        </LoginProvider>
      </NotificationProvider>
    </GlobalProvider>
  );
};
