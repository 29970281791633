import React, { ReactNode, useReducer } from "react";

type StateType = {
  notification?: {
    message: string;
    severity: string;
  };
};

export enum ActionEnum {
  SET_NOTIFICATION = "SET_NOTIFICATION",
}

type ActionType = {
  type: ActionEnum;
  payload?: any;
};

type ContextType = {
  state: StateType;
  dispatch: React.Dispatch<ActionType>;
};

const initialState = {
  notification: {
    message: "",
    severity: "",
  },
};

export const reducer = (state: StateType, action: ActionType) => {
  switch (action.type) {
    case ActionEnum.SET_NOTIFICATION:
      return {
        ...state,
        notification: {
          message: action.payload?.message,
          severity: action.payload?.severity,
        },
      };
    default:
      return { ...state };
  }
};
interface IProps {
  children: ReactNode;
}
export const NotificationContext = React.createContext({} as ContextType);
export const NotificationProvider = ({ children }: IProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <NotificationContext.Provider value={{ state, dispatch }}>
      {children}
    </NotificationContext.Provider>
  );
};
