import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { ViewWrapper, Card, MoneyTicket as MT, Table } from "../../components";
import { useHistory } from "react-router-dom";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import shinsei from "../../assets/images/shinsei.png";
import dashboardLogo from "../../assets/images/dashboardLogo.png";
import Grid from "@material-ui/core/Grid";
import { statusBackgroundColor } from "../../definitions/const";
import { useGetApi } from "../../hooks/useGetApi";
import { Chip } from "@material-ui/core";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    alignContent: "center",
  },
  gridNotify: {
    width: "170px",
  },
  notifyCount: {
    fontSize: "43px",
  },
}));

type DashboardProps = {
  width: "xs" | "sm" | "md" | "lg" | "xl";
};

const Dashboard = ({ width }: DashboardProps) => {
  const classes = useStyles();
  const history = useHistory();
  const [moneyTicket, setMoneyTicket] = useState(0);
  const [moneyTicket2, setMoneyTicket2] = useState(0);
  const [moneyTicketExpire1, setMoneyTicketExpire1] = useState("");
  const [moneyTicketExpire2, setMoneyTicketExpire2] = useState("");
  const [queries, setQueries] = useState({});
  const {
    data: historyData,
    isLoading: isLoadingHistoryData,
    moreScroll,
  } = useGetApi({
    type: "scroll",
    url: "/money-ticket/history",
    queries: queries,
  });
  const [customizedHistoryData, setCustomizedHistoryData]: any = useState([]);
  const { data: balanceData, isLoading: isLoadingBalanceData } = useGetApi({
    type: "get",
    url: "/money-ticket/balance",
  });
  const { data: applovalCountData, isLoading: isLoadingApplovalCountData } =
    useGetApi({
      type: "get",
      url: "/money-ticket/approval/count?status=UNDECIDED",
    });

  /**
   * 履歴の行をクリックしたときの画面遷移
   */
  const onRowClick = (row: any) => {
    let typeCode = "";
    if (row.type === "金券精算申請") {
        typeCode = "apply";      
    } else if (row.type === "受け取り") {
      typeCode = "transfer";
    } else if (row.type === "受け渡し") {
      typeCode = "transfer";
    } else if (row.type === "インポート") {
      typeCode = "import";
    } else if (row.type === "インセンティブ") {
      typeCode = "incentive";
    }
    if (typeCode === "apply" || typeCode === "incentive") {
      history.push("/money-ticket/apply?type=approval&selectedId=" + row.id);
    }
    if (typeCode === "transfer") {
      history.push("/money-ticket/transfer?type=transfer&selectedId=" + row.id);
    }
  };

  const onScrolled = async () => {
    setQueries({
      offset: historyData.length,
    });
  };

  useEffect(() => {
    let dispData = historyData.map((data: any) => {
      if (data.type === "受け取り" || data.type === "インポート") {
        data.icon = "fa fa-plus-square";
        data.iconColor = "green";
      } else if (
        data.type === "受け渡し" ||
        data.type === "金券精算申請" ||
        data.type === "インセンティブ"
      ) {
        data.icon = "fa fa-minus-square";
        data.iconColor = "red";
      }

      if (data.type === "インポート") {
        data.cursor = "default";
      } else {
        data.cursor = "pointer";
      }
      return data;
    });

    setCustomizedHistoryData(dispData);
  }, [historyData]);

  useEffect(() => {
    setMoneyTicket(balanceData.length > 0 ? balanceData[0].amounts : 0);
    setMoneyTicketExpire1(
      balanceData.length > 0
        ? `有効期限：${balanceData[0].expire}`
        : "有効期限：-"
    );
    setMoneyTicket2(balanceData.length > 1 ? balanceData[1].amounts : 0);
    setMoneyTicketExpire2(
      balanceData.length > 1
        ? `有効期限：${balanceData[1].expire}`
        : "有効期限：-"
    );
  }, [balanceData]);

  return (
    <ViewWrapper>
      <Grid container direction="row">
        {isWidthUp("sm", width) && (
          <img
            src={dashboardLogo}
            alt=""
            width={"310px"}
            style={{
              position: "relative",
              top: "10px",
              right: "0px",
              height: "150px",
            }}
          />
        )}
        <Card
          title={"あなたの判定待ち"}
          titleLayout={true}
          loading={isLoadingApplovalCountData}
        >
          <Grid
            container
            className={classes.root}
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid
              item
              className={classes.gridNotify}
              onClick={() => {
                history.push("/money-ticket/approval");
              }}
              style={{ cursor: "pointer" }}
            >
              <Card>
                金券精算申請
                <Grid
                  container
                  className={classes.root}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                  spacing={1}
                >
                  <Grid item>
                    <img
                      src={shinsei}
                      alt=""
                      width={"35px"}
                      style={{
                        position: "relative",
                        top: "2px",
                        right: "5px",
                      }}
                    />
                  </Grid>
                  <Grid item className={classes.notifyCount}>
                    {applovalCountData.count}
                  </Grid>
                  <Grid item>件</Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Card>
        <Card title={"所有金券"} titleLayout={true}>
          <Grid
            container
            className={classes.root}
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={2}
          >
            {moneyTicket2 > 0 && (
              <Grid item style={{ minWidth: "300px" }}>
                <MT
                  loading={isLoadingBalanceData}
                  maxWidth={"400px"}
                  color={"red"}
                  expire={moneyTicketExpire2}
                  amounts={Number(moneyTicket2).toLocaleString()}
                >
                  {""}
                </MT>
              </Grid>
            )}
            <Grid item style={{ minWidth: "300px" }}>
              <MT
                loading={isLoadingBalanceData}
                maxWidth={"400px"}
                expire={moneyTicketExpire1}
                amounts={Number(moneyTicket).toLocaleString()}
              >
                {""}
              </MT>
            </Grid>
          </Grid>
        </Card>
      </Grid>

      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} sm={12}>
          <Card title={"履歴"} titleLayout={true}>
            <Table
              loading={isLoadingHistoryData}
              cursor
              headers={[
                {
                  name: "apply_category",
                  disp: "申請種別",
                  type: "custom",
                  component: (
                    rowIndex: any,
                    index: any,
                    header: any,
                    rowData: any
                  ) => {
                    return (
                      <td key={index}>
                        <div>
                          <h3>
                            {!rowData["created_at"]
                              ? ""
                              : moment(rowData["created_at"]).format(
                                  "YYYY年MM月DD日"
                                )}
                          </h3>

                          <p>{rowData["display_apply_id"]}</p>
                          <p>{rowData["display_type"]}</p>
                          <p>{rowData["contents"]}</p>
                          {rowData["expire1"] && (
                            <Chip
                              // variant="outlined"
                              size="small"
                              label={`有効期限：${rowData["expire1"]}`}
                              style={{ fontSize: "5px", cursor: "pointer" }}
                            />
                          )}
                          <p>
                            {rowData["expire2"] && (
                              <Chip
                                // variant="outlined"
                                size="small"
                                label={`有効期限：${rowData["expire2"]}`}
                                style={{ fontSize: "5px", cursor: "pointer" }}
                              />
                            )}
                          </p>
                        </div>
                      </td>
                    );
                  },
                },
                {
                  name: "amounts",
                  disp: "金額",
                  type: "coloredMoneyText",
                },
                {
                  name: "status",
                  disp: "ステータス",
                  type: "label",
                  maxWidth: "100px",
                  background: statusBackgroundColor,
                },
              ]}
              data={customizedHistoryData}
              onRowClick={onRowClick}
              onScrolled={onScrolled}
              moreScroll={moreScroll}
            />
          </Card>
        </Grid>
      </Grid>
    </ViewWrapper>
  );
};

export default withWidth()(Dashboard);
