import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextBox, SelectBox, TextArea } from "..";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { Skeleton } from "@material-ui/lab";
import { Checkbox } from "@material-ui/core";
import DetailsIcon from "@material-ui/icons/Details";
import { useTheme } from "@material-ui/core/styles";
import currencySymbol from "../../assets/images/currencySymbol.png";
const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: "collapse",
    borderSpacing: "0",
    width: "100%",
    maxHeight: "500px",
    overflow: "scroll",
    "& tr, & td": {
      textAlign: "left",
      padding: "8px 4px",
    },
    "& td.icon": {
      backgroundSize: "35px",
      backgroundPosition: "left 5px center",
      backgroundRepeat: "no-repeat",
      paddingLeft: "30px",
    },
  },
  th: {
    background: theme.palette.lpGray.light,
    padding: "12px 8px",
    borderBottom: "solid 1px #eee",
    position: "sticky",
    top: "0",
  },
  tr: {
    background: theme.palette.lpWhite.main,
    borderBottom: "solid 1px #eee",
    "&:hover": {
      backgroundColor: theme.palette.lpWhite.dark,
    },
  },
  trCursor: {
    cursor: "pointer",
  },
  trSelectedRow: {
    backgroundColor: theme.palette.lpSecond.light,
    "&:hover": {
      backgroundColor: theme.palette.lpSecond.light,
    },
  },
  iconItem: {
    display: "inline-flex",
    verticalAlign: "middle",
  },
  iconGroup: {
    display: "inline-flex",
    width: "100%",
    padding: theme.spacing(1),
    alignItems: "center",
  },
  iconCircle: {
    width: "30px",
    height: "30px",
    borderRadius: "15px",
    alignItems: "center",
    textAlign: "center",
  },
  icon: {
    marginTop: "6px",
    fontSize: "1.8em",
    color: theme.palette.lpSecond.light,
  },
  iconText: {
    alignSelf: "center",
    padding: "0 " + theme.spacing(1) + "px",
  },
  textBox: {
    padding: "0",
  },
  skeleton: {
    margin: "3px 0",
  },
  loader: {
    color: "#ffffff",
    fontSize: "10px",
    // margin: "calc(100vh / 2 - 50px) auto auto auto",
    position: "relative",
    textIndent: "-9999em",
    transform: "translateZ(0)",
    animationDelay: "-0.16s",
    borderRadius: "50%",
    width: "2em",
    height: "2em",
    animationFillMode: "both",
    animation: "load7 1.8s infinite ease-in-out",
    "&:before": {
      borderRadius: "50%",
      width: "2em",
      height: "2em",
      animationFillMode: "both",
      animation: "load7 1.8s infinite ease-in-out",
      content: "",
      position: "absolute",
      top: "0",
      left: "-3.5em",
      animationDelay: "-0.32s",
    },
    "&:after": {
      borderRadius: "50%",
      width: "2em",
      height: "2em",
      animationFillMode: "both",
      animation: "load7 1.8s infinite ease-in-out",
      content: "",
      position: "absolute",
      top: "0",
      left: "3.5em",
    },
  },
  label: {
    borderRadius: "20px 20px 20px 20px",
    // background: "cornflowerblue",
    color: "white",
    padding: "4px",
    textAlign: "center",
  },
  checkBox: {
    color: theme.palette.lpFirst.main,
  },
  checkBoxDisabled: {
    color: theme.palette.lpFirst.main,
  },
  loading: {
    animation: "blink 0.5s ease-in-out infinite alternate",
  },
}));

type TableData = {
  [key: string]: any;
};

type TableProps = {
  headers: { [key: string]: any }[];
  data: TableData[];
  filter?: string;
  loading?: boolean;
  cursor?: boolean;
  moreScroll?: boolean;
  onRowClick?: (row: TableData) => void;
  onDeleteRow?: (row: TableData) => void;
  onScrolled?: () => Promise<any>;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>, row: TableData) => void;
};

export const DashboardHistoryTable: React.FC<TableProps> = ({
  headers = [],
  data = [],
  filter = "",
  loading = false,
  cursor = false,
  moreScroll = false,
  onRowClick = () => {},
  onDeleteRow = () => {},
  onScrolled = async () => {},
  onChange = () => {},
}: TableProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const [moreScrollLoading, setMoreScrollLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(-1);

  if (filter) {
    data = data.filter((item: any) => {
      for (let i in item) {
        if (item[i].indexOf(filter) !== -1) {
          return true;
        }
      }
      return false;
    });
  }

  const handleScroll = (e: any) => {
    const scrolled = async () => {
      const bottom =
        e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight;
      console.log(bottom);
      if (bottom) {
        setMoreScrollLoading(true);
        await onScrolled();
        setMoreScrollLoading(false);
      }
    };
    scrolled();
  };

  useEffect(() => {
    if (selectedRow >= data.length) {
      setSelectedRow(data.length - 1);
    }
  }, [data, selectedRow]);

  return (
    <Fragment>
      {loading ? (
        <div>
          <Skeleton className={classes.skeleton} variant="rect" height={30} />
          <Skeleton className={classes.skeleton} variant="rect" height={40} />
          <Skeleton className={classes.skeleton} variant="rect" height={40} />
          <Skeleton className={classes.skeleton} variant="rect" height={40} />
          <Skeleton className={classes.skeleton} variant="rect" height={40} />
        </div>
      ) : (
        <div
          style={{ maxHeight: "600px", overflow: "scroll" }}
          onScroll={handleScroll}
        >
          <table id="table" className={classes.table}>
            <thead>
              {/* <tr>
                {headers?.map((header: any, index: number) => {
                  return (
                    <th key={index} className={classes.th}>
                      {header.disp}
                    </th>
                  );
                })}
              </tr> */}
            </thead>
            {data.length === 0 ? (
              <tbody>
                <tr>
                  <td
                    colSpan={Number(headers.length)}
                    style={{ textAlign: "center" }}
                  >
                    データがありません。
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {data.map((d: any, rowIndex: number) => {
                  return (
                    <tr
                      key={rowIndex}
                      className={
                        classes.tr +
                        " " +
                        (cursor && classes.trCursor) +
                        " " +
                        (cursor &&
                          selectedRow === rowIndex &&
                          classes.trSelectedRow)
                      }
                      onClick={() => {
                        onRowClick(d);
                        setSelectedRow(rowIndex);
                      }}
                    >
                      {headers?.map((header: any, index: number) => {
                        return header.type === "iconText" ? (
                          <td key={index}>
                            <div className={classes.iconText}>
                              <h3>
                                {!d["created_at"]
                                  ? ""
                                  : moment(d["created_at"]).format(
                                      "YYYY年MM月DD日"
                                    )}
                              </h3>

                              <p>{d["type"]}</p>
                              <p>{d["contents"]}</p>
                            </div>
                          </td>
                        ) : header.type === "text" ? (
                          <td
                            key={index}
                            id={String(header.name) + "_" + String(rowIndex)}
                          >
                            {d[header.name]}
                          </td>
                        ) : header.type === "moneyText" ? (
                          <td key={index} className={classes.iconGroup}>
                            {isNaN(d[header.name]) ? (
                              d[header.name]
                            ) : (
                              <Fragment>
                                <div className={classes.iconCircle}>
                                  <i
                                    className={d.icon + " " + classes.icon}
                                    style={
                                      d.iconColor === "green"
                                        ? { color: "#76E59C" }
                                        : d.iconColor === "red"
                                        ? { color: "#E76A7C" }
                                        : {}
                                    }
                                  />
                                </div>
                                <img
                                  src={currencySymbol}
                                  alt=""
                                  width={"9px"}
                                  style={{
                                    position: "relative",
                                    top: "1px",
                                    right: "2px",
                                  }}
                                />
                                <h2
                                  style={
                                    d["type"] === "インポート" ||
                                    d["type"] === "受け取り"
                                      ? {
                                          color: "#42C13E",
                                          display: "inline-flex",
                                        }
                                      : {
                                          color: "#E76A7C",
                                          display: "inline-flex",
                                        }
                                  }
                                >
                                  {Number(d[header.name]).toLocaleString()}
                                </h2>
                              </Fragment>
                            )}
                          </td>
                        ) : header.type === "textBox" ? (
                          <td key={index}>
                            <TextBox
                              id={String(header.name) + "_" + String(rowIndex)}
                              value={d[header.name]}
                              maxWidth={
                                header.maxWidth ? header.maxWidth : "100px"
                              }
                              onChange={(e: any) => header.onTextChange(e, d)}
                            />
                          </td>
                        ) : header.type === "textArea" ? (
                          <td key={index}>
                            <TextArea
                              id={String(header.name) + "_" + String(rowIndex)}
                              value={d[header.name]}
                              maxWidth={
                                header.maxWidth ? header.maxWidth : "100px"
                              }
                              onChange={(e: any) => header.onTextChange(e, d)}
                            />
                          </td>
                        ) : header.type === "selectBox" ? (
                          <td key={index}>
                            <SelectBox
                              value={d[header.name]}
                              options={header.options}
                              maxWidth={
                                header.maxWidth ? header.maxWidth : "200px"
                              }
                              onChange={(e) => header.onChange(e, d)}
                            />
                          </td>
                        ) : header.type === "checkBox" ? (
                          <td key={index}>
                            <Checkbox
                              disabled={header.disabled}
                              checked={d[header.name]}
                              onChange={(e: any) =>
                                header.onCheckBoxChange(e, d)
                              }
                              // className={classes.checkBox}
                              style={
                                header.disabled
                                  ? { color: "#9C9391" }
                                  : { color: theme.palette.lpFirst.dark }
                              }
                            />
                          </td>
                        ) : header.type === "label" ? (
                          <td key={index}>
                            <div
                              id={String(header.name) + "_" + String(rowIndex)}
                              className={classes.label}
                              style={{
                                maxWidth: header.maxWidth,
                                background: header.background[d[header.name]],
                              }}
                            >
                              {d[header.name]}
                            </div>
                          </td>
                        ) : header.type === "datetime" ? (
                          <td key={index}>
                            {!d[header.name]
                              ? ""
                              : moment(d[header.name]).format(
                                  "YYYY年MM月DD日 HH時mm分"
                                )}
                          </td>
                        ) : header.type === "detail" ? (
                          <td key={index} className={classes.iconGroup}>
                            <IconButton
                              aria-label="detail"
                              onClick={(e: any) => header.onClick(e, d)}
                            >
                              <DetailsIcon />
                            </IconButton>
                          </td>
                        ) : header.type === "delete" ? (
                          <td key={index} className={classes.iconGroup}>
                            <IconButton
                              id={String(header.name) + "_" + String(rowIndex)}
                              aria-label="delete"
                              onClick={() => onDeleteRow(d)}
                            >
                              <DeleteIcon />
                            </IconButton>
                            <div>
                              {/* <i className={"fa fa-trash-o " + classes.icon} onClick={() => onDeleteRow(d)} /> */}
                            </div>
                          </td>
                        ) : (
                          <td key={index}></td>
                        );
                      })}
                    </tr>
                  );
                })}
                {moreScroll ? (
                  <Fragment>
                    {moreScrollLoading ? (
                      <tr>
                        <td
                          className={classes.loading}
                          colSpan={Number(headers.length)}
                          style={{ textAlign: "center" }}
                        >
                          loading...
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td
                          colSpan={Number(headers.length)}
                          style={{ textAlign: "center" }}
                        >
                          データをロードします。
                        </td>
                      </tr>
                    )}
                  </Fragment>
                ) : (
                  <tr>
                    <td
                      colSpan={Number(headers.length)}
                      style={{ textAlign: "center" }}
                    >
                      これ以上データはありません。
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
      )}
    </Fragment>
  );
};
