import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useState } from "react";
import {
  ViewWrapper,
  Button,
  Table,
  Card,
  MessageBar,
  ModalUserList,
} from "../../components";
import { useHistory } from "react-router-dom";
import { PostApi } from "../../utils";
import {
  LoaderContext,
  ActionEnum as LoaderActionEnum,
} from "../../stores/LoaderStore";
import { LoginContext } from "../../stores/LoginStore";

const useStyles = makeStyles((theme) => ({
  tableTitleGroup: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: theme.spacing(1),
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
  },
  moneyGroup: {
    display: "flex",
  },
  money: {
    padding: "0 20px 0 20px",
    fontSize: "50px",
    color: "#A3A0F5",
  },
}));

type Distribution = {
  bs: string;
  name: string;
  dept: string;
  amounts: string | number;
  purpose: string;
};

export const TransferRequestCreate = () => {
  const classes = useStyles();
  const history = useHistory();
  const { dispatch: loaderDispatch } = useContext(LoaderContext);
  const { state: loginState } = useContext(LoginContext);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [searchEmployee, setSearchEmployee] = useState("");
  const [distributionData, setDistributionData] = useState<Distribution[]>([]);
  const [modalSelectedDistributorList, setModalSelectedDistributorList] =
    useState<string[]>([]);
  const [messageBarMessage, setMessageBarMessage] = useState("");
  const [messageBarOpen, setMessageBarOpen] = useState(false);

  const regist = () => {
    if (distributionData.length === 0) {
      setMessageBarMessage("データを入力してください。");
      setMessageBarOpen(true);
      return;
    }

    for (const data of distributionData) {
      if (isNaN(Number(data.amounts))) {
        setMessageBarMessage("金額は数値を入力してください。");
        setMessageBarOpen(true);
        return;
      }
      if (Number(data.amounts) === 0) {
        setMessageBarMessage("金額は０より大きい値を入力してください。");
        setMessageBarOpen(true);
        return;
      }
      if (data.purpose.length === 0) {
        setMessageBarMessage("目的を入力してください。");
        setMessageBarOpen(true);
        return;
      }
      if (data.purpose.length > 200) {
        setMessageBarMessage("目的は200文字以下で入力してください。");
        setMessageBarOpen(true);
        return;
      }
    }

    if (
      distributionData.filter(
        (user) => user.bs === loginState.loginUserInfo?.bs
      ).length > 0
    ) {
      setMessageBarMessage("自身を指定することはできません。");
      setMessageBarOpen(true);
      return;
    }

    if (
      distributionData.filter(
        (user1) =>
          distributionData.filter((user2) => user1.bs === user2.bs).length > 1
      ).length > 0
    ) {
      setMessageBarMessage("同じユーザが含まれています。");
      setMessageBarOpen(true);
      return;
    }

    const putData = async () => {
      loaderDispatch({
        type: LoaderActionEnum.SET_LOADER_TRUE,
      });

      const body = distributionData.map((d: any) => {
        d.amounts = Number(d.amounts);
        return d;
      });

      const result = await PostApi("/money-ticket/transfer-request", body);

      loaderDispatch({
        type: LoaderActionEnum.SET_LOADER_FALSE,
      });

      if (result.statusCode >= 400) {
        setMessageBarMessage(result.message);
        setMessageBarOpen(true);
      } else {
        history.push("/money-ticket/transfer");
      }
    };

    putData();
  };

  const handleSearchEmployee = (e: any) => {
    setSearchEmployee(e.target.value);
  };

  const handleAddDistributionData = (row: any) => {
    if (
      distributionData.filter((x: Distribution) => x.bs === row.bs).length > 0
    ) {
      return;
    }
    if (row.bs === loginState.loginUserInfo?.bs) {
      return;
    }

    setDistributionData((prevState: any) => [
      ...prevState,
      {
        bs: row.bs,
        name: row.name,
        dept: row.dept,
        amounts: 0,
        purpose: "",
      },
    ]);
    setModalSelectedDistributorList((prevState: any) => [...prevState, row.bs]);
  };

  const onDeleteRow = (row: any) => {
    setDistributionData(distributionData.filter((d: any) => d.bs !== row.bs));
    setModalSelectedDistributorList((prevState: any) =>
      prevState.filter((user: any) => user !== row.bs)
    );
  };

  const onTextChange = (e: any, row: any) => {
    setDistributionData(
      distributionData.map((d: any) => {
        if (d.bs === row.bs) {
          d.amounts = e.target.value;
        }
        return d;
      })
    );
  };

  const onTextChangePurpose = (e: any, row: any) => {
    setDistributionData(
      distributionData.map((d: any) => {
        if (d.bs === row.bs) {
          d.purpose = e.target.value;
        }
        return d;
      })
    );
  };

  return (
    <ViewWrapper>
      <h2>おねだり</h2>
      <Card loading={false}>
        <div className={classes.tableTitleGroup}>
          <Button
            title="追加"
            variant="contained"
            disabled={false}
            onClick={() => setIsOpen(true)}
          />
        </div>
        <Table
          headers={[
            {
              name: "name",
              disp: "名前",
              type: "text",
            },
            {
              name: "dept",
              disp: "部門",
              type: "text",
            },
            {
              name: "amounts",
              disp: "おねだり金額",
              type: "textBox",
              onTextChange: onTextChange,
            },
            {
              name: "purpose",
              disp: "目的",
              type: "textArea",
              maxWidth: "300px",
              onTextChange: onTextChangePurpose,
            },
            {
              name: "delete",
              disp: "削除",
              type: "delete",
            },
          ]}
          data={distributionData}
          onDeleteRow={onDeleteRow}
        />
      </Card>

      {!false && (
        <div className={classes.buttonGroup}>
          <Button
            title="決定"
            variant="contained"
            onClick={regist}
            disabled={false}
          />
          <Button
            title="キャンセル"
            variant="outlined"
            onClick={() => history.push("/money-ticket/transfer")}
          />
        </div>
      )}

      <ModalUserList
        value={searchEmployee}
        isOpen={modalIsOpen}
        selectedUsers={modalSelectedDistributorList}
        onChange={handleSearchEmployee}
        onRowDoubleClick={handleAddDistributionData}
        onClickClose={() => setIsOpen(false)}
      />

      <MessageBar
        open={messageBarOpen}
        setOpen={setMessageBarOpen}
        severity={"error"}
        message={messageBarMessage}
      />
    </ViewWrapper>
  );
};
