import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  viewContainer: {
    animation: "fadeIn 1.5s ease 0s 1 normal",
    height: "100vh",
  },
}));

type ViewWrapperProps = {
  children: React.ReactNode;
};

export const ViewWrapper: React.FC<ViewWrapperProps> = ({
  children,
}: ViewWrapperProps) => {
  const classes = useStyles();

  return <div className={classes.viewContainer}>{children}</div>;
};
