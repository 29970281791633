import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment, useEffect, useState } from "react";
import { ViewWrapper, Button, Card, MoneyTicket } from "../../components";
import { useHistory } from "react-router-dom";
import { GetApi } from "../../utils";
import Typography from "@material-ui/core/Typography";
const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: "scroll",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
  },
  moneyGroup: {
    display: "flex",
  },
  money: {
    padding: "0 20px 0 20px",
    fontSize: "50px",
    color: "#A3A0F5",
  },
  title: {
    color: theme.palette.grey[400],
  },
}));

export const TransferDetail = (props: any) => {
  // クエリパラメータの取得
  const { id, setId, setTabValue } = props;
  // 定数定義
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [createdAt, setCreatedAt] = useState("");
  const [totalamounts, setTotalamounts] = useState(0);
  const [type, setType] = useState("");
  const [fromName, setFromName] = useState("");
  const [toName, setToName] = useState("");
  const [comment, setComment] = useState("");
  const [purpose, setPurpose] = useState("");
  const [expire, setExpire] = useState("");

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const fetchData = async () => {
      if (!id) {
        return;
      }
      setLoading(true);
      const result = await GetApi("/money-ticket/transfer/" + id);
      if (result.data) {
        setTotalamounts(result.data?.amounts);
        setCreatedAt(result.data?.created_at);
        setType(result.data?.type);
        setFromName(
          result.data?.from_name + "(" + result.data?.from_dept + ")"
        );
        setToName(result.data?.to_name + "(" + result.data?.to_dept + ")");
        setComment(result.data?.comment);
        setPurpose(result.data?.purpose);
        setExpire(result.data?.expire);
      } else {
        history.push("/notfound");
      }
      setLoading(false);
    };

    fetchData();
  }, [id]);

  return (
    <ViewWrapper>
      <div className={classes.container}>
        <Fragment>
          <h2>金券{type}</h2>
          <MoneyTicket
            loading={loading}
            maxWidth={"300px"}
            expire={`有効期限：${expire}`}
            amounts={Number(totalamounts).toLocaleString()}
          >
            {/* 金額
            <div className={classes.moneyGroup}>
              <div className={classes.money}>
                ¥{Number(totalamounts).toLocaleString()}
              </div>
            </div> */}
          </MoneyTicket>
          <Card loading={loading}>
            <div className={classes.title}>FROM</div>
            <Typography variant="body2" gutterBottom>
              {fromName}
            </Typography>

            <div className={classes.title}>TO</div>
            <Typography variant="body2" gutterBottom>
              {toName}
            </Typography>

            <div className={classes.title}>日時</div>
            <Typography variant="body2" gutterBottom>
              {moment(createdAt).format("YYYY年MM月DD日 HH時mm分")}
            </Typography>

            <div className={classes.title}>メッセージ</div>
            <Typography variant="body2" gutterBottom>
              {purpose}
            </Typography>
          </Card>
          {!loading && (
            <div className={classes.buttonGroup}>
              <Button
                title="閉じる"
                variant="outlined"
                onClick={() => {
                  setId("");
                  setTabValue(0);
                  history.push("/money-ticket/transfer");
                }}
              />
            </div>
          )}
        </Fragment>
      </div>
    </ViewWrapper>
  );
};
