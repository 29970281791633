import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  ViewWrapper,
  Button,
  Table,
  Card,
  MessageBar,
  ModalForm,
  MoneyTicket as MT,
} from "../../components";
import { useHistory } from "react-router-dom";
import { GetApi } from "../../utils";
import { ApplyDetail } from ".";
import queryString from "query-string";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import currencySymbol from "../../assets/images/currencySymbol.png";
import Grid from "@material-ui/core/Grid";
import { LoginContext } from "../../stores/LoginStore";
import { Box, Paper, Tab, Tabs } from "@material-ui/core";
import { TransferDetail } from "./TransferDetail";
import { useGetApi } from "../../hooks/useGetApi";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    alignContent: "center",
  },
  card: {
    maxWidth: "500px",
    padding: "8px",
    margin: theme.spacing(1),
  },
  moneyGroup: {
    display: "flex",
  },
  money: {
    padding: "0 20px 0 20px",
    fontSize: "50px",
    color: theme.palette.lpSecond.main,
  },
  expire: {
    marginTop: "10px",
    padding: "5px 10px 5px 10px",
    color: "#181724",
    background: "#E3E3E3",
    borderRadius: "20px 20px 20px 20px",
  },
  container: {
    display: "grid",
    gridTemplateColumns: "50% 50%",
    height: "100%",
  },
  mainContainerWithSide: {
    gridColumn: "1/2",
  },
  mainContainer: {
    gridColumn: "1/3",
  },
  sideContainerNone: {
    display: "none",
  },
  sideContainer: {
    overflow: "scroll",
    padding: "0px 8px",
    left: "32px",
    position: "relative",
    height: "100%",
    gridColumn: "2/3",
    background: theme.palette.lpWhite.main,
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, .3)",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
  },
  titleText: {
    color: theme.palette.grey[400],
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  requestAmounts: {
    color: theme.palette.grey[800],
    fontSize: "25px",
  },
  requestName: {
    color: theme.palette.grey[900],
    fontSize: "20px",
  },
  requestHistoryName: {
    color: theme.palette.grey[900],
    fontSize: "14px",
  },
  requestDeptName: {
    color: theme.palette.grey[700],
    fontSize: "10px",
  },
  requestPurpose: {
    color: theme.palette.grey[800],
    fontSize: "12px",
    paddingTop: "8px",
  },
  label: {
    borderRadius: "20px 20px 20px 20px",
    // background: "cornflowerblue",
    color: "white",
    padding: "4px",
    textAlign: "center",
  },
  iconGroup: {
    display: "inline-flex",
    width: "100%",
    padding: theme.spacing(1),
    alignItems: "center",
  },
  iconCircle: {
    width: "30px",
    height: "30px",
    borderRadius: "15px",
    alignItems: "center",
    textAlign: "center",
  },
  icon: {
    marginTop: "6px",
    fontSize: "1.8em",
    color: theme.palette.lpSecond.light,
  },
}));

const Transfer = (props: any) => {
  const qs = queryString.parse(props.location.search);
  const classes = useStyles();
  const history = useHistory();
  const { state: loginState } = useContext(LoginContext);
  const width = props.width;
  const [data, setData] = useState<{}[]>([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [moreScroll, setMoreScroll] = useState(false);
  const [id, setId] = useState(!qs.selectedId ? "" : qs.selectedId);
  const [messageBarMessage, setMessageBarMessage] = useState("");
  const [messageBarOpen, setMessageBarOpen] = useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [transferRequestHistory] = useState([]);
  const [isOpenRequestHistory, setIsOpenRequestHistory] = useState(false);
  const [type, setType] = useState(!qs.type ? "" : qs.type);
  const [moneyTicket, setMoneyTicket] = useState(0);
  const [moneyTicket2, setMoneyTicket2] = useState(0);
  const [moneyTicketExpire1, setMoneyTicketExpire1] = useState("");
  const [moneyTicketExpire2, setMoneyTicketExpire2] = useState("");
  const { data: balanceData, isLoading: isLoadingBalanceData } = useGetApi({
    type: "get",
    url: "/money-ticket/balance",
  });

  const onClickCloseRequestHistoryModal = () => {
    setIsOpenRequestHistory(false);
  };

  const onScrolled = async () => {
    const fetchHistoryData = async () => {
      if (!moreScroll) {
        return;
      }
      const result = await GetApi("/money-ticket/transfer", {
        offset: data.length,
      });

      if (result.statusCode >= 400) {
        setMessageBarMessage(result.message);
        setMessageBarOpen(true);
        return;
      }

      let dispData = result.data.map((d: any) => {
        if (d.type === "金券申請") {
          d.icon = "fa fa-file-text";
          d.iconColor = "purple";
        } else if (d.type === "受け取り") {
          d.icon = "fa fa-plus-square";
          d.iconColor = "green";
        } else if (d.type === "受け渡し") {
          d.icon = "fa fa-minus-square";
          d.iconColor = "red";
        } else if (d.type === "インポート") {
          d.icon = "fa fa-plus-square";
          d.iconColor = "green";
        }
        return d;
      });

      const _temp = dispData.filter(
        (element: any) => data.findIndex((e: any) => e.id === element.id) < 0
      );
      const _tempData = [...data, ..._temp];

      setMoreScroll(result.data.length !== 0 ? true : false);
      setData(_tempData);
    };
    await fetchHistoryData();
  };

  const handleChangeTabValue = (event: any, newValue: any) => {
    setTabValue(newValue);
  };

  const fetchHistoryData = async () => {
    const result = await GetApi("/money-ticket/transfer");

    if (result.statusCode >= 400) {
      setMessageBarMessage(result.message);
      setMessageBarOpen(true);
      return;
    }

    let dispData = result.data.map((d: any) => {
      if (d.type === "金券申請") {
        d.icon = "fa fa-file-text";
        d.iconColor = "purple";
      } else if (d.type === "受け取り") {
        d.icon = "fa fa-plus-square";
        d.iconColor = "green";
      } else if (d.type === "受け渡し") {
        d.icon = "fa fa-minus-square";
        d.iconColor = "red";
      } else if (d.type === "インポート") {
        d.icon = "fa fa-plus-square";
        d.iconColor = "green";
      }
      return d;
    });

    setMoreScroll(result.data.length !== 0 ? true : false);
    setData(dispData);
    setTableLoading(false);
  };

  const onRowClick = (row: any) => {
    console.log("row.id");
    console.log(row.id);
    setId(row.id);
    setTabValue(1);
    setType("transfer");
    history.push("/money-ticket/transfer?type=transfer&selectedId=" + row.id);
  };

  useEffect(() => {
    // const fetchTransferRequestData = async () => {
    //   const result = await GetApi("/money-ticket/transfer-request");

    //   if (result.statusCode >= 400) {
    //     setMessageBarMessage(result.message);
    //     setMessageBarOpen(true);
    //     return;
    //   }
    //   setTransferRequest(result.data);
    // };

    const fetchMoneyTicketData = async () => {
      const result = await GetApi("/money-ticket/balance");

      if (result.statusCode >= 400) {
        setMessageBarMessage(result.message);
        setMessageBarOpen(true);
        return;
      }
    };

    //unconfirmedMoneyTicketData();
    fetchMoneyTicketData();
    fetchHistoryData();
    // fetchTransferRequestData();
  }, []);

  useEffect(() => {
    setMoneyTicket(balanceData.length > 0 ? balanceData[0].amounts : 0);
    setMoneyTicketExpire1(
      balanceData.length > 0
        ? `有効期限：${balanceData[0].expire}`
        : "有効期限：-"
    );
    setMoneyTicket2(balanceData.length > 1 ? balanceData[1].amounts : 0);
    setMoneyTicketExpire2(
      balanceData.length > 1
        ? `有効期限：${balanceData[1].expire}`
        : "有効期限：-"
    );
  }, [balanceData]);

  return (
    <ViewWrapper>
      <div className={classes.container}>
        <div
          className={
            id && isWidthUp("sm", width)
              ? classes.mainContainerWithSide
              : classes.mainContainer
          }
        >
          <div>
            <Button
              title={"金券をわたす"}
              onClick={() => history.push("/money-ticket/transfer/create")}
            />
            <ModalForm
              isOpen={isOpenRequestHistory}
              onClickClose={onClickCloseRequestHistoryModal}
            >
              <h3>おねだり履歴</h3>
              <ul
                style={{
                  padding: "0px",
                  overflowY: "auto",
                }}
              >
                {transferRequestHistory.map((data: any) => (
                  <div>
                    <Card style={{ maxWidth: "600px" }}>
                      <div>
                        <Grid container alignItems="center">
                          <Grid item xs>
                            <div
                              className={classes.label}
                              style={{
                                maxWidth: "100px",
                                background:
                                  data.judgment === "1"
                                    ? "#9A919C"
                                    : data.judgment === "2"
                                    ? "#3962F7"
                                    : "#C1335F",
                              }}
                            >
                              {data.judgment_name}
                            </div>
                            <div className={classes.titleText}>
                              {moment(data.created_at).format(
                                "YYYY年MM月DD日 HH時mm分"
                              )}
                            </div>
                            <div className={classes.requestHistoryName}>
                              {data.request_bs !==
                                loginState.loginUserInfo?.bs &&
                                data.request_name + " におねだりされました"}
                              {data.target_bs !==
                                loginState.loginUserInfo?.bs &&
                                data.target_name + " におねだりしました"}
                            </div>
                            <div className={classes.requestAmounts}>
                              <img
                                src={currencySymbol}
                                alt=""
                                width={"18px"}
                                style={{
                                  position: "relative",
                                  top: "1px",
                                  right: "3px",
                                }}
                              />
                              {Number(data.amounts).toLocaleString()}
                            </div>
                            <div className={classes.requestDeptName}>
                              {data.dept_name}
                            </div>
                          </Grid>
                        </Grid>
                        <div className={classes.requestPurpose}>
                          <p style={{ whiteSpace: "pre" }}>{data.purpose}</p>
                        </div>
                      </div>
                    </Card>
                  </div>
                ))}
              </ul>
            </ModalForm>
          </div>

          {isWidthDown("xs", width) ? (
            <Box m={1} style={{ marginBottom: "0px" }}>
              <Paper square>
                <Tabs
                  centered
                  variant="fullWidth"
                  value={tabValue}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleChangeTabValue}
                  aria-label="disabled tabs example"
                >
                  <Tab label="一覧" />
                  <Tab label="詳細" />
                </Tabs>
              </Paper>
            </Box>
          ) : (
            <Card title={"所有金券"} titleLayout={true}>
              <Grid
                container
                className={classes.root}
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={2}
              >
                {moneyTicket2 > 0 && (
                  <Grid item style={{ minWidth: "300px" }}>
                    <MT
                      loading={isLoadingBalanceData}
                      maxWidth={"400px"}
                      color={"red"}
                      expire={moneyTicketExpire2}
                      amounts={Number(moneyTicket2).toLocaleString()}
                    >
                      {""}
                    </MT>
                  </Grid>
                )}
                <Grid item style={{ minWidth: "300px" }}>
                  <MT
                    loading={isLoadingBalanceData}
                    maxWidth={"400px"}
                    expire={moneyTicketExpire1}
                    amounts={Number(moneyTicket).toLocaleString()}
                  >
                    {""}
                  </MT>
                </Grid>
              </Grid>
            </Card>
          )}
          {(isWidthDown("xs", width) && tabValue === 0) ||
          isWidthUp("sm", width) ? (
            <Fragment>
              <p style={{ paddingLeft: "8px" }}>
                ※明細行をクリックして、詳細を開きます
              </p>
              <Card
                title={"履歴"}
                titleLayout={true}
                style={{ marginTop: "1px" }}
              >
                <Table
                  loading={tableLoading}
                  cursor={true}
                  headers={[
                    {
                      name: "",
                      disp: "取引区分",
                      type: "custom",
                      component: (
                        rowIndex: any,
                        index: any,
                        header: any,
                        rowData: any
                      ) => {
                        return (
                          <td
                            key={index}
                            id={String(header.name) + "_" + String(rowIndex)}
                          >
                            <p
                              style={{
                                color: "gray",
                                margin: "2px",
                                fontSize: "6px",
                              }}
                            >
                              {!rowData["created_at"]
                                ? ""
                                : moment(rowData["created_at"]).format(
                                    "YYYY年MM月DD日 HH時mm分"
                                  )}
                            </p>
                            <p style={{ margin: "2px" }}>
                              {rowData["display_type"]}
                            </p>
                            {/* <p style={{ margin: "2px" }}>{rowData["purpose"]}</p> */}
                          </td>
                        );
                      },
                    },
                    {
                      name: "",
                      disp: "誰から",
                      type: "custom",
                      component: (
                        rowIndex: any,
                        index: any,
                        header: any,
                        rowData: any
                      ) => {
                        return (
                          <td
                            key={index}
                            id={String(header.name) + "_" + String(rowIndex)}
                          >
                            {rowData["from_name"] === "" ? (
                              <p
                                style={{
                                  color: "gray",
                                  margin: "2px",
                                  fontSize: "6px",
                                }}
                              >
                                {"-"}
                              </p>
                            ) : (
                              <Fragment>
                                <p
                                  style={{
                                    color: "gray",
                                    margin: "2px",
                                    fontSize: "4px",
                                  }}
                                >
                                  {rowData["from_bs"]}
                                </p>
                                <p
                                  style={{
                                    margin: "2px",
                                  }}
                                >
                                  {rowData["from_name"]}
                                </p>
                              </Fragment>
                            )}
                          </td>
                        );
                      },
                    },
                    {
                      name: "",
                      disp: "誰へ",
                      type: "custom",
                      component: (
                        rowIndex: any,
                        index: any,
                        header: any,
                        rowData: any
                      ) => {
                        return (
                          <td
                            key={index}
                            id={String(header.name) + "_" + String(rowIndex)}
                          >
                            {rowData["to_name"] === "" ? (
                              <p
                                style={{
                                  color: "gray",
                                  margin: "2px",
                                  fontSize: "6px",
                                }}
                              >
                                {"-"}
                              </p>
                            ) : (
                              <Fragment>
                                <p
                                  style={{
                                    color: "gray",
                                    margin: "2px",
                                    fontSize: "4px",
                                  }}
                                >
                                  {rowData["to_bs"]}
                                </p>
                                <p
                                  style={{
                                    margin: "2px",
                                  }}
                                >
                                  {rowData["to_name"]}
                                </p>
                              </Fragment>
                            )}
                          </td>
                        );
                      },
                    },
                    // {
                    //   name: "",
                    //   disp: "金額",
                    //   type: "custom",
                    //   component: (
                    //     rowIndex: any,
                    //     index: any,
                    //     header: any,
                    //     rowData: any
                    //   ) => {
                    //     return (
                    //       <td
                    //         key={index}
                    //         id={String(header.name) + "_" + String(rowIndex)}
                    //         className={classes.iconGroup}
                    //       >
                    //         <Fragment>
                    //           <div className={classes.iconCircle}>
                    //             <i
                    //               className={rowData.icon + " " + classes.icon}
                    //               style={
                    //                 rowData.iconColor === "green"
                    //                   ? { color: "#76E59C" }
                    //                   : rowData.iconColor === "red"
                    //                   ? { color: "#E76A7C" }
                    //                   : {}
                    //               }
                    //             />
                    //           </div>
                    //           <img
                    //             src={currencySymbol}
                    //             alt=""
                    //             width={"9px"}
                    //             style={{
                    //               position: "relative",
                    //               top: "1px",
                    //               right: "2px",
                    //             }}
                    //           />
                    //           <h2
                    //             style={{
                    //               color: "#000000",
                    //               display: "inline-flex",
                    //             }}
                    //           >
                    //             {Number(rowData["amounts"]).toLocaleString()}
                    //           </h2>
                    //         </Fragment>
                    //       </td>
                    //     );
                    //   },
                    // },
                    // {
                    //   name: "contents",
                    //   disp: "内容",
                    //   type: "text",
                    // },
                    // {
                    //   name: "created_at",
                    //   disp: "日付",
                    //   type: "datetime",
                    // },
                    {
                      name: "amounts",
                      disp: "金額",
                      type: "coloredMoneyText",
                    },
                  ]}
                  data={data}
                  onRowClick={onRowClick}
                  onScrolled={onScrolled}
                  moreScroll={moreScroll}
                />
              </Card>
            </Fragment>
          ) : (
            <Card style={{ marginTop: "1px" }}>
              <div>
                {id && type === "transfer" ? (
                  <TransferDetail
                    id={id}
                    setId={setId}
                    setTabValue={setTabValue}
                  />
                ) : (
                  <div>データが選択されていません。</div>
                )}
              </div>
            </Card>
          )}
        </div>
        {isWidthUp("sm", width) && (
          <div
            className={
              id
                ? `${classes.sideContainer} SideDetail`
                : classes.sideContainerNone
            }
          >
            {id && type === "transfer" ? (
              <TransferDetail id={id} setId={setId} setTabValue={setTabValue} />
            ) : (
              <div></div>
            )}
          </div>
        )}
      </div>

      <MessageBar
        open={messageBarOpen}
        setOpen={setMessageBarOpen}
        severity={"error"}
        message={messageBarMessage}
      />
    </ViewWrapper>
  );
};

export default withWidth()(Transfer);
