import { Auth } from "aws-amplify";
import { API } from "aws-amplify";

// axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

const apiName = process.env.REACT_APP_API_NAME;
// const axiosBase = require('axios');

// const axios = axiosBase.create({
//   baseURL: 'http://127.0.0.1:8080', // バックエンドB のURL:port を指定する
//   headers: {
//     'Content-Type': 'application/json',
//     'X-Requested-With': 'XMLHttpRequest',
//     'Authorization': '',
//   }
// });

// const auth = GetCognitoAuth((result: any) => {
//   console.log("セッションを正常に取得できました");
//   console.log(result);
//   console.log(result.idToken.jwtToken);
//   axios.defaults.headers.common['Authorization'] = `Bearer ${result.idToken.jwtToken}`;
// }, () => {
//   console.log("bbbbbbbbbbbbb");
// });

const setAuth = async () => {
  // const currentSession = await Auth.currentSession();
  // axios.defaults.headers.common['Authorization'] = `Bearer ${currentSession.getIdToken().getJwtToken()}`;
};

export const GetApi = async (url: string, queries?: { [key: string]: any }) => {
  try {
    await setAuth();

    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;

    const myInit = {
      headers: {
        Authorization: token,
      },
      response: true,
      queryStringParameters: queries,
    };
    return await API.get(apiName, url, myInit);
  } catch (error) {
    console.dir(error);
    return createErrorResponse(error);
  }
};

export const PostApi = async (url: string, body: any) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;

    const myInit = {
      body: body,
      headers: {
        Authorization: token,
      },
      response: true,
    };
    return await API.post(apiName, url, myInit);
  } catch (error) {
    console.dir(error);
    return createErrorResponse(error);
  }
};

export const PutApi = async (url: string, body: any) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const myInit = {
      body: body,
      headers: {
        Authorization: token,
      },
      response: true,
    };
    return await API.put(apiName, url, myInit);
  } catch (error) {
    return createErrorResponse(error);
  }
};

export const DeleteApi = async (url: string) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;

    const myInit = {
      headers: {
        Authorization: token,
      },
      response: true,
    };
    return await API.del(apiName, url, myInit);
  } catch (error) {
    return createErrorResponse(error);
  }
};

const createErrorResponse = (error: any) => {
  let response;
  if (!error.response) {
    response = {
      status: "600",
      message: "ネットワークエラーが発生しました。",
    };
  } else {
    response = error.response.data;
  }
  return response;
};
