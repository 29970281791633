import React, { ReactNode, useReducer } from "react";

type StateType = {
  menu?: any;
};

export enum ActionEnum {
  SELECT_MENU = "SELECT_MENU",
}

type ActionType = {
  type: ActionEnum;
  payload?: any;
};
type ContextType = {
  state: StateType;
  dispatch: React.Dispatch<ActionType>;
};
const initialState = {
  menu: {},
};

export const reducer = (state: StateType, action: ActionType) => {
  switch (action.type) {
    case ActionEnum.SELECT_MENU:
      return {
        ...state,
        menu: action.payload?.menu,
      };
    default:
      return { ...state };
  }
};
interface IProps {
  children: ReactNode;
}
export const MenuContext = React.createContext({} as ContextType);
export const MenuProvider = ({ children }: IProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <MenuContext.Provider value={{ state, dispatch }}>
      {children}
    </MenuContext.Provider>
  );
};
