import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ExitToApp from "@material-ui/icons/ExitToApp";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { MenuContext } from "../../stores/MenuStore";
import { JudgmentContext } from "../../stores/JudgmentStore";
import { LoginContext } from "../../stores/LoginStore";
import { GetCognitoAuth } from "../../views/Auth/awsConfig";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Chip from "@material-ui/core/Chip";
import { MessageBar } from "../MessageBar/MessageBar";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "fixed",
    zIndex: 100,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "calc(100% - 200px)",
    },
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
    color: "black",
  },
  appBar: {
    background: "#ffffff",
    height: "57px",
    borderBottom: "solid 1px #949493",
  },
}));

export const MenuAppBar = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const { state: menuState } = useContext(MenuContext);
  const { state: judgmentState } = useContext(JudgmentContext);
  const { state: loginState } = useContext(LoginContext);
  const [title, setTitle] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorNotificationsEl, setAnchorNotificationsEl] =
    React.useState(null);
  const open = Boolean(anchorEl);
  const openNotifications = Boolean(anchorNotificationsEl);
  const [messageBarMessage] = useState("");
  const [messageBarOpen, setMessageBarOpen] = useState(false);

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseNotifications = () => {
    setAnchorNotificationsEl(null);
  };

  const handleSignout = () => {
    const auth = GetCognitoAuth(
      () => {},
      () => {}
    );
    auth.signOut();
  };

  useEffect(() => {
    if (menuState.menu.name && menuState.menu.disp) {
      setTitle(menuState.menu.name);
    }
    if (menuState.menu.name && menuState.menu.children) {
      //setTitle(state.menu.name + " / " + state.menu.children);
      setTitle(menuState.menu.children);
    }
  }, [menuState.menu]);

  return (
    <div className={classes.root} style={!props.open ? { width: "100%" } : {}}>
      <AppBar position="static" className={classes.appBar} color={"default"}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.onClick}
            edge="start"
          >
            {props.open ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
          <Typography
            variant="body2"
            className={classes.title}
            style={{ fontWeight: "bold" }}
          >
            {title}
          </Typography>
          <div>
            {loginState.loginUserInfo?.name}
            {/* <IconButton>
              <Badge
                aria-label="notifications of current user"
                aria-controls="menu-notifications"
                aria-haspopup="true"
                badgeContent={
                  judgmentState.data.judgment.apply +
                  judgmentState.data.judgment.request
                }
                color="secondary"
                onClick={handleNotifications}
              >
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            <Menu
              id="menu-notifications"
              anchorEl={anchorNotificationsEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={openNotifications}
              onClose={handleCloseNotifications}
            >
              {judgmentState.data.judgment.apply > 0 && (
                <MenuItem
                  onClick={() => {
                    history.push("/money-ticket/approval");
                    handleCloseNotifications();
                  }}
                >
                  金券申請の判定が必要です
                  <Chip
                    size="small"
                    label={judgmentState.data.judgment.apply}
                    color="secondary"
                  />
                </MenuItem>
              )}

              {judgmentState.data.judgment.request > 0 && (
                <MenuItem
                  onClick={() => {
                    history.push("/money-ticket/transfer");
                    handleCloseNotifications();
                  }}
                >
                  おねだりの判定が必要です
                  <Chip
                    size="small"
                    label={judgmentState.data.judgment.request}
                    color="secondary"
                  />
                </MenuItem>
              )}

              {judgmentState.data.judgment.apply +
                judgmentState.data.judgment.request ===
                0 && <MenuItem>通知はありません</MenuItem>}
            </Menu>

            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="primary"
            >
              <ExitToApp />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
            >
              {/* <MenuItem onClick={handleSettings}>個人設定</MenuItem> */}
              <MenuItem onClick={handleSignout}>ログアウト</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <MessageBar
        open={messageBarOpen}
        setOpen={setMessageBarOpen}
        severity={"error"}
        message={messageBarMessage}
      />
    </div>
  );
};
