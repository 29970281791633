import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import { ImagePreview } from "./ImagePreview";
import { Storage } from "aws-amplify";
// var pdf2png = require("pdf2png");

const useStyles = makeStyles({
  icon: {
    marginRight: 8,
    height: 48,
    width: 48,
  },
  input: {
    display: "none",
  },
  div1: {
    textAlign: "right",
  },
  div0: {
    display: "flex",
    flexFlow: "wrap",
  },
});

export const ImageArea = (props: any) => {
  const classes = useStyles();
  const { images, setImages } = props;

  const deleteImage = useCallback(
    async (id) => {
      const ret = window.confirm("この画像を削除しますか？");
      if (!ret) {
        return false;
      } else {
        const newImages = images.filter((image: any) => image !== id);
        setImages(newImages);

        // const params = {
        //   Bucket: "tanida-react-app",
        //   Delete: {
        //     Objects: [
        //       {
        //         Key: id
        //       }
        //     ],
        //   }
        // };

        // console.log(params)

        // s3.deleteObjects(params, function (err: any, data: any) {
        //   if (err) {
        //     console.log(err, err.stack);
        //   } else {
        //     console.log(data);
        //   }
        // });
      }
    },
    [images, setImages]
  );

  const uploadImage = useCallback(
    (event) => {
      // dispatch(showLoadingAction("uploading..."))
      const file = event.target.files;
      console.log(file);

      let blob = new Blob(file, { type: file[0].type });

      // // Generate random 16 digits strings
      const S =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      const N = 16;
      let fileName = Array.from(crypto.getRandomValues(new Uint32Array(N)))
        .map((n) => S[n % S.length])
        .join("");

      if (file[0].type === "application/pdf") {
        fileName = fileName + ".pdf";
      } else if (file[0].type === "image/jpeg") {
        fileName = fileName + ".jpg";
      } else if (file[0].type === "image/png") {
        fileName = fileName + ".png";
      }

      // const params: any = {
      //   Bucket: process.env.REACT_APP_RECEIPT_S3,
      //   Key: fileName,
      // };

      // pdf2png.convert(__dirname + "/example.pdf", function (resp: any) {
      //   if (!resp.success) {
      //     console.log("Something went wrong: " + resp.error);

      //     return;
      //   }

      //   console.log("Yayy the pdf got converted, now I'm gonna save it!");

      //   // fs.writeFile("test/example_simple.png", resp.data, function (err) {
      //   //   if (err) {
      //   //     console.log(err);
      //   //   } else {
      //   //     console.log("The file was saved!");
      //   //   }
      //   // });
      // });

      // var v = fs.readFileSync("./アップロード対象ファイル名.jpg");
      // params.Body = blob;
      // const newImage = { id: fileName, path: `https://local-lincrea-portal-receipt-storage/${fileName}` };
      //     props.setImages(((prevState: any) => [...prevState, newImage]))
      //     console.log(data);
      Storage.put(fileName, blob, {
        contentType: file[0].type,
      })
        .then(async (result: any) => {
          const newImage = fileName;
          setImages((prevState: any) => [...prevState, newImage]);
        }) // {key: "test.txt"}
        .catch((err: any) => {
          console.log(err, err.stack);
        });
    },
    [setImages]
  );

  return (
    <div>
      <div className={classes.div0}>
        {images.length > 0 &&
          images.map((image: any) => (
            <ImagePreview onClick={deleteImage} id={image} key={image} />
          ))}
      </div>
      <div>
        <span>領収書画像の添付</span>
        <IconButton className={classes.icon}>
          <label>
            <AddPhotoAlternateIcon />
            <input
              className={classes.input}
              type="file"
              id="image"
              onChange={(e) => uploadImage(e)}
            />
          </label>
        </IconButton>
      </div>
    </div>
  );
};
