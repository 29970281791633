import React from "react";
import Modal from "react-modal";

type ModalFormProps = {
  isOpen: boolean;
  height?: string;
  onClickClose: () => void;
  children: React.ReactNode;
};

export const ModalForm: React.FC<ModalFormProps> = ({
  isOpen = false,
  onClickClose = () => {},
  height = "",
  children,
}: ModalFormProps) => {
  const modalStyle: Modal.Styles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,0.55)",
    },
    content: {
      maxWidth: "500px",
      margin: "auto",
      padding: "16px",
      height: height,
    },
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      style={modalStyle}
      onRequestClose={onClickClose}
    >
      {children}
    </Modal>
  );
};
