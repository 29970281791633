import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment, useEffect, useState } from "react";
import {
  ViewWrapper,
  Button,
  Table,
  Card,
  Block,
  MoneyTicket,
  MessageBar,
} from "../../components";
import { useHistory } from "react-router-dom";
import { GetApi } from "../../utils";
import Typography from "@material-ui/core/Typography";
const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  tableTitleGroup: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: theme.spacing(1),
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
  },
  moneyGroup: {
    display: "flex",
  },
  money: {
    padding: "0 20px 0 20px",
    fontSize: "50px",
    color: "#A3A0F5",
  },
  title: {
    color: theme.palette.lpGray.main,
  },
  div: {
    margin: theme.spacing(1),
  },
}));

export const ImportHistoryDetail = (props: any) => {
  // パスパラメータの取得
  const { id } = props.match.params;
  // 定数定義
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [contents, setContents] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [totalamounts, setTotalamounts] = useState(0);
  const [expire, setExpire] = useState("");
  const [createUser, setCreateUser] = useState("");
  const [data, setData] = useState<{}[]>([]);
  const [messageBarMessage, setMessageBarMessage] = useState("");
  const [messageBarOpen, setMessageBarOpen] = useState(false);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const fetchData = async () => {
      const result = await GetApi("/money-ticket/import/" + id);

      if (result.statusCode >= 400) {
        setMessageBarMessage(result.message);
        setMessageBarOpen(true);
        return;
      }

      setContents(result.data.contents);
      setTotalamounts(result.data.amounts);
      setCreatedAt(result.data.created_at);
      setExpire(result.data.expire);
      setCreateUser(result.data.name);
      setData(result.data.users);

      setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <ViewWrapper>
      <MessageBar
        open={messageBarOpen}
        setOpen={setMessageBarOpen}
        severity={"error"}
        message={messageBarMessage}
      />
      <Card title={"インポート履歴"} titleLayout={true}>
        <MoneyTicket
          loading={loading}
          maxWidth="300px"
          expire={"合計インポート金額"}
          amounts={Number(totalamounts).toLocaleString()}
        >
          {/* 合計インポート金額
        <div className={classes.moneyGroup}>
          <div className={classes.money}>
            ¥{Number(totalamounts).toLocaleString()}
          </div>
        </div> */}
        </MoneyTicket>
        <Block loading={loading}>
          <div className={classes.title}>内容</div>
          <Typography variant="body2" gutterBottom>
            {contents}
          </Typography>

          <div className={classes.title}>金券有効期限</div>
          <Typography variant="body2" gutterBottom>
            {expire}
          </Typography>

          <div className={classes.title}>実施者</div>
          <Typography variant="body2" gutterBottom>
            {createUser}
          </Typography>

          <div className={classes.title}>実施日</div>
          <Typography variant="body2" gutterBottom>
            {moment(createdAt).format("YYYY年M月DD日 HH時mm分")}
          </Typography>
        </Block>
        <Table
          loading={loading}
          headers={[
            {
              name: "target_bs",
              disp: "bs番号",
              type: "text",
            },
            {
              name: "name",
              disp: "名前",
              type: "text",
            },
            {
              name: "amounts",
              disp: "金額",
              type: "moneyText",
            },
          ]}
          data={data}
        />
        {!loading && (
          <Fragment>
            <div className={classes.buttonGroup}>
              <Button
                title="戻る"
                variant="outlined"
                onClick={() => history.push("/money-ticket/import/history")}
              />
            </div>
          </Fragment>
        )}
      </Card>
    </ViewWrapper>
  );
};
