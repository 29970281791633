import React, { useEffect, useState } from "react";
import { AmplifyS3Image } from "@aws-amplify/ui-react";
import { Storage } from "aws-amplify";
import { pdfjs, Document, Page } from "react-pdf";
import { makeStyles } from "@material-ui/core/styles";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles({
  pdf: {
    width: "90%",
    overflow: "scroll",
    objectFit: "cover",
  },
  image: {
    "--width": "90%",
  },
});

export const ImagePreview = (props: any) => {
  const classes = useStyles();
  const [signedURL, setSignedURL] = useState("");

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const get = async () => {
      const _signedURL = await Storage.get(props.id);
      console.log(String(_signedURL));
      setSignedURL(String(_signedURL));
    };
    get();
  }, []);

  return (
    <div className="p-media__thumb" onClick={() => props.onClick(props.id)}>
      {/* <S3Image imgKey={props.id} /> */}
      {props.id.slice(-3) === "pdf" ? (
        <Document file={signedURL} className={classes.pdf}>
          <Page pageNumber={1} />
        </Document>
      ) : (
        <AmplifyS3Image imgKey={props.id} className={classes.image} />
      )}
    </div>
  );
};
