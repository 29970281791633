export const Pages = [
  {
    auth: "all",
    name: "ダッシュボード",
    url: "/dashboard",
    icon: "fa-tachometer",
  },
  {
    auth: "all",
    name: "金券をわたす",
    url: "/money-ticket/transfer",
    icon: "fa-exchange",
  },
  {
    auth: "all",
    name: "金券精算_申請",
    icon: "fa-money",
    url: "/money-ticket/apply",
    // children: [
    //   {
    //     auth: "all",
    //     name: "金券申請(一般)",
    //     url: "/money-ticket/apply",
    //   },
    //   {
    //     auth: "all",
    //     name: "社内交際費申請",
    //     url: "/money-ticket/entertainment-expenses",
    //   },
    //   {
    //     auth: "all",
    //     name: "インセンティブ申請",
    //     url: "/money-ticket/incentive",
    //   },
    //   {
    //     auth: "all",
    //     name: "キャンペーン申請",
    //     url: "/money-ticket/campaign",
    //   },
    //   // {
    //   //   name: "おねだり",
    //   //   url: "/money-ticket/transfer-request",
    //   // },
    // ],
  },
  {
    auth: "all",
    name: "金券精算_承認",
    url: "/money-ticket/approval",
    icon: "fa-check-square-o",
  },
  {
    auth: "generalAffairs",
    name: "総務管理",
    icon: "fa-cogs",
    children: [
      {
        auth: "generalAffairs",
        name: "金券精算_総務承認",
        url: "/money-ticket/general-affairs",
      },
      {
        auth: "generalAffairs",
        name: "金券インポート",
        url: "/money-ticket/import",
      },
      // {
      //   auth: "officerEntertainmentExpenses",
      //   name: "役員交際費申請",
      //   url: "/money-ticket/officer-entertainment-expenses",
      // },
      {
        auth: "balanceEditing",
        name: "金券残高編集",
        url: "/money-ticket/balance-editing",
      },
      {
        auth: "generalAffairs",
        name: "権限設定",
        url: "/money-ticket/settings",
      },
      {
        auth: "generalAffairs",
        name: "金券利用状況",
        url: "/money-ticket/general-search",
      },
    ],
  },
  // {
  //   auth: "all",
  //   name: "公開データ",
  //   icon: "fa-bar-chart",
  //   children: [
  //     {
  //       name: "金券利用状況",
  //       url: "/money-ticket/usage-data",
  //     },
  //   ],
  // },
  {
    auth: "all",
    name: "ユーザ情報設定",
    url: "/settings",
    icon: "fa-address-book-o",
  },
];
