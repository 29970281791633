import { Fragment, useState, useEffect } from "react";
import { GetCognitoAuth } from "../Auth/awsConfig";
import { Redirect } from "react-router-dom";

const defaultRedirectUrl = "/dashboard";

export const IdpCallback = (props: any) => {
  const [needRedirect, setNeedRedirect] = useState(false);
  const [redirectPath, setRedirectPath] = useState("");

  const invokeRedirect = (path: string) => () => {
    setRedirectPath(path);
    setNeedRedirect(true);
  };

  useEffect(() => {
    // const auth = GetCognitoAuth(invokeRedirect(String(sessionStorage.getItem('redirecturl'))), invokeRedirect("/"));
    let url = String(sessionStorage.getItem("redirecturl"));
    console.log(url);
    if (!url || url === "null" || url === "" || url === "/") {
      url = defaultRedirectUrl;
    }
    const auth = GetCognitoAuth(invokeRedirect(url), invokeRedirect("/"));

    auth.parseCognitoWebResponse(props.location.search);
  }, [props.location.search]);

  return <Fragment>{needRedirect && <Redirect to={redirectPath} />}</Fragment>;

  // const cognitoUser = userPool.getCurrentUser()

  // console.log(cognitoUser)

  // if (cognitoUser) {
  //   // sign inしている状態
  //   cognitoUser.getSession(function (err: any, signInUserSession: any) {
  //     cognitoUser.getUserData(function(err: any, userData: any) {
  //       if (err) {
  //         alert(err.message || JSON.stringify(err));
  //         return;
  //       }
  //       console.log('User data for user ' + userData);
  //       console.log(userData);

  //       const googleLogin = userData.UserAttributes.filter( (item: any, i: any) => item.Name === 'identities').length === 0 ? false : true
  //       const userMFASettingList = userData.UserMFASettingList || []
  //       const userId = userData.UserAttributes.filter((item: any) => item.Name === 'sub').length === 0 ? '' : userData.UserAttributes.filter((item: any) => item.Name === 'sub')[0].Value

  //       //dispatch(signInAction({ username: userData.Username, role: '', mfa: userMFASettingList, googleLogin: googleLogin, userId: userId }))

  //     });

  //     //dispatch(signInAction({ username: cognitoUser.username, role: '', mfa: false }))
  //   })
  // } else {
  //   // sign inしていない状態
  //   console.log('no signing in')
  //   //window.location.href = 'https://lincrea-portal.auth.ap-northeast-1.amazoncognito.com/login?response_type=token&client_id=2tkdj7f53hecqe1luv56ahe19l&scope=aws.cognito.signin.user.admin&redirect_uri=http://localhost:3000'
  //   // return <Redirect to={''} />
  // }
};
