import React, { Fragment } from "react"
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1),
  }
}));

type BlockProps = {
  loading?: boolean;
  children: React.ReactNode;
}

export const Block: React.FC<BlockProps> = ({
  loading = false,
  children
}: BlockProps) => {

  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.root} >
        {loading ? (<Skeleton variant="rect" height={100} />) : children}
      </div>
    </Fragment>
  )
}