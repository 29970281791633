import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import { Check } from "@material-ui/icons";
import clsx from "clsx";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: theme.palette.lpFirst.dark,
  },
  circle: {
    width: 10,
    height: 10,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: theme.palette.lpFirst.dark,
    zIndex: 0,
    fontSize: 22,
  },
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

// const useQontoStepIconStyles = makeStyles({
//   root: {
//     color: "#eaeaf0",
//     display: "flex",
//     height: 22,
//     alignItems: "center",
//   },
//   active: {
//     color: "#784af4",
//   },
//   circle: {
//     width: 10,
//     height: 10,
//     borderRadius: "50%",
//     backgroundColor: "currentColor",
//   },
//   completed: {
//     color: "#784af4",
//     zIndex: 0,
//     fontSize: 22,
//   },
// });

function QontoStepIcon(props: any) {
  const classes = useStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

const steps = [
  "配分者確認待ち",
  "第１承認待ち",
  "最終承認待ち",
  "総務承認待ち",
  "総務承認済み",
  "決済完了",
];

type MoneyTicketStatusSteperProps = {
  status: string;
};

export const MoneyTicketStatusSteper: React.FC<MoneyTicketStatusSteperProps> =
  ({ status = "" }: MoneyTicketStatusSteperProps) => {
    const [activeStep, setActiveStep] = useState(-1);
    const theme = useTheme();

    const QontoConnector = withStyles({
      alternativeLabel: {
        top: 10,
        left: "calc(-50% + 16px)",
        right: "calc(50% + 16px)",
      },
      active: {
        "& $line": {
          borderColor: theme.palette.lpFirst.dark,
        },
      },
      completed: {
        "& $line": {
          borderColor: theme.palette.lpFirst.dark,
        },
      },
      line: {
        borderColor: "#eaeaf0",
        borderTopWidth: 3,
        borderRadius: 1,
      },
    })(StepConnector);

    useEffect(() => {
      switch (status) {
        case "配分者確認待ち":
          setActiveStep(0);
          break;
        case "第１承認待ち":
          setActiveStep(1);
          break;
        case "最終承認待ち":
          setActiveStep(2);
          break;
        case "総務承認待ち":
          setActiveStep(3);
          break;
        case "総務承認済み":
          setActiveStep(4);
          break;
        case "決済完了":
          setActiveStep(6);
          break;
        default:
          setActiveStep(-1);
          break;
      }
    }, [status]);

    return (
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        style={{ backgroundColor: "transparent", padding: "8px" }}
        connector={<QontoConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>
              <div style={{ fontSize: "10px" }}>{label}</div>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    );
  };
