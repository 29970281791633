import { useState, useEffect, useContext } from "react";
import { GetApi } from "../utils";
import {
  NotificationContext,
  ActionEnum as NotificationActionEnum,
} from "../stores/NotificationStore";

interface RequestProps {
  type: "get" | "scroll";
  url: string;
  queries?: Record<string, any>;
  id?: string;
}

export const useGetApi = ({
  type,
  url,
  queries,
  id = "id",
}: RequestProps): any => {
  const { dispatch: notificationDispatch } = useContext(NotificationContext);
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [moreScroll, setMoreScroll] = useState(true);

  useEffect(() => {
    const getApi = async () => {
      try {
        if (!(type === "scroll" && data)) {
          setIsLoading(true);
        }
        const result = await GetApi(url, queries);
        if (result.status === 200) {
          if (type === "scroll") {
            const _temp = result.data.filter(
              (element: any) =>
                data.findIndex((e: any) => e[id] === element[id]) < 0
            );

            if (!queries || queries.offset === 0) {
              setData([..._temp]);
            } else {
              setData([...data, ..._temp]);
            }
            setMoreScroll(result.data.length !== 0 ? true : false);
          } else {
            setMoreScroll(false);
            setData(result.data);
          }

          setIsLoading(false);
        } else {
          notificationDispatch({
            type: NotificationActionEnum.SET_NOTIFICATION,
            payload: {
              message: "データが取得できませんでした。",
              severity: "error",
            },
          });
        }
      } catch (error) {
        console.error(`Error ${error}`);
        notificationDispatch({
          type: NotificationActionEnum.SET_NOTIFICATION,
          payload: {
            message: `データが取得できませんでした。${error}`,
            severity: "error",
          },
        });
      }
    };

    if (moreScroll || queries?.offset === 0) {
      getApi();
    }
  }, [queries]);

  return { data, setData, isLoading, moreScroll };
};
