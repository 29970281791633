import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button as MuiButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  buttonContained: {
    margin: theme.spacing(1),
    color: theme.palette.lpText.dark,
    background: theme.palette.lpFirst.main,
    "&:hover": {
      background: theme.palette.lpFirst.dark,
    },
  },
  buttonOutlined: {
    margin: theme.spacing(1),
    color: theme.palette.grey[500],
    "&:hover": {
      background: theme.palette.grey[300],
    },
  },
}));

type ButtonProps = {
  id?: string;
  title?: string;
  variant?: "text" | "outlined" | "contained";
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const Button: React.FC<ButtonProps> = ({
  id = "",
  title = "",
  variant = "contained",
  disabled = false,
  onClick = () => {},
}: ButtonProps) => {
  const classes = useStyles();

  return (
    <MuiButton
      id={id}
      variant={variant}
      disabled={disabled}
      className={
        variant === "outlined"
          ? classes.buttonOutlined
          : classes.buttonContained
      }
      onClick={onClick}
    >
      {title}
    </MuiButton>
  );
};
