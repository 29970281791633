export const applyCategoryForMemberOptions = [
  {
    code: "1",
    name: "一般",
  },
  {
    code: "2",
    name: "社内交際費",
  },
];

export const applyCategoryOptions = [
  {
    code: "1",
    name: "一般",
  },
  {
    code: "2",
    name: "社内交際費",
  },
  {
    code: "3",
    name: "インセンティブ",
  },
];

export const applyUsageClassificationOptions: {
  [key: string]: { code: string; name: string }[];
} = {
  "1": [
    {
      code: "11",
      name: "飲食・差入",
    },
    {
      code: "12",
      name: "物品購入",
    },
    {
      code: "13",
      name: "ゴルフ",
    },
    {
      code: "14",
      name: "レジャー",
    },
  ],
  "2": [
    {
      code: "21",
      name: "凛カップ景品",
    },
    {
      code: "22",
      name: "ＬMO",
    },
    {
      code: "23",
      name: "祀",
    },
  ],
  "3": [
    {
      code: "31",
      name: "本部インセンティブ",
    },
  ],
};

export const peopleNumberOptions = [
  {
    code: "1",
    name: "1〜5",
  },
  {
    code: "2",
    name: "6〜10",
  },
  {
    code: "3",
    name: "11〜",
  },
];

export const onlineOptions = [
  {
    code: "1",
    name: "リアル",
  },
  {
    code: "2",
    name: "オンライン",
  },
];

export const judgmentOptions = [
  {
    code: "UNDECIDED",
    name: "未判定",
  },
  {
    code: "APPROVAL",
    name: "判定済み",
  },
];

export const balanceEdittingTypeOptions = [
  {
    code: "1",
    name: "配分間違い",
  },
  {
    code: "2",
    name: "役員交際費",
  },
];

export const increaseDecreaseOptions = [
  {
    code: "1",
    name: "減額",
  },
  {
    code: "2",
    name: "増額",
  },
];
