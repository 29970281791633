import { CognitoAuth } from "amazon-cognito-auth-js";
import { Amplify, API } from "aws-amplify";

const config = {
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_REGION, // REQUIRED - Amazon Cognito Region
    userPoolId: process.env.REACT_APP_USER_POOL_ID, //OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_RECEIPT_S3, //REQUIRED -  Amazon S3 bucket name
      region: process.env.REACT_APP_REGION, //OPTIONAL -  Amazon service region
    },
  },
  API: {
    endpoints: [
      {
        name: process.env.REACT_APP_API_NAME,
        endpoint: process.env.REACT_APP_API_ENDPOINT,
      },
    ],
  },
}

Amplify.configure(config);
API.configure(config);

export const GetCognitoAuth = (onSuccess: any, onFailure: any) => {
  var authData = {
    ClientId: process.env.REACT_APP_CLIENT_ID || "",
    AppWebDomain: process.env.REACT_APP_APP_WEB_DOMAIN || "",
    TokenScopesArray: [
      "profile",
      "email",
      "openid",
      "aws.cognito.signin.user.admin",
    ],
    RedirectUriSignIn: process.env.REACT_APP_REDIRECT_URI_SIGN_IN || "",
    RedirectUriSignOut: process.env.REACT_APP_REDIRECT_URI_SIGN_OUT || "",
    IdentityProvider: process.env.REACT_APP_IDENTITY_PRIVIDER || "",
  };
  var auth = new CognitoAuth(authData);
  auth.userhandler = {
    onSuccess: function (result) {
      if (onSuccess) {
        onSuccess(result);
      }
    },
    onFailure: function (err) {
      if (onFailure) {
        onFailure(err);
      }
      auth.signOut();
    },
  };
  auth.useCodeGrantFlow();

  return auth;
};