import React, { useEffect, useState } from "react";
import { ViewWrapper, Card, MessageBar, SelectBox } from "../../components";
import { GetApi } from "../../utils";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { Bar } from "react-chartjs-2";
import Chart from "react-apexcharts";

const UsageData = (props: any) => {
  const width = props.width;
  const [messageBarMessage, setMessageBarMessage] = useState("");
  const [messageBarOpen, setMessageBarOpen] = useState(false);

  const barData = {
    labels: [
      "第1SIビジネス本部第1ブロック",
      "第1SIビジネス本部第2ブロック",
      "第1SIビジネス本部第3ブロック",
      "第2SIビジネス本部第1ブロック",
      "第2SIビジネス本部第2ブロック",
      "第2SIビジネス本部第3ブロック",
      "金融ビジネス本部第1ブロック",
      "金融ビジネス本部第2ブロック",
      "金融ビジネス本部第3ブロック",
      "マーケティング統括本部",
      "品質統括本部",
      "首都圏営業",
      "品川人事総務",
      "関西SIビジネス第1ブロック",
      "関西SIビジネス第2ブロック",
      "関西SIビジネス第3ブロック",
      "大阪営業",
      "中部SIビジネス本部",
      "九州",
    ],
    datasets: [
      {
        label: "食事会",
        data: [12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3, 9],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
        stack: 1,
      },
      {
        label: "差入れ",
        data: [1, 2, 4, 3, 5, 1, 3, 4, 5, 2, 3, 4, 5, 1, 3, 4, 2, 3, 4],
        backgroundColor: "rgba(54, 162, 235, 0.5)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
        stack: 1,
      },
      {
        label: "ゴルフ・レクリエーション",
        data: [9, 7, 8, 6, 7, 8, 6, 7, 6, 5, 6, 9, 8, 9, 8, 6, 7, 8, 7],
        backgroundColor: "rgba(255, 206, 86, 0.5)",
        borderColor: "rgba(255, 206, 86, 1)",
        borderWidth: 1,
        stack: 1,
      },
      {
        label: "その他",
        data: [1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 4, 1, 3, 4, 5, 3, 3, 1, 2],
        backgroundColor: "rgba(75, 192, 192, 0.5)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        stack: 1,
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  useEffect(() => {
    const fetchApprovalCount = async () => {
      const param = { status: "UNDECIDED" };
      const result = await GetApi("/money-ticket/approval/count", param);

      console.log(result);
      if (result.statusCode >= 400) {
        setMessageBarMessage(result.message);
        setMessageBarOpen(true);
        return;
      }
    };
    const fetchMoneyTicketMemberData = async () => {
      const result = await GetApi("/money-ticket/balance/members");

      if (result.statusCode >= 400) {
        setMessageBarMessage(result.message);
        setMessageBarOpen(true);
        return;
      }
    };

    const fetchMoneyTicketData = async () => {
      const result = await GetApi("/money-ticket/balance");

      if (result.statusCode >= 400) {
        setMessageBarMessage(result.message);
        setMessageBarOpen(true);
        return;
      }
    };

    const fetchHistoryData = async () => {
      const result = await GetApi("/money-ticket/history");

      if (result.statusCode >= 400) {
        setMessageBarMessage(result.message);
        setMessageBarOpen(true);
        return;
      }
    };

    fetchApprovalCount();
    fetchMoneyTicketData();
    fetchHistoryData();
    fetchMoneyTicketMemberData();
  }, []);

  return (
    <ViewWrapper>
      <Card>
        <SelectBox
          title={"期間"}
          options={[
            {
              code: "1",
              name: "2021年5月 - 2021年7月",
            },
            {
              code: "2",
              name: "2021年8月 - 2021年10月",
            },
            {
              code: "3",
              name: "2021年11月 - 2022年1月",
            },
            {
              code: "4",
              name: "2022年2月 - 2022年4月",
            },
          ]}
          onChange={() => {}}
        />

        <Bar
          type={"Bar"}
          data={barData}
          width={100}
          height={isWidthUp("sm", width) ? 50 : 100}
          options={options}
        />

        <Chart
          options={{
            title: {
              text: "流通状況",
            },
            chart: {
              height: 350,
              type: "heatmap",
            },
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              labels: {
                rotate: -90,
                trim: false,
                maxHeight: 200,
                style: {
                  colors: [],
                  fontSize: "10px",
                },
              },
            },
            yaxis: {
              labels: {
                align: "right",
                trim: false,
                minWidth: 50,
                maxWidth: 100,
                style: {
                  colors: [],
                  fontSize: "10px",
                },
              },
            },
            plotOptions: {
              heatmap: {
                useFillColorAsStroke: false,
                radius: 0,
                shadeIntensity: 0.7,
                colorScale: {
                  ranges: [
                    {
                      from: 1,
                      to: 10,
                      color: "#B3FFE7",
                      name: "low",
                    },
                    {
                      from: 11,
                      to: 20,
                      color: "#A8E000",
                      name: "medium",
                    },
                    {
                      from: 21,
                      to: 50,
                      color: "#FF8F00",
                      name: "high",
                    },
                  ],
                },
              },
            },
          }}
          series={[
            {
              name: "AW - SC",
              data: [
                {
                  x: "第1SIビジネス本部第1ブロック",
                  y: 0,
                },
                {
                  x: "第1SIビジネス本部第2ブロック",
                  y: 2,
                },
                {
                  x: "第1SIビジネス本部第3ブロック",
                  y: 1,
                },
                {
                  x: "第2SIビジネス本部第1ブロック",
                  y: 3,
                },
                {
                  x: "第2SIビジネス本部第2ブロック",
                  y: 3,
                },
                {
                  x: "第2SIビジネス本部第3ブロック",
                  y: 3,
                },
                {
                  x: "金融ビジネス本部第1ブロック",
                  y: 3,
                },
                {
                  x: "金融ビジネス本部第2ブロック",
                  y: 3,
                },
                {
                  x: "金融ビジネス本部第3ブロック",
                  y: 3,
                },
                {
                  x: "マーケティング統括本部",
                  y: 3,
                },
                {
                  x: "品質統括本部",
                  y: 3,
                },
                {
                  x: "首都圏営業",
                  y: 3,
                },
                {
                  x: "品川人事総務",
                  y: 3,
                },
                {
                  x: "関西SIビジネス第1ブロック",
                  y: 3,
                },
                {
                  x: "関西SIビジネス第2ブロック",
                  y: 3,
                },
                {
                  x: "関西SIビジネス第3ブロック",
                  y: 3,
                },
                {
                  x: "大阪営業",
                  y: 3,
                },
                {
                  x: "中部SIビジネス本部",
                  y: 3,
                },
                {
                  x: "九州",
                  y: 3,
                },
              ],
            },
            {
              name: "SI - AI",
              data: [
                {
                  x: "第1SIビジネス本部第1ブロック",
                  y: 4,
                },
                {
                  x: "第1SIビジネス本部第2ブロック",
                  y: 5,
                },
                {
                  x: "第1SIビジネス本部第3ブロック",
                  y: 10,
                },
                {
                  x: "第2SIビジネス本部第1ブロック",
                  y: 3,
                },
                {
                  x: "第2SIビジネス本部第2ブロック",
                  y: 3,
                },
                {
                  x: "第2SIビジネス本部第3ブロック",
                  y: 10,
                },
                {
                  x: "金融ビジネス本部第1ブロック",
                  y: 3,
                },
                {
                  x: "金融ビジネス本部第2ブロック",
                  y: 3,
                },
                {
                  x: "金融ビジネス本部第3ブロック",
                  y: 5,
                },
                {
                  x: "マーケティング統括本部",
                  y: 10,
                },
                {
                  x: "品質統括本部",
                  y: 1,
                },
                {
                  x: "首都圏営業",
                  y: 3,
                },
                {
                  x: "品川人事総務",
                  y: 3,
                },
                {
                  x: "関西SIビジネス第1ブロック",
                  y: 3,
                },
                {
                  x: "関西SIビジネス第2ブロック",
                  y: 3,
                },
                {
                  x: "関西SIビジネス第3ブロック",
                  y: 6,
                },
                {
                  x: "大阪営業",
                  y: 14,
                },
                {
                  x: "中部SIビジネス本部",
                  y: 5,
                },
                {
                  x: "九州",
                  y: 3,
                },
              ],
            },
            {
              name: "M",
              data: [
                {
                  x: "第1SIビジネス本部第1ブロック",
                  y: 10,
                },
                {
                  x: "第1SIビジネス本部第2ブロック",
                  y: 10,
                },
                {
                  x: "第1SIビジネス本部第3ブロック",
                  y: 10,
                },
                {
                  x: "第2SIビジネス本部第1ブロック",
                  y: 15,
                },
                {
                  x: "第2SIビジネス本部第2ブロック",
                  y: 20,
                },
                {
                  x: "第2SIビジネス本部第3ブロック",
                  y: 13,
                },
                {
                  x: "金融ビジネス本部第1ブロック",
                  y: 13,
                },
                {
                  x: "金融ビジネス本部第2ブロック",
                  y: 13,
                },
                {
                  x: "金融ビジネス本部第3ブロック",
                  y: 13,
                },
                {
                  x: "マーケティング統括本部",
                  y: 13,
                },
                {
                  x: "品質統括本部",
                  y: 13,
                },
                {
                  x: "首都圏営業",
                  y: 13,
                },
                {
                  x: "品川人事総務",
                  y: 13,
                },
                {
                  x: "関西SIビジネス第1ブロック",
                  y: 13,
                },
                {
                  x: "関西SIビジネス第2ブロック",
                  y: 13,
                },
                {
                  x: "関西SIビジネス第3ブロック",
                  y: 13,
                },
                {
                  x: "大阪営業",
                  y: 13,
                },
                {
                  x: "中部SIビジネス本部",
                  y: 13,
                },
                {
                  x: "九州",
                  y: 13,
                },
              ],
            },
            {
              name: "次部長以上",
              data: [
                {
                  x: "第1SIビジネス本部第1ブロック",
                  y: 40,
                },
                {
                  x: "第1SIビジネス本部第2ブロック",
                  y: 40,
                },
                {
                  x: "第1SIビジネス本部第3ブロック",
                  y: 20,
                },
                {
                  x: "第2SIビジネス本部第1ブロック",
                  y: 30,
                },
                {
                  x: "第2SIビジネス本部第2ブロック",
                  y: 30,
                },
                {
                  x: "第2SIビジネス本部第3ブロック",
                  y: 30,
                },
                {
                  x: "金融ビジネス本部第1ブロック",
                  y: 33,
                },
                {
                  x: "金融ビジネス本部第2ブロック",
                  y: 20,
                },
                {
                  x: "金融ビジネス本部第3ブロック",
                  y: 22,
                },
                {
                  x: "マーケティング統括本部",
                  y: 10,
                },
                {
                  x: "品質統括本部",
                  y: 11,
                },
                {
                  x: "首都圏営業",
                  y: 12,
                },
                {
                  x: "品川人事総務",
                  y: 15,
                },
                {
                  x: "関西SIビジネス第1ブロック",
                  y: 30,
                },
                {
                  x: "関西SIビジネス第2ブロック",
                  y: 20,
                },
                {
                  x: "関西SIビジネス第3ブロック",
                  y: 30,
                },
                {
                  x: "大阪営業",
                  y: 10,
                },
                {
                  x: "中部SIビジネス本部",
                  y: 15,
                },
                {
                  x: "九州",
                  y: 10,
                },
              ],
            },
          ]}
          type="heatmap"
          height={400}
        />

        <MessageBar
          open={messageBarOpen}
          setOpen={setMessageBarOpen}
          severity={"error"}
          message={messageBarMessage}
        />
      </Card>
    </ViewWrapper>
  );
};

export default withWidth()(UsageData);
