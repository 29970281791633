import React, { ChangeEvent, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, MenuItem, Select } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  selectBox: {
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
  label: {
    width: "150px",
  },
  cp_ipselect: {
    flex: 1,
    overflow: "hidden",
    maxWidth: "400px",
    textAlign: "center",
    "& select": {
      width: "100%",
      paddingRight: "1em",
      cursor: "pointer",
      textIndent: "0.01px",
      textOverflow: "ellipsis",
      border: "none",
      outline: "none",
      background: "transparent",
      backgroundImage: "none",
      boxShadow: "none",
      // appearance: "none",
      "&::-ms-expand": {
        display: "none",
      },
    },
  },
  cp_sl01: {
    position: "relative",
    border: "1px solid #ddd",
    borderRadius: "3px",
    height: "37px",
    background: theme.palette.lpTextBox.main,
    // marginTop: "5px",
    // "&::before": {
    //   position: "absolute",
    //   top: "0.8em",
    //   right: "0.9em",
    //   width: "0",
    //   height: "0",
    //   padding: "0",
    //   content: '',
    //   borderLeft: "6px solid transparent",
    //   borderRight: "6px solid transparent",
    //   borderTop: "6px solid #666666",
    //   pointerEvents: "none",
    // },
    "& select": {
      padding: "10px 15px",
      color: "#000",
      "& .PrivateNotchedOutline-root-37 MuiOutlinedInput-notchedOutline": {
        border: "none",
        // outline: "none",
      },
      "&:hover": {
        background: theme.palette.lpTextBox.dark,
      },
      "&:focus": {
        outline: "0",
        border: "1px solid " + theme.palette.lpSecond.main,
        background: theme.palette.lpTextBox.light,
      },
    },
    "& select:disabled": {
      // padding: "9px 15px",
      // color: "#ffffff",
      backgroundColor: "rgb(234, 233, 233)",
      "&:hover": {
        backgroundColor: "rgb(234, 233, 233)",
      },
      "&:focus": {
        outline: "0",
        border: "1px solid " + theme.palette.lpSecond.main,
        backgroundColor: "rgb(234, 233, 233)",
      },
    },
  },
  select: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      // outline: "none",
    },
    "& .Mui-focused": {
      fontSize: "12px",
      // outline: "none",
    },
    "& .MuiMenuItem-root": {
      fontSize: "12px",
      // outline: "none",
    },
    "& .MuiSelect-selectMenu": {
      minHeight: "10px",
      height: "18px",
      padding: "10px",
      fontSize: "12px",
      width: "100px",
      border: "1px solid #ddd",
      borderRadius: "3px",
      // height: "37px",
      "&:hover": {
        background: theme.palette.lpTextBox.dark,
      },
      "&:focus": {
        outline: "0",
        border: "1px solid " + theme.palette.lpSecond.main,
        background: theme.palette.lpTextBox.light,
      },
    },
  },
}));

export type SelectBoxOption = {
  code: string;
  name: string;
};

type SelectBoxProps = {
  options: SelectBoxOption[];
  value?: string;
  required?: boolean;
  maxWidth?: string;
  labelWidth?: string;
  width?: string;
  disabled?: boolean;
  onChange: (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => void;
  title?: string;
};

export const SelectBox: React.FC<SelectBoxProps> = ({
  options = [],
  value = "",
  title = "",
  required = false,
  maxWidth = "100%",
  labelWidth = "100px",
  width = "100%",
  disabled = false,
  onChange = () => {},
}: SelectBoxProps) => {
  const classes = useStyles();

  const optionsList = options.map((option: SelectBoxOption) => (
    // <option key={option.code} value={option.code}>
    //   {option.name}
    // </option>
    <MenuItem value={option.code}>{option.name}</MenuItem>
  ));

  return (
    <Fragment>
      {/* <div className={classes.selectBox}>
        {title && (
          <label className={classes.label} style={{ width: labelWidth }}>
            {title}
            {required && <span style={{ color: "red" }}> *</span>}
          </label>
        )}
        <div
          className={classes.cp_ipselect + " " + classes.cp_sl01}
          style={{ maxWidth: maxWidth }}
        >
          <select value={value} onChange={onChange} disabled={disabled}>
            {optionsList}
          </select>
        </div>
      </div> */}
      <div className={classes.selectBox}>
        {title && (
          <label className={classes.label} style={{ width: labelWidth }}>
            {title}
            {required && <span style={{ color: "red" }}> *</span>}
          </label>
        )}
        <FormControl variant="outlined">
          {/* <InputLabel id="demo-simple-select-outlined-label">Age</InputLabel> */}
          <Select
            value={value}
            onChange={onChange}
            className={classes.select}
            style={{ maxWidth: maxWidth }}
            disabled={disabled}
          >
            {optionsList}
          </Select>
        </FormControl>
      </div>
    </Fragment>
  );
};
