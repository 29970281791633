import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  textBox: {
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
  label: {},
  inputGroup: {
    display: "flex",
  },
  icon: {
    marginTop: "5px",
    padding: "8px 16px 0 16px",
    borderRadius: "3px 0 0 3px",
    background: theme.palette.lpGray.light,
    border: "1px solid #ddd",
  },
  input: {
    "&:read-only": {
      backgroundColor: "rgb(234, 233, 233)",
      "&:hover": {
        backgroundColor: "rgb(234, 233, 233)",
      },
      "&:focus": {
        backgroundColor: "rgb(234, 233, 233)",
      },
    },
    flex: 1,
    resize: "none",
    marginTop: "5px",
    width: "100%",
    padding: "10px 15px",
    background: theme.palette.lpTextBox.main,
    borderRadius: "3px",
    border: "1px solid #ddd",
    boxSizing: "border-box",
    "&:hover": {
      background: theme.palette.lpTextBox.dark,
    },
    "&:focus": {
      outline: "0",
      border: "1px solid " + theme.palette.lpSecond.main,
      background: theme.palette.lpTextBox.light,
    },
  },
  errorInput: {
    resize: "none",
    marginTop: "5px",
    width: "100%",
    padding: "10px 15px",
    background: theme.palette.lpTextBox.main,
    borderRadius: "3px",
    border: "1px solid red",
    boxSizing: "border-box",
    "&:hover": {
      background: theme.palette.lpTextBox.dark,
    },
    "&:focus": {
      outline: "0",
      border: "1px solid " + theme.palette.lpSecond.main,
      background: theme.palette.lpTextBox.light,
    },
  },
  inputIcon: {
    marginTop: "5px",
    width: "100%",
    padding: "10px 15px",
    borderRadius: "0 3px 3px 0",
    background: theme.palette.lpTextBox.main,
    border: "1px solid #ddd",
    boxSizing: "border-box",
    "&:hover": {
      background: theme.palette.lpTextBox.dark,
    },
    "&:focus": {
      outline: "0",
      border: "1px solid " + theme.palette.lpSecond.main,
      background: theme.palette.lpTextBox.light,
      borderRadius: "0 3px 3px 0",
    },
  },
  error: {
    color: "red",
  },
}));

type TextAreaProps = {
  id?: string;
  title?: string;
  value?: string;
  width?: string;
  maxWidth?: string;
  icon?: string;
  placeholder?: string;
  error?: string;
  multiline?: boolean;
  row?: number;
  labelWidth?: string;
  required?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

export const TextArea: React.FC<TextAreaProps> = ({
  id = "",
  title = "",
  value = "",
  width = "",
  maxWidth = "",
  icon = "",
  placeholder = "",
  error = "",
  multiline = false,
  row = 1,
  labelWidth = "100px",
  required = false,
  onChange = () => {},
}: TextAreaProps) => {
  const classes = useStyles();
  //const { title, value, width, icon, placeholder, onChange, error } = props

  return (
    <div className={classes.textBox}>
      <div className={classes.label} style={{ width: labelWidth }}>
        {title}
        {required && <span style={{ color: "red" }}> *</span>}
      </div>
      {icon ? (
        <div className={classes.inputGroup}>
          <div className={classes.icon}>
            <span>
              <i className={icon}></i>
            </span>
          </div>
          <textarea
            id={id}
            value={value}
            placeholder={placeholder}
            className={error ? classes.errorInput : classes.input}
            onChange={onChange}
          ></textarea>
        </div>
      ) : (
        <Fragment>
          <textarea
            id={id}
            value={value}
            rows={2}
            placeholder={placeholder}
            style={{ maxWidth: maxWidth }}
            className={error ? classes.errorInput : classes.input}
            onChange={onChange}
          ></textarea>
          {/* <input
            id={id}
            className={error ? classes.errorInput : classes.input}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
          /> */}
          {error && <div className={classes.error}>{error}</div>}
        </Fragment>
      )}
    </div>
  );
};
