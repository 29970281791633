import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  ViewWrapper,
  Button,
  Table,
  Block,
  MessageBar,
  MoneyTicket,
  ImageModal,
  MoneyTicketStatusSteper,
  ImagePreview,
  TextBox,
  Card,
} from "../../components";
import { useHistory } from "react-router-dom";
import { GetApi, PutApi } from "../../utils";
import Typography from "@material-ui/core/Typography";
import {
  LoaderContext,
  ActionEnum as LoaderActionEnum,
} from "../../stores/LoaderStore";
import {
  JudgmentContext,
  ActionEnum as JudgmentActionEnum,
} from "../../stores/JudgmentStore";
import { LoginContext } from "../../stores/LoginStore";
import { judgmentBackgroundColor, judgmentType } from "../../definitions/const";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
  },
  title: {
    color: theme.palette.grey[400],
  },
}));

export const ApprovalDetail = (props: any) => {
  // クエリパラメータの取得
  const { id, setId, data, setData, setTabValue } = props;

  // 定数定義
  const classes = useStyles();
  const history = useHistory();
  const { state: loginState } = useContext(LoginContext);
  const { dispatch: loaderDispatch } = useContext(LoaderContext);
  const { state: judgmentState, dispatch: judgmentDispatch } =
    useContext(JudgmentContext);
  const [loading, setLoading] = useState(true);
  const [createdAt, setCreatedAt] = useState("");
  const [applyUser, setApplyUser] = useState("");
  const [applyId, setApplyId] = useState("");
  const [applyCategory, setApplyCategory] = useState("");
  const [usageClassification, setUsageClassification] = useState("");
  const [usageClassificationDetail, setUsageClassificationDetail] =
    useState("");
  const [peopleNumber, setPeopleNumber] = useState("");
  const [online, setOnline] = useState("");
  const [useDate, setUseDate] = useState("");
  const [totalamounts, setTotalamounts] = useState(0);
  const [messageBarMessage, setMessageBarMessage] = useState("");
  const [messageBarOpen, setMessageBarOpen] = useState(false);
  const [judgmentButton, setJudgmentButton] = useState(false);
  const [approvalList, setApprovalList] = useState<any[]>([]);
  const [images, setImages] = useState([]);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageId, setImageId] = useState("");
  const [status, setStatus] = useState("");
  const [comment, setComment] = useState("");
  const [judgeComment, setJudgeComment] = useState("");
  const [judgeCommentError, setJudgeCommentError] = useState("");
  const [useDateReason, setUseDateReason] = useState("");
  const [distributorNumberReason, setDistributorNumberReason] = useState("");
  const [receiptTotalAmounts, setReceiptTotalAmounts] = useState("");

  const handlerApproveJudgment = (judgment: string) => {
    const update = async () => {
      // 否認の場合のみ、判定コメント必須
      if (judgment === "REJECTION") {
        if (judgeComment.length === 0) {
          setMessageBarMessage("判定コメントを入力してください。");
          setMessageBarOpen(true);
          setJudgeCommentError("判定コメントを入力してください。");
          return;
        } else {
          setJudgeCommentError("");
        }
      } else {
        setJudgeCommentError("");
      }

      if (judgeComment.length > 200) {
        setMessageBarMessage("判定コメントは 200 文字以下で入力してください。");
        setMessageBarOpen(true);
        setJudgeCommentError("判定コメントは 200 文字以下で入力してください。");
        return;
      } else {
        setJudgeCommentError("");
      }
      loaderDispatch({
        type: LoaderActionEnum.SET_LOADER_TRUE,
      });
      const body = {
        judgment: judgment,
        comment: judgeComment,
      };
      const result = await PutApi(
        "/money-ticket/apply/" + id + "/judgment",
        body
      );

      loaderDispatch({
        type: LoaderActionEnum.SET_LOADER_FALSE,
      });

      // 処理成功
      if (result.statusCode >= 400) {
        setMessageBarMessage(result.message);
        setMessageBarOpen(true);
      } else {
        let nextRow = 0;

        const filteredData = data.filter((d: any, index: number) => {
          if (d.apply_id === id) {
            nextRow = index;
          }
          return d.apply_id !== id;
        });
        if (filteredData.length === 0) {
          setId("");
          setTabValue(0);
        } else if (filteredData.length <= nextRow) {
          setId(filteredData[nextRow - 1].apply_id);
        } else {
          setId(filteredData[nextRow].apply_id);
        }
        setJudgeComment("");
        setData(filteredData);

        judgmentDispatch({
          type: JudgmentActionEnum.CHANGE_DATA_JUDGMENT_APPLY,
          payload: {
            data: {
              judgment: {
                apply: judgmentState.data.judgment.apply - 1,
              },
            },
          },
        });
      }
    };
    update();
  };

  const fetchData = async () => {
    setLoading(true);
    if (!id) {
      return;
    }
    const result = await GetApi("/money-ticket/apply/" + id);
    if (result.data) {
      setTotalamounts(result.data?.amounts);
      setCreatedAt(result.data?.created_at);
      setApplyUser(result.data?.applicant_name);
      setImages(result.data?.receipt_images.split(","));
      setApplyId(result.data?.display_apply_id);
      setApplyCategory(result.data?.apply_category);
      setUsageClassification(result.data?.apply_usage_classification);
      setUsageClassificationDetail(
        result.data?.apply_usage_classification_detail || "-"
      );
      setPeopleNumber(result.data?.people_number || "-");
      setOnline(result.data?.online || "-");
      setUseDate(result.data?.use_date || "-");
      setStatus(result.data?.apply_status);
      setComment(result.data?.comment);

      setDistributorNumberReason(result.data?.distributor_number_reason || "");
      setUseDateReason(result.data?.use_date_reason || "");
      setReceiptTotalAmounts(result.data?.receipt_total_amounts);

      //自分の判定を取得
      let me = result.data?.authorizer.filter(
        (user: any) => user.authorizer_bs === loginState.loginUserInfo?.bs
      );
      if (
        (me.length > 1 && me[1].judgment === "未判定") ||
        (me.length > 0 && me[0].judgment === "未判定")
      ) {
        setJudgmentButton(true);
      } else {
        setJudgmentButton(false);
      }

      setApprovalList(result.data?.authorizer);
    } else {
      history.push("/notfound");
    }
    setLoading(false);
  };

  const onClickClose = () => {
    setImageModalOpen(false);
  };

  const handleChangeJudgeComment = (e: any) => {
    setJudgeComment(e.target.value);
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    fetchData();
    setJudgeCommentError("");
  }, [id]);

  return (
    <ViewWrapper>
      <MessageBar
        open={messageBarOpen}
        setOpen={setMessageBarOpen}
        severity={"error"}
        message={messageBarMessage}
      />
      <ImageModal
        isOpen={imageModalOpen}
        onClickClose={onClickClose}
        imageId={imageId}
      />

      <Card title={"金券精算申請　明細"} titleLayout={true}>
        <MoneyTicket
          loading={loading}
          maxWidth="300px"
          expire={"金券使用額"}
          amounts={Number(totalamounts).toLocaleString()}
        ></MoneyTicket>
        <Card loading={loading}>
          <Block loading={loading}>
            <div className={classes.title}>ステータス</div>
            <MoneyTicketStatusSteper status={status} />

            <div className={classes.title}>申請情報</div>
            <Typography variant="body2" gutterBottom>
              {applyId}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {applyUser}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {moment(createdAt).format("YYYY年MM月DD日 HH時mm分")}
            </Typography>

            <div className={classes.title}>申請種別</div>
            <Typography variant="body2" gutterBottom>
              {applyCategory}
            </Typography>

            <div className={classes.title}>利用区分</div>
            <Typography variant="body2" gutterBottom>
              {usageClassification}
            </Typography>

            <div className={classes.title}>摘要</div>
            <Typography variant="body2" gutterBottom>
              {usageClassificationDetail}
            </Typography>

            <div className={classes.title}>使用日</div>
            <Typography variant="body2" gutterBottom>
              {useDate === "-" ? "-" : moment(useDate).format("YYYY年MM月DD日")}
            </Typography>
            {useDateReason && (
              <Typography
                variant="body2"
                gutterBottom
                style={{ color: "blue" }}
              >
                土日祝申請理由：{useDateReason}
              </Typography>
            )}

            <div className={classes.title}>人数</div>
            <Typography variant="body2" gutterBottom>
              {peopleNumber}
            </Typography>
            {distributorNumberReason && (
              <Typography
                variant="body2"
                gutterBottom
                style={{ color: "blue" }}
              >
                １人申請理由：{distributorNumberReason}
              </Typography>
            )}

            <div className={classes.title}>リアル/オンライン</div>
            <Typography variant="body2" gutterBottom>
              {online}
            </Typography>

            {comment && (
              <Fragment>
                <div className={classes.title}>備考欄</div>
                <Typography variant="body2" gutterBottom>
                  <p style={{ whiteSpace: "pre", margin: "0px" }}>{comment}</p>
                </Typography>
              </Fragment>
            )}

            {images.length > 0 && images[0] !== "" && (
              <Fragment>
                <div className={classes.title}>領収書合計金額</div>
                <p style={{ color: "red" }}>
                  CHECK!　添付されている領収書の合計金額が入力されているか確認
                </p>
                <MoneyTicket
                  loading={loading}
                  maxWidth={"300px"}
                  expire={""}
                  amounts={Number(receiptTotalAmounts).toLocaleString()}
                />
              </Fragment>
            )}

            {images.length > 0 && images[0] !== "" && (
              <Fragment>
                <div className={classes.title}>領収書</div>
                <p style={{ color: "red" }}>
                  CHECK!　領収書の日付が申請日の３ヶ月以内かを確認
                </p>
              </Fragment>
            )}
            {images.length > 0 &&
              images.map((image: any) => (
                <ImagePreview
                  onClick={() => {
                    setImageId(image);
                    setImageModalOpen(true);
                  }}
                  id={image}
                  key={image}
                />
              ))}
          </Block>

          <Typography variant="body2" gutterBottom>
            判定情報
          </Typography>

          <Table
            loading={loading}
            headers={[
              {
                name: "apply_category",
                disp: "判定者",
                type: "custom",
                component: (
                  rowIndex: any,
                  index: any,
                  header: any,
                  rowData: any
                ) => {
                  return (
                    <td
                      key={index}
                      id={String(header.name) + "_" + String(rowIndex)}
                    >
                      <p style={{ margin: "2px" }}>
                        {rowData["approval_type"]}
                      </p>
                      <p style={{ margin: "2px" }}>{rowData["name"]}</p>
                      <p
                        style={{
                          color: "gray",
                          margin: "2px",
                          fontSize: "6px",
                        }}
                      >
                        {rowData["dept"]}
                      </p>
                    </td>
                  );
                },
              },
              {
                name: "amounts",
                disp: "金額",
                type: "moneyText",
              },
              {
                name: "apply_category",
                disp: "判定情報",
                type: "custom",
                component: (
                  rowIndex: any,
                  index: any,
                  header: any,
                  rowData: any
                ) => {
                  return (
                    <td
                      key={index}
                      id={String(header.name) + "_" + String(rowIndex)}
                    >
                      <div
                        id={String(header.name) + "_" + String(rowIndex)}
                        style={{
                          maxWidth: "70px",
                          background:
                            judgmentBackgroundColor[rowData["judgment"]],
                          borderRadius: "20px 20px 20px 20px",
                          color: "white",
                          padding: "4px",
                          textAlign: "center",
                        }}
                      >
                        {rowData["judgment"]}
                      </div>
                      <p style={{ margin: "2px" }}>
                        {!rowData["judgmented_at"]
                          ? ""
                          : moment(rowData["judgmented_at"]).format(
                              "YYYY年MM月DD日 HH時mm分"
                            )}
                      </p>
                      <p style={{ margin: "2px" }}>{rowData["comment"]}</p>
                    </td>
                  );
                },
              },
            ]}
            data={approvalList}
          />
        </Card>
      </Card>
      {!loading && (
        <Fragment>
          {judgmentButton && (
            <TextBox
              title={"判定コメント"}
              value={judgeComment}
              error={judgeCommentError}
              onChange={handleChangeJudgeComment}
            />
          )}
          <div className={classes.buttonGroup}>
            {judgmentButton && (
              <Fragment>
                <Button
                  title="承認"
                  variant="contained"
                  onClick={() => handlerApproveJudgment("APPROVAL")}
                />
                <Button
                  title="差し戻し"
                  variant="contained"
                  onClick={() => handlerApproveJudgment("REJECTION")}
                />
              </Fragment>
            )}
            <Button
              title="閉じる"
              variant="outlined"
              onClick={() => {
                setId("");
                setTabValue(0);
              }}
            />
          </div>
        </Fragment>
      )}
    </ViewWrapper>
  );
};
