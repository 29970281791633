import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useEffect, useState } from "react";
import {
  ViewWrapper,
  Button,
  Card,
  TextBox,
  MessageBar,
  ModalUserList,
  SelectBox,
  Table,
} from "../../components";
import { useHistory } from "react-router-dom";
import { GetApi, PostApi } from "../../utils";
import { LoginContext } from "../../stores/LoginStore";
import {
  LoaderContext,
  ActionEnum as LoaderActionEnum,
} from "../../stores/LoaderStore";
import {
  NotificationContext,
  ActionEnum as NotificationActionEnum,
} from "../../stores/NotificationStore";
import {
  balanceEdittingTypeOptions,
  increaseDecreaseOptions,
} from "../../definitions/selectors";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  userSelect: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

type AmountsData = {
  expire: string;
  beforeAmounts: number;
  editAmounts: string;
  afterAmounts: number;
  increaseDecrease: string;
  textType: "text" | "number";
};
export const BalanceEditingCreate = () => {
  const classes = useStyles();
  const history = useHistory();
  const { dispatch: notificationDispatch } = useContext(NotificationContext);
  const { state: loginState } = useContext(LoginContext);
  const { dispatch: loaderDispatch } = useContext(LoaderContext);
  const [targetUserName, setTargetUserName] = useState("");
  const [bs, setBs] = useState("");
  const [userSelectModalIsOpen, setUserSelectModalIsOpen] = useState(false);
  const [searchEmployee, setSearchEmployee] = useState(
    loginState.loginUserInfo?.dept
  );
  const [messageBarMessage, setMessageBarMessage] = useState("");
  const [messageBarOpen, setMessageBarOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState("");
  const [type, setType] = useState("1");
  const [modalSelectedUserList, setModalSelectedUserList] = useState<string[]>(
    []
  );
  const [amountsData, setAmountsData] = useState<AmountsData[]>([]);

  // イベント処理処理
  // 選択ユーザ変更時
  useEffect(() => {
    const getAmounts = async () => {
      const result: {
        statusCode: number;
        message: string;
        data: {
          amounts: number;
          expire: string;
        }[];
      } = await GetApi(`/money-ticket/balance/${bs}`);

      console.log(result);
      if (result.statusCode >= 400) {
        setMessageBarMessage(result.message);
        setMessageBarOpen(true);
        return;
      }
      console.log(result.data);

      const now = moment().utc().add(9, "h");
      const year = now.format("YYYY");
      const month = now.format("MM");
      const day = now.format("DD");
      let expire1 = "";
      let expire2 = "";
      if (month === "05" || month === "06" || month === "07") {
        expire1 = moment({ years: year, months: 7 - 1, days: 1 })
          .endOf("month")
          .format("YYYY-MM-DD");
        if (month === "07" && day >= "20") {
          expire2 = moment({ years: year, months: 10 - 1, days: 1 })
            .endOf("month")
            .format("YYYY-MM-DD");
        }
      } else if (month === "08" || month === "09" || month === "10") {
        expire1 = moment({ years: year, months: 10 - 1, days: 1 })
          .endOf("month")
          .format("YYYY-MM-DD");
        if (month === "10" && day >= "20") {
          expire2 = moment({ years: Number(year) + 1, months: 1 - 1, days: 1 })
            .endOf("month")
            .format("YYYY-MM-DD");
        }
      } else if (month === "11" || month === "12") {
        expire1 = moment({ years: Number(year) + 1, months: 1 - 1, days: 1 })
          .endOf("month")
          .format("YYYY-MM-DD");
      } else if (month === "01") {
        expire1 = moment({ years: year, months: 1 - 1, days: 1 })
          .endOf("month")
          .format("YYYY-MM-DD");
        if (month === "01" && day >= "20") {
          expire2 = moment({ years: year, months: 4 - 1, days: 1 })
            .endOf("month")
            .format("YYYY-MM-DD");
        }
      } else if (month === "02" || month === "03" || month === "04") {
        expire1 = moment({ years: year, months: 4 - 1, days: 1 })
          .endOf("month")
          .format("YYYY-MM-DD");
        if (month === "04" && day >= "20") {
          expire2 = moment({ years: year, months: 7 - 1, days: 1 })
            .endOf("month")
            .format("YYYY-MM-DD");
        }
      }

      let amounts1 = result.data.find(
        (item) => item.expire === expire1
      )?.amounts;
      amounts1 = !amounts1 ? 0 : amounts1;
      let amounts2 = result.data.find(
        (item) => item.expire === expire2
      )?.amounts;
      amounts2 = !amounts2 ? 0 : amounts2;
      if (expire2 === "") {
        setAmountsData([
          {
            expire: expire1,
            beforeAmounts: amounts1,
            editAmounts: "0",
            afterAmounts: amounts1,
            increaseDecrease: "1",
            textType: "text",
          },
        ]);
      } else {
        setAmountsData([
          {
            expire: expire1,
            beforeAmounts: amounts1,
            editAmounts: "0",
            afterAmounts: amounts1,
            increaseDecrease: "1",
            textType: "text",
          },
          {
            expire: expire2,
            beforeAmounts: amounts2,
            editAmounts: "0",
            afterAmounts: amounts2,
            increaseDecrease: "1",
            textType: "text",
          },
        ]);
      }
    };
    if (bs) {
      getAmounts();
    }
  }, [bs]);

  const onTypeSelectChange = (e: any) => {
    setType(e.target.value);
  };

  const regist = () => {
    if (reason === "") {
      setMessageBarMessage("理由詳細を入力してください。");
      setMessageBarOpen(true);
      setReasonError("理由詳細を入力してください。");
      return;
    } else {
      setReasonError("");
    }
    if (reason.length > 200) {
      setMessageBarMessage("理由詳細は 200 文字以下で入力してください。");
      setMessageBarOpen(true);
      setReasonError("理由詳細を入力してください。");
      return;
    } else {
      setReasonError("");
    }

    if (!bs) {
      setMessageBarMessage("対象者を選択してください。");
      setMessageBarOpen(true);
      return;
    }

    let totalEditAmounts = 0;
    for (const item of amountsData) {
      const amounts = item.editAmounts.replaceAll(",", "");
      if (isNaN(Number(amounts))) {
        setMessageBarMessage("金額は数値を入力してください。");
        setMessageBarOpen(true);
        return;
      }
      totalEditAmounts += Number(amounts);
    }

    if (Number(totalEditAmounts) === 0) {
      setMessageBarMessage("編集金額を入力してください。");
      setMessageBarOpen(true);
      return;
    }

    const putData = async () => {
      loaderDispatch({
        type: LoaderActionEnum.SET_LOADER_TRUE,
      });

      const body = [];
      for (const item of amountsData) {
        if (!item.editAmounts || item.editAmounts === "0") {
          continue;
        }
        body.push({
          bs: bs,
          amounts:
            item.increaseDecrease === "1"
              ? Number(item.editAmounts.replaceAll(",", "")) * -1
              : Number(item.editAmounts.replaceAll(",", "")),
          reason: reason,
          type: type,
          expire: item.expire,
        });
      }

      const result = await PostApi("/money-ticket/balance-editing", body);

      loaderDispatch({
        type: LoaderActionEnum.SET_LOADER_FALSE,
      });

      if (result.statusCode >= 400) {
        setMessageBarMessage(result.message);
        setMessageBarOpen(true);
      } else {
        notificationDispatch({
          type: NotificationActionEnum.SET_NOTIFICATION,
          payload: {
            message: "残高編集処理が正常に行われました。",
            severity: "success",
          },
        });
        history.push("/money-ticket/balance-editing");
      }
    };

    putData();
  };

  const handleSearchEmployee = (e: any) => {
    setSearchEmployee(e.target.value);
  };

  const handleUserSelect = (row: any) => {
    setTargetUserName(row.name);
    setBs(row.bs);
    setUserSelectModalIsOpen(false);
    setModalSelectedUserList([row.bs]);
  };

  const onClickCloseUserSelectModal = () => {
    setUserSelectModalIsOpen(false);
  };

  const onClickUserSelect = () => {
    setUserSelectModalIsOpen(true);
  };

  const onChangeReason = (e: any) => {
    setReason(e.target.value);
  };

  const onChangeEditAmounts = (e: any, row: AmountsData) => {
    console.log(e);
    // windows chrome の自動補完?の対応
    if (
      e.nativeEvent.inputType === "insertText" &&
      !String(e.target.value).match(/^\d+$/)
    ) {
      return;
    }
    const value = e.target.value.replace(/[０-９]/g, function (s: string) {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
    setAmountsData(
      amountsData.map((item) => {
        if (item.expire === row.expire) {
          if (!isNaN(Number(value))) {
            item.editAmounts = value;
            if (item.increaseDecrease === "1") {
              item.afterAmounts = Number(item.beforeAmounts) - Number(value);
            } else {
              item.afterAmounts = Number(item.beforeAmounts) + Number(value);
            }
          }
        }
        return item;
      })
    );
  };
  const onFocusEditAmounts = (e: any, row: AmountsData) => {
    setAmountsData(
      amountsData.map((item) => {
        if (item.expire === row.expire) {
          const amounts = e.target.value.replaceAll(",", "");
          if (!isNaN(Number(amounts))) {
            item.editAmounts = String(amounts);
          }
          item.textType = "number";
        }
        return item;
      })
    );
  };
  const onBlurEditAmounts = (e: any, row: any) => {
    setAmountsData(
      amountsData.map((item) => {
        if (item.expire === row.expire) {
          const amounts = e.target.value.replaceAll(",", "");
          if (!isNaN(Number(amounts))) {
            item.editAmounts = Number(amounts).toLocaleString();
          }
          item.textType = "text";
        }
        return item;
      })
    );
  };

  const onChangeIncreaseDecreaseSelect = (e: any, row: any) => {
    setAmountsData(
      amountsData.map((item) => {
        item.increaseDecrease = e.target.value;
        if (item.expire === row.expire) {
          if (item.increaseDecrease === "1") {
            item.afterAmounts =
              Number(item.beforeAmounts) -
              Number(item.editAmounts.replaceAll(",", ""));
          } else {
            item.afterAmounts =
              Number(item.beforeAmounts) +
              Number(item.editAmounts.replaceAll(",", ""));
          }
        }
        return item;
      })
    );
  };

  return (
    <ViewWrapper>
      <MessageBar
        open={messageBarOpen}
        setOpen={setMessageBarOpen}
        severity={"error"}
        message={messageBarMessage}
      />
      <Card title={"編集"} titleLayout={true}>
        <SelectBox
          title={"理由区分"}
          required={true}
          value={type}
          options={balanceEdittingTypeOptions}
          onChange={onTypeSelectChange}
        />
        <TextBox
          title={"理由詳細"}
          id={"amounts"}
          required={true}
          value={reason}
          error={reasonError}
          placeholder={
            "いつの　何のために　対応する申請がある場合は申請No（Spendia/L-Connec+）"
          }
          onChange={onChangeReason}
        />
        <div className={classes.userSelect}>
          <TextBox
            id={"targetUserName"}
            title={"対象者"}
            width={"300px"}
            required={true}
            value={targetUserName}
            readOnly={true}
          />
          <Button title="選択" onClick={onClickUserSelect} />
        </div>

        <Table
          headers={[
            {
              name: "expire",
              disp: "有効期限",
              type: "text",
            },
            {
              name: "beforeAmounts",
              disp: "編集前所有金券",
              type: "moneyText",
            },
            {
              name: "editAmounts",
              disp: "編集金額",
              type: "custom",
              component: (
                rowIndex: any,
                index: any,
                header: any,
                rowData: any
              ) => {
                return (
                  <td key={index}>
                    <div className={classes.userSelect}>
                      <TextBox
                        id={"amounts"}
                        required={true}
                        width={"300px"}
                        value={rowData["editAmounts"]}
                        textAlign={"right"}
                        onChange={(e: any) => onChangeEditAmounts(e, rowData)}
                        onFocus={(e: any) => onFocusEditAmounts(e, rowData)}
                        onBlur={(e: any) => onBlurEditAmounts(e, rowData)}
                        type={rowData["textType"]}
                      />
                      <SelectBox
                        maxWidth={"100px"}
                        value={rowData["increaseDecrease"]}
                        options={increaseDecreaseOptions}
                        onChange={(e: any) =>
                          onChangeIncreaseDecreaseSelect(e, rowData)
                        }
                      />
                    </div>
                  </td>
                );
              },
            },
            {
              name: "afterAmounts",
              disp: "編集後所有金券",
              type: "moneyText",
            },
          ]}
          data={amountsData}
        />
      </Card>

      <div className={classes.buttonGroup}>
        <Button
          id="applyButton"
          title="決定"
          variant="contained"
          onClick={regist}
        />
        <Button
          id="cancelButton"
          title="キャンセル"
          variant="outlined"
          onClick={() => history.push("/money-ticket/balance-editing")}
        />
      </div>
      <ModalUserList
        value={searchEmployee}
        isOpen={userSelectModalIsOpen}
        selectedUsers={modalSelectedUserList}
        onChange={handleSearchEmployee}
        onRowDoubleClick={handleUserSelect}
        onClickClose={onClickCloseUserSelectModal}
      />
    </ViewWrapper>
  );
};
