import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useEffect, useState } from "react";
import {
  ViewWrapper,
  Button,
  Table,
  Card,
  DatePicker,
  TextBox,
  MessageBar,
  ImageArea,
  ModalUserList,
  SelectBox,
  TextArea,
} from "../../components";
import { useHistory } from "react-router-dom";
import { GetApi, PostApi } from "../../utils";
import { LoginContext } from "../../stores/LoginStore";
import {
  LoaderContext,
  ActionEnum as LoaderActionEnum,
} from "../../stores/LoaderStore";
import Divider from "@material-ui/core/Divider";
import * as holiday_jp from "@holiday-jp/holiday_jp";
import Grid from "@material-ui/core/Grid";
import { UsersContext } from "../../stores/UsersStore";
import {
  NotificationContext,
  ActionEnum as NotificationActionEnum,
} from "../../stores/NotificationStore";
import {
  applyCategoryOptions,
  applyCategoryForMemberOptions,
  applyUsageClassificationOptions,
  peopleNumberOptions,
  onlineOptions,
} from "../../definitions/selectors";
import {
  CollectionsBookmarkOutlined,
  KeyboardReturnOutlined,
} from "@material-ui/icons";

import queryString from "query-string";
import { ApprovalTypeCode, ApprovalTypeName } from "../../models/systemCode";

const useStyles = makeStyles((theme) => ({
  textBox: {
    //fontSize: "16px",
  },
  label: {},
  input: {
    marginTop: "5px",
    width: "100%",
    padding: "10px 15px",
    background: theme.palette.lpWhite.dark,
    borderRadius: "3px",
    border: "1px solid #ddd",
    boxSizing: "border-box",
  },
  tableTitleGroup: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: theme.spacing(1),
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
  },
  moneyGroup: {
    display: "flex",
  },
  money: {
    padding: "0 20px 0 20px",
    fontSize: "50px",
    color: "#A3A0F5",
  },
  formGroup: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

type Distribution = {
  authorizer_bs: string;
  name: string;
  dept: string;
  email: string;
  pno: string;
  amounts: string | number;
  pnoReadOnly: boolean;
  amountsReadOnly: boolean;
  textType: "text" | "number";
};

type Superior = {
  approvalType: ApprovalTypeCode;
  route: string;
  authorizer_bs: string;
  name: string;
  dept: string;
  email: string;
};

export const ApplyCreate = (props: any) => {
  const qs = queryString.parse(props.location.search);

  const [approvalStatus, setApprovalStatus] = useState("");
  const [displayApplyId, setDisplayApplyId] = useState("");
  const [applyId, setApplyId] = useState("");
  const classes = useStyles();
  const history = useHistory();
  const { state: loginState } = useContext(LoginContext);
  const { dispatch: notificationDispatch } = useContext(NotificationContext);
  const { dispatch: loaderDispatch } = useContext(LoaderContext);
  const { state: usersState } = useContext(UsersContext);
  const [superiorModalIsOpen, setSuperiorModalIsOpen] = useState(false);
  /** 第1承認者の変更ボタンクリック時に、どの承認者の行をクリックしたかを格納しておく変数 */
  const [selectedSuperiorRowOrder, setSelectedSuperiorRowOrder] = useState<
    string | null
  >(null);
  const [addDistributionModalIsOpen, setAddDistributionModalIsOpen] =
    useState(false);
  const [commentError, setCommentError] = useState("");
  const [searchEmployee, setSearchEmployee] = useState(
    loginState.loginUserInfo?.dept
  );
  const [messageBarMessage, setMessageBarMessage] = useState("");
  const [messageBarOpen, setMessageBarOpen] = useState(false);
  const [distributionList, setDistributionList] = useState<Distribution[]>([
    {
      authorizer_bs: loginState.loginUserInfo?.bs || "",
      name: loginState.loginUserInfo?.name || "",
      dept: loginState.loginUserInfo?.dept || "",
      email: loginState.loginUserInfo?.email || "",
      amounts: "",
      pno: loginState.loginUserInfo?.dept_code || "",
      pnoReadOnly: false,
      amountsReadOnly: false,
      textType: "text",
    },
  ]);
  const [superiorList, setSuperiorList] = useState<Superior[]>([]);
  const [images, setImages] = useState<string[]>([]);
  const [comment, setComment] = useState("");
  const [initBalance, setInitBalance] = useState(0);
  const [applyCategory, setApplyCategory] = useState("1");
  const [applyCategoryVisible, setApplyCategoryVisible] = useState(true);
  const [applyUsageClassification, setApplyUsageClassification] =
    useState("11");

  const [applyUsageClassificationDetail, setApplyUsageClassificationDetail] =
    useState("");

  const [
    applyUsageClassificationDetailVisible,
    setApplyUsageClassificationDetailVisible,
  ] = useState(true);
  const [
    applyUsageClassificationDetailError,
    setApplyUsageClassificationDetailError,
  ] = useState("");
  const [peopleNumber, setPeopleNumber] = useState("1");
  const [peopleNumberVisible, setPeopleNumberVisible] = useState(true);
  const [online, setOnline] = useState("1");
  const [onlineVisible, setOnlineVisible] = useState(true);
  const [useAmounts, setUseAmounts] = useState("");
  const [useAmountsError, setUseAmountsError] = useState("");
  const [useAmountsTextType, setUseAmountsTextType] = useState("text");
  const [balance, setBalance] = useState("");
  const [useDate, setUseDate] = useState(new Date());
  const [useDateVisible, setUseDateVisible] = useState(true);
  const [distributorNumber, setDistributorNumber] = useState(1);
  const [receiptTotalAmounts, setReceiptTotalAmounts] = useState("");
  const [receiptTotalAmountsVisible, setReceiptTotalAmountsVisible] =
    useState(true);
  const [receiptTotalAmountsError, setReceiptTotalAmountsError] = useState("");
  const [receiptTotalAmountsTextType, setReceiptTotalAmountsTextType] =
    useState("");
  const [useDateReason, setUseDateReason] = useState("");
  const [useDateReasonError, setUseDateReasonError] = useState("");
  const dayOfWeek = new Date().getDay();
  const holidays = holiday_jp.between(new Date(), new Date());
  const [useDateReasonVisible, setUseDateReasonVisible] = useState(
    dayOfWeek === 0 || dayOfWeek === 6 || holidays.length > 0 ? true : false
  );
  const [distributorNumberReason, setDistributorNumberReason] = useState("");
  const [distributorNumberReasonError, setDistributorNumberReasonError] =
    useState("");
  const [distributorNumberReasonVisible, setDistributorNumberReasonVisible] =
    useState(false);
  const [distributorTotalAmounts, setDistributorTotalAmounts] = useState("");
  const [remainingBalance, setRemainingBalance] = useState("");
  const [bankAccount, setBankAccount] = useState(
    loginState.loginUserInfo?.defaultBankAccount
  );

  const [amountOfMoneyVisible, setAmountOfMoneyVisible] = useState(true);
  const [bankAccountVisible, setBankAccountVisible] = useState(true);
  const [availableBankAccount] = useState(
    loginState.loginUserInfo?.availableBankAccount
  );
  const [distributorInfoTitle, setDistributorInfoTitle] = useState("被配分者");
  const [modalSelectedDistributorList, setModalSelectedDistributorList] =
    useState<string[]>([
      loginState.loginUserInfo?.bs ? loginState.loginUserInfo?.bs : "",
    ]);
  const [modalSelectedSuperiorList, setModalSelectedSuperiorList] = useState<
    string[]
  >([]);
  const [applyButtonName, setApplyButtonName] = useState("申請");
  const [addDistributorButtonEnable, setAddDistributorButtonEnable] =
    useState(true);
  const [addFirstSuperiorButtonEnable, setAddFirstSuperiorButtonEnable] =
    useState(true);

  //再申請時の処理
  useEffect(() => {
    const getData = async () => {
      console.log(qs);
      if (qs.selectedId) {
        const result = await GetApi(
          "/money-ticket/apply/" + qs.selectedId + `/reapply`
        );
        if (result.statusCode >= 400) {
          notificationDispatch({
            type: NotificationActionEnum.SET_NOTIFICATION,
            payload: {
              message: result.message,
              severity: "error",
            },
          });
          // setMessageBarMessage(result.message);
          // setMessageBarOpen(true);
          history.push("/money-ticket/apply");
          return;
        }
        const applyData = result.data;

        //金券使用額
        setUseAmounts(Number(applyData.amounts).toLocaleString());

        //所有金券
        setBalance(
          (Number(initBalance) - Number(applyData.amounts)).toLocaleString()
        );

        //振込先口座
        let responseApplyUsageClassification = "";
        if (applyData.apply_category === "一般") {
          setApplyCategory("1");
          //利用区分
          if (applyData.apply_usage_classification === "飲食・差入") {
            responseApplyUsageClassification = "11";
          } else if (applyData.apply_usage_classification === "物品購入") {
            setPeopleNumberVisible(false);
            setOnlineVisible(false);
            setUseDateReasonVisible(false);
            setDistributorNumberReasonVisible(false);
            responseApplyUsageClassification = "12";
          } else if (applyData.apply_usage_classification === "ゴルフ") {
            setOnlineVisible(false);
            setUseDateReasonVisible(false);
            setDistributorNumberReasonVisible(false);
            responseApplyUsageClassification = "13";
          } else if (applyData.apply_usage_classification === "レジャー") {
            setUseDateReasonVisible(false);
            responseApplyUsageClassification = "14";
          }
        } else if (applyData.apply_category === "社内交際費") {
          setApplyCategory("2");
          setPeopleNumberVisible(false);
          setUseDateReasonVisible(false);
          setDistributorNumberReasonVisible(false);
          //利用区分
          if (applyData.apply_usage_classification === "凛カップ景品") {
            responseApplyUsageClassification = "21";
          } else if (applyData.apply_usage_classification === "LMO") {
            responseApplyUsageClassification = "22";
          } else if (applyData.apply_usage_classification === "祀") {
            responseApplyUsageClassification = "23";
          } else {
            console.log("not exist applyData.apply_usage_classification");
          }
        }
        setApplyUsageClassification(responseApplyUsageClassification);

        //摘要
        setApplyUsageClassificationDetail(
          applyData.apply_usage_classification_detail
        );

        //人数
        //peopleNumberの値で分岐するようにする
        if (applyData.people_number === "1〜5") {
          setPeopleNumber("1");
          //apply_usage_classificationの値で分岐するようにする
        } else if (applyData.people_number === "6〜10") {
          setPeopleNumber("2");
        } else if (applyData.people_number === "11〜") {
          setPeopleNumber("3");
        }

        //リアル/オンライン
        if (applyData.online === "リアル") {
          setOnline("1");
          //onlineの値で分岐するようにする
        } else if (applyData.online === "オンライン") {
          setOnline("2");
        }

        //使用日
        // setUseDate(new Date(applyData.use_date));
        onChangeUseDateChangeWithUsageClassification(
          new Date(applyData.use_date),
          responseApplyUsageClassification
        );

        //理由（使用日）
        setUseDateReason(applyData.use_date_reason);

        //理由（被配分者数）
        setDistributorNumberReason(applyData.distributor_number_reason);

        //備考欄
        setComment(applyData.comment);

        //合計金額
        setReceiptTotalAmounts(
          Number(applyData.receipt_total_amounts).toLocaleString()
        );

        //領収書画像
        setImages(
          !applyData.receipt_images ? [] : applyData.receipt_images.split(",")
        );

        setDistributionList([]);
        setSuperiorList([]);

        let approvalList: Superior[] = [];

        let firstSuperiorOrder = 1;
        for (const n of applyData.authorizer) {
          if (n.approval_type === ApprovalTypeName.DISTRIBUTOR) {
            setDistributionList((prevState: Distribution[]) => [
              ...prevState,
              {
                authorizer_bs: n.authorizer_bs,
                name: n.name,
                dept: n.dept,
                email: n.email,
                amounts: Number(n.amounts).toLocaleString(),
                pno: n.pno,
                pnoReadOnly: applyCategory === "2" ? true : false,
                amountsReadOnly: false,
                textType: "text",
              },
            ]);

            setModalSelectedDistributorList((prevState: any) => [
              ...prevState,
              n.authorizer_bs,
            ]);
          } else if (n.approval_type === ApprovalTypeName.FIRST_SUPERIOR) {
            approvalList.push({
              approvalType: ApprovalTypeCode.FIRST_SUPERIOR,
              authorizer_bs: n.authorizer_bs,
              name: n.name,
              dept: n.dept,
              email: n.email,
              route: ApprovalTypeName.FIRST_SUPERIOR,
              // order: firstSuperiorOrder,
            });
            firstSuperiorOrder += 1;
          } else if (n.approval_type === ApprovalTypeName.SECOND_SUPERIOR) {
            approvalList.push({
              approvalType: ApprovalTypeCode.SECOND_SUPERIOR,
              authorizer_bs: n.authorizer_bs,
              name: n.name,
              dept: n.dept,
              email: n.email,
              route: ApprovalTypeName.SECOND_SUPERIOR,
            });
          }
        }

        setSuperiorList(approvalList);
        if (applyData.apply_status === "修正依頼") {
          setApplyCategoryVisible(false);
          setAddDistributorButtonEnable(false);
          setAddFirstSuperiorButtonEnable(false);
          setApplyButtonName("修正した内容を申請");
          setApprovalStatus("7");
          setDisplayApplyId(applyData.display_apply_id);
          setApplyId(applyData.apply_id);
        }
      } else {
        console.log("not qs");
      }
    };
    getData();
  }, []);

  // 申請登録処理
  const regist = () => {
    const requestData = {
      applyCategory: applyCategory,
      useAmounts: Number(useAmounts.replaceAll(",", "")),
      applyUsageClassification: applyUsageClassification,
      applyUsageClassificationDetail: applyUsageClassificationDetail,
      peopleNumber: peopleNumber,
      online: online,
      useDate: `${useDate.getFullYear().toString()}-${(
        "0" + String(useDate.getMonth() + 1)
      ).slice(-2)}-${("0" + useDate.getDate().toString()).slice(-2)}`,
      useDateReason: useDateReason,
      distributorNumberReason: distributorNumberReason,
      receiptTotalAmounts: Number(receiptTotalAmounts.replaceAll(",", "")),
      comment: comment,
      images: images,
      bankAccount: bankAccount,
    };
    console.log(requestData);

    // 金券使用額
    if (isNaN(requestData.useAmounts)) {
      setMessageBarMessage("金券使用額は数値を入力してください。");
      setMessageBarOpen(true);
      setUseAmountsError("金券使用額は数値を入力してください。");
      return;
    } else {
      setUseAmountsError("");
    }
    if (Number(requestData.useAmounts) === 0) {
      setMessageBarMessage("金券使用額は０より大きい値を入力してください。");
      setMessageBarOpen(true);
      setUseAmountsError("金券使用額は０より大きい値を入力してください。");
      return;
    } else {
      setUseAmountsError("");
    }

    const dayOfWeek = new Date(requestData.useDate).getDay();
    const holidays = holiday_jp.between(
      new Date(requestData.useDate),
      new Date(requestData.useDate)
    );

    //　申請種別ごとのチェック
    if (requestData.applyCategory === "1") {
      // 摘要
      if (requestData.applyUsageClassificationDetail.length === 0) {
        setMessageBarMessage("摘要は、必須です。");
        setMessageBarOpen(true);
        setApplyUsageClassificationDetailError("摘要は、必須です。");
        return;
      } else {
        setApplyUsageClassificationDetailError("");
      }

      // 人数
      if (requestData.applyUsageClassification === "12") {
        requestData.peopleNumber = "";
      }

      // 理由(使用日)
      if (requestData.applyUsageClassification === "11") {
        if (
          requestData.useDateReason === "" &&
          (dayOfWeek === 0 || dayOfWeek === 6 || holidays.length > 0)
        ) {
          setMessageBarMessage("使用日が土日祝日の場合、必須です。");
          setMessageBarOpen(true);
          setUseDateReasonError("使用日が土日祝日の場合、必須です。");
          return;
        } else {
          setUseDateReasonError("");
        }
      } else {
        requestData.useDateReason = "";
      }

      if (requestData.useDateReason.length > 100) {
        setMessageBarMessage("理由(使用日)は 100 文字以下で入力してください。");
        setMessageBarOpen(true);
        setUseDateReasonError(
          "理由(使用日)は 100 文字以下で入力してください。"
        );
        return;
      } else {
        setUseDateReasonError("");
      }

      // 理由(被配分者数)
      if (
        requestData.distributorNumberReason === "" &&
        distributionList.length === 1 &&
        (applyUsageClassification === "11" || applyUsageClassification === "14")
      ) {
        setMessageBarMessage("1人申請時の場合、必須です。");
        setMessageBarOpen(true);
        setDistributorNumberReasonError("1人申請時の場合、必須です。");
        return;
      } else {
        setDistributorNumberReasonError("");
      }
      if (requestData.distributorNumberReason.length > 100) {
        setMessageBarMessage(
          `理由(${`${distributorInfoTitle}の追加`}数)は 100 文字以下で入力してください。`
        );
        setMessageBarOpen(true);
        setDistributorNumberReasonError(
          `理由(${distributorInfoTitle}数)は 100 文字以下で入力してください。`
        );
        return;
      } else {
        setDistributorNumberReasonError("");
      }
    }

    if (requestData.applyCategory === "2") {
      // 摘要
      if (requestData.applyUsageClassificationDetail.length === 0) {
        setMessageBarMessage("摘要は、必須です。");
        setMessageBarOpen(true);
        setApplyUsageClassificationDetailError("摘要は、必須です。");
        return;
      } else {
        setApplyUsageClassificationDetailError("");
      }
    }

    // 振込先口座
    if (applyCategory === "3") {
      requestData.bankAccount = "";
    }

    if (comment.length > 500) {
      setMessageBarMessage("備考欄は 500 文字以下で入力してください。");
      setMessageBarOpen(true);
      setCommentError("備考欄は 500 文字以下で入力してください。");
      return;
    } else {
      setCommentError("");
    }

    // 領収書合計金額
    if (applyCategory === "1" || applyCategory === "2") {
      if (isNaN(requestData.receiptTotalAmounts)) {
        setMessageBarMessage("領収書合計金額は数値を入力してください。");
        setMessageBarOpen(true);
        setReceiptTotalAmountsError("領収書合計金額は数値を入力してください。");
        return;
      } else {
        setReceiptTotalAmountsError("");
      }
      if (Number(requestData.receiptTotalAmounts) === 0) {
        setMessageBarMessage(
          "領収書合計金額は０より大きい値を入力してください。"
        );
        setMessageBarOpen(true);
        setReceiptTotalAmountsError(
          "領収書合計金額は０より大きい値を入力してください。"
        );
        return;
      } else {
        setReceiptTotalAmountsError("");
      }
    } else {
      requestData.receiptTotalAmounts = 0;
    }

    //　添付
    if (applyCategory === "1" || applyCategory === "2") {
      // 領収書
      if (images.length > 20) {
        setMessageBarMessage("領収書は最大２０枚まで添付できます。");
        setMessageBarOpen(true);
      }
    } else {
      requestData.images = [];
    }

    if (distributionList.length === 0) {
      setMessageBarMessage("配分者情報を入力してください。");
      setMessageBarOpen(true);
      return;
    }

    if (superiorList.length === 0) {
      setMessageBarMessage("承認者情報を入力してください。");
      setMessageBarOpen(true);
      return;
    }

    for (const data of distributionList) {
      const amounts = String(data.amounts).replaceAll(",", "");
      if (isNaN(Number(amounts))) {
        setMessageBarMessage("配分金額は数値を入力してください。");
        setMessageBarOpen(true);
        return;
      }
      if (Number(amounts) === 0) {
        setMessageBarMessage("配分金額は０より大きい値を入力してください。");
        setMessageBarOpen(true);
        return;
      }
    }

    // 合計金額チェック
    if (applyCategory !== "3") {
      if (
        Number(requestData.receiptTotalAmounts) < Number(requestData.useAmounts)
      ) {
        setMessageBarMessage(
          "金券使用額は、領収書の合計金額以下にしてください。"
        );
        setMessageBarOpen(true);
        setUseAmountsError(
          "金券使用額は、領収書の合計金額以下にしてください。"
        );
        return;
      } else {
        setUseAmountsError("");
      }
    }

    const putData = async () => {
      loaderDispatch({
        type: LoaderActionEnum.SET_LOADER_TRUE,
      });

      const body = {
        approvalStatus: approvalStatus,
        displayApplyId: displayApplyId,
        applyId: applyId,
        applyCategory: requestData.applyCategory,
        useAmounts: requestData.useAmounts,
        applyUsageClassification: requestData.applyUsageClassification,
        applyUsageClassificationDetail:
          requestData.applyUsageClassificationDetail,
        peopleNumber: requestData.peopleNumber,
        online: online,
        useDate: requestData.useDate,
        useDateReason: requestData.useDateReason,
        comment: comment,
        receiptTotalAmounts: requestData.receiptTotalAmounts,
        receiptImages: images,
        distributorNumberReason: requestData.distributorNumberReason,
        distributor: distributionList.map((d: Distribution) => {
          d.amounts = Number(String(d.amounts).replaceAll(",", ""));
          return d;
        }),
        superior: superiorList,
        bankAccount: requestData.bankAccount,
      };

      const result = await PostApi(
        `/money-ticket/apply${
          qs.selectedId ? `?reapplyoriginid=${qs.selectedId}` : ""
        }`,
        body
      );

      loaderDispatch({
        type: LoaderActionEnum.SET_LOADER_FALSE,
      });

      if (result.statusCode >= 400) {
        setMessageBarMessage(result.message);
        setMessageBarOpen(true);
      } else {
        notificationDispatch({
          type: NotificationActionEnum.SET_NOTIFICATION,
          payload: {
            message: "申請しました。",
            severity: "success",
          },
        });
        history.push("/money-ticket/apply");
      }
    };

    putData();
  };

  const handleSearchEmployee = (e: any) => {
    setSearchEmployee(e.target.value);
  };

  const handleAddDistributionList = (row: any) => {
    if (
      distributionList.filter((x: Distribution) => x.authorizer_bs === row.bs)
        .length > 0
    ) {
      return;
    }
    setDistributionList((prevState: Distribution[]) => [
      ...prevState,
      {
        authorizer_bs: row.bs,
        name: row.name,
        dept: row.dept,
        email: row.email,
        amounts: "",
        pno: applyCategory === "2" ? "90" : row.dept_code,
        pnoReadOnly: applyCategory === "2" ? true : false,
        amountsReadOnly: false,
        textType: "text",
      },
    ]);
    setModalSelectedDistributorList((prevState: any) => [...prevState, row.bs]);
  };

  const handleSuperiorListRowClick = (row: any) => {
    const superiorData = superiorList.find(
      (superior) => superior.authorizer_bs === selectedSuperiorRowOrder
    );

    if (superiorData) {
      setSuperiorList((prevState: Superior[]) =>
        prevState.map((superior) => {
          if (superior.authorizer_bs === selectedSuperiorRowOrder) {
            return {
              ...superior,
              authorizer_bs: row.bs,
              name: row.name,
              dept: row.dept,
              email: row.email,
            };
          }
          return superior;
        })
      );
    } else {
      const firstSuperiorData = superiorList.filter(
        (superior) => superior.approvalType === ApprovalTypeCode.FIRST_SUPERIOR
      );
      const newSuperiorList = [
        {
          approvalType: ApprovalTypeCode.FIRST_SUPERIOR,
          route: ApprovalTypeName.FIRST_SUPERIOR,
          authorizer_bs: row.bs,
          name: row.name,
          dept: row.dept,
          email: row.email,
        },
        ...superiorList,
      ];
      setSuperiorList(newSuperiorList);
    }
    setSuperiorModalIsOpen(false);
    setSelectedSuperiorRowOrder(null);
  };

  const onDeleteRow = (row: any) => {
    setDistributionList(
      distributionList.filter((d: any) => d.authorizer_bs !== row.authorizer_bs)
    );
    setModalSelectedDistributorList((prevState: any) =>
      prevState.filter((user: any) => user !== row.authorizer_bs)
    );
  };

  const handlerSuperiorDeleteRow = (row: any) => {
    if (approvalStatus === "7") {
      console.log("修正依頼のため承認者情報を削除できません");
      return;
    }
    if (row.approvalType === ApprovalTypeCode.SECOND_SUPERIOR) {
      setMessageBarMessage("最終承認者は削除できません");
      setMessageBarOpen(true);
      return;
    }
    setSuperiorList(
      superiorList.filter((d) => d.authorizer_bs !== row.authorizer_bs)
    );
  };

  const handlerSuperiorChangeRow = (row: Superior) => {
    if (approvalStatus === "7") {
      console.log("修正依頼のため承認者情報を変更できません");
    } else {
      setSelectedSuperiorRowOrder(row.authorizer_bs);
      setSuperiorModalIsOpen(true);
    }
  };

  const onTextChange = (e: any, row: any) => {
    const value = e.target.value.replace(/[０-９]/g, function (s: string) {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
    setDistributionList(
      distributionList.map((d: any) => {
        if (d.authorizer_bs === row.authorizer_bs) {
          const amounts = value.replaceAll(",", "");
          if (!isNaN(Number(amounts))) {
            d.amounts = amounts;
          }
        }
        return d;
      })
    );
  };

  useEffect(() => {
    if (distributionList.length === 0) return;
    let total = 0;
    distributionList.forEach((distributor) => {
      const amounts = String(distributor.amounts).replaceAll(",", "");
      if (!isNaN(Number(amounts))) {
        total += Number(amounts);
      }
    });
    setDistributorTotalAmounts(total.toLocaleString());
  }, [distributionList]);

  const onTextFocus = (e: any, row: any) => {
    setDistributionList(
      distributionList.map((d: any) => {
        if (d.authorizer_bs === row.authorizer_bs) {
          const amounts = e.target.value.replaceAll(",", "");
          if (!isNaN(Number(amounts))) {
            d.amounts = amounts;
          }
          d.textType = "number";
        }
        return d;
      })
    );
  };

  const onTextBlur = (e: any, row: any) => {
    setDistributionList(
      distributionList.map((d: any) => {
        if (d.authorizer_bs === row.authorizer_bs) {
          const amounts = e.target.value.replaceAll(",", "");
          if (!isNaN(Number(amounts))) {
            d.amounts = Number(amounts).toLocaleString();
          }
          d.textType = "text";
        }
        return d;
      })
    );
  };

  const onPnoTextChange = (e: any, row: any) => {
    setDistributionList(
      distributionList.map((d) => {
        if (d.authorizer_bs === row.authorizer_bs) {
          d.pno = e.target.value;
        }
        return d;
      })
    );
  };

  const onClickCloseDistributionModal = () => {
    setAddDistributionModalIsOpen(false);
  };

  const onClickCloseSuperiorModal = () => {
    setSuperiorModalIsOpen(false);
  };

  const onClickAddDistribution = () => {
    setAddDistributionModalIsOpen(true);
  };

  const handlerOnChangeComment = (e: any) => {
    setComment(e.target.value);
  };

  const onChangeUseAmounts = async (e: any) => {
    const halfValue = e.target.value.replace(/[０-９]/g, function (s: string) {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
    if (isNaN(Number(halfValue))) return;
    setUseAmounts(String(halfValue));
  };
  const onFocusUseAmounts = (e: any) => {
    const amounts = e.target.value.replaceAll(",", "");
    setUseAmounts(amounts);
    setUseAmountsTextType("number");
  };
  const onBlurUseAmounts = (e: any) => {
    setUseAmountsTextType("text");
    const amounts = e.target.value.replaceAll(",", "");
    if (isNaN(Number(amounts))) return;
    setUseAmounts(Number(amounts).toLocaleString());
  };

  const onChangeApplyUsageClassificationDetail = (e: any) => {
    setApplyUsageClassificationDetail(e.target.value);
  };

  const onChangeUseDateReason = (e: any) => {
    setUseDateReason(e.target.value);
  };

  const onChangeDistributorNumberReason = (e: any) => {
    setDistributorNumberReason(e.target.value);
  };

  const onChangeReceiptTotalAmounts = (e: any) => {
    const value = e.target.value.replace(/[０-９]/g, function (s: string) {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
    if (isNaN(Number(value))) return;
    setReceiptTotalAmounts(value);
  };
  const onFocusReceiptTotalAmounts = (e: any) => {
    const amounts = e.target.value.replaceAll(",", "");
    setReceiptTotalAmounts(amounts);
    setReceiptTotalAmountsTextType("number");
  };
  const onBlurReceiptTotalAmounts = (e: any) => {
    setReceiptTotalAmountsTextType("text");
    const amounts = e.target.value.replaceAll(",", "");
    if (isNaN(Number(amounts))) return;
    setReceiptTotalAmounts(Number(amounts).toLocaleString());
  };

  const onChangeUseDateChange = (date: Date) => {
    changeUseDateChange(date);
  };

  const onChangeUseDateChangeWithUsageClassification = (
    date: Date,
    responseApplyUsageClassification = ""
  ) => {
    changeUseDateChange(date, responseApplyUsageClassification);
  };

  const changeUseDateChange = (
    date: Date,
    responseApplyUsageClassification = ""
  ) => {
    const _applyUsageClassification =
      responseApplyUsageClassification || applyUsageClassification;
    console.log(_applyUsageClassification);
    const _useDate = `${date.getFullYear().toString()}-${String(
      date.getMonth() + 1
    )}-${date.getDate().toString()}`;
    const dayOfWeek = new Date(_useDate).getDay();
    const holidays = holiday_jp.between(new Date(_useDate), new Date(_useDate));
    console.log(holidays);
    if (_applyUsageClassification === "11") {
      if (dayOfWeek === 0 || dayOfWeek === 6 || holidays.length > 0) {
        setUseDateReasonVisible(true);
      } else {
        setUseDateReasonVisible(false);
      }
    } else {
      setUseDateReasonVisible(false);
    }
    setUseDate(date);
  };

  //申請種別セレクトボックス変更時処理
  const onApplyCategorySelectChange = (e: any) => {
    setApplyCategory(e.target.value);
    setApplyUsageClassification(`${e.target.value}1`);
    setApplyUsageClassificationDetailVisible(true);
    if (e.target.value === "1") {
      setPeopleNumberVisible(true);
      setOnlineVisible(true);
      setUseDateVisible(true);
      setUseDateReasonVisible(true);
      if (distributorNumber === 1) {
        setDistributorNumberReasonVisible(true);
      } else {
        setDistributorNumberReasonVisible(false);
      }
      setBalance(
        (
          Number(initBalance) - Number(useAmounts.replace(",", ""))
        ).toLocaleString()
      );
      setReceiptTotalAmountsVisible(true);
      setBankAccountVisible(true);
      setDistributionList(
        distributionList.map((d) => {
          const user = usersState.users.find(
            (item: any) => item.bs === d.authorizer_bs
          );
          d.pno = user.dept_code;
          d.pnoReadOnly = false;
          return d;
        })
      );
      setDistributorInfoTitle("被配分者");
    } else if (e.target.value === "2") {
      setPeopleNumberVisible(false);
      setPeopleNumber("1");
      setOnlineVisible(true);
      setUseDateVisible(true);
      setUseDateReasonVisible(false);
      setDistributorNumberReasonVisible(false);
      setBalance(
        (
          Number(initBalance) - Number(useAmounts.replace(",", ""))
        ).toLocaleString()
      );
      setReceiptTotalAmountsVisible(true);
      setBankAccountVisible(true);
      setDistributionList(
        distributionList.map((d) => {
          d.pno = "90";
          d.pnoReadOnly = true;
          return d;
        })
      );
      setDistributorInfoTitle("立替費用清算者");
    } else if (e.target.value === "3") {
      setPeopleNumberVisible(false);
      setPeopleNumber("1");
      setOnlineVisible(false);
      setOnline("1");
      setUseDateVisible(false);
      setUseDateReasonVisible(false);
      setDistributorNumberReasonVisible(false);
      setBalance("承認者の金券を使用");
      setReceiptTotalAmountsVisible(false);
      setBankAccountVisible(false);
      setDistributionList(
        distributionList.map((d) => {
          const user = usersState.users.find(
            (item: any) => item.bs === d.authorizer_bs
          );
          d.pno = user.dept_code;
          d.pnoReadOnly = false;
          return d;
        })
      );
      setDistributorInfoTitle("被配分者");
    }
  };

  // 利用区分セレクトボックス値変更時処理
  const onApplyUsageClassificationSelectChange = (e: any) => {
    const changedValue = e.target.value;
    setApplyUsageClassification(changedValue);
    if (applyCategory === "1") {
      if (changedValue === "12" || changedValue === "13") {
        setOnlineVisible(false);
      } else {
        setOnlineVisible(true);
      }
      if (changedValue === "12") {
        setPeopleNumberVisible(false);
        setPeopleNumber("1");
      } else {
        setPeopleNumberVisible(true);
      }
      if (changedValue === "12" || changedValue === "13") {
        setDistributorNumberReasonVisible(false);
      } else {
        if (distributorNumber === 1) {
          setDistributorNumberReasonVisible(true);
        } else {
          setDistributorNumberReasonVisible(false);
        }
      }
      if (changedValue === "11") {
        const _useDate = `${useDate.getFullYear().toString()}-${(
          "0" + String(useDate.getMonth() + 1)
        ).slice(-2)}-${("0" + useDate.getDate().toString()).slice(-2)}`;
        const dayOfWeek = new Date(_useDate).getDay();
        const holidays = holiday_jp.between(
          new Date(_useDate),
          new Date(_useDate)
        );
        if (dayOfWeek === 0 || dayOfWeek === 6 || holidays.length > 0) {
          setUseDateReasonVisible(true);
        } else {
          setUseDateReasonVisible(false);
        }
      } else {
        setUseDateReasonVisible(false);
      }
    } else if (applyCategory === "2") {
      setApplyUsageClassificationDetailVisible(true);
    } else if (applyCategory === "3") {
      // changedValue === "1"
      //   ? setApplyUsageClassificationDetailVisible(false)
      //   : setApplyUsageClassificationDetailVisible(true);
    }
  };

  const onPeopleNumberSelectChange = (e: any) => {
    setPeopleNumber(e.target.value);
  };

  const onOnlineSelectChange = (e: any) => {
    setOnline(e.target.value);
  };

  const onBankAccountSelectChange = (e: any) => {
    setBankAccount(e.target.value);
  };

  const onClickAddFirstSuperior = () => {
    if (
      superiorList.filter(
        (superior) => superior.approvalType === ApprovalTypeCode.FIRST_SUPERIOR
      ).length >= 3
    ) {
      return;
    }
    setSuperiorModalIsOpen(true);
  };

  useEffect(() => {
    const _useAmounts = useAmounts.replace(",", "");
    const _distributorTotalAmounts = distributorTotalAmounts.replace(",", "");
    setRemainingBalance(
      (Number(_useAmounts) - Number(_distributorTotalAmounts)).toLocaleString()
    );
    if (applyCategory !== "3") {
      setBalance((Number(initBalance) - Number(_useAmounts)).toLocaleString());
    }
  }, [initBalance, applyCategory, distributorTotalAmounts, useAmounts]);

  useEffect(() => {
    setDistributorNumber(distributionList.length);
    if (
      applyCategory === "1" &&
      (applyUsageClassification === "11" || applyUsageClassification === "14")
    ) {
      if (distributionList.length > 1) {
        setDistributorNumberReasonVisible(false);
      } else if (distributionList.length <= 1) {
        setDistributorNumberReasonVisible(true);
      }
    } else {
      setDistributorNumberReasonVisible(false);
    }
  }, [applyCategory, distributionList]);

  useEffect(() => {
    console.log(superiorList);
  }, [superiorList]);
  useEffect(() => {
    // 承認ルートの取得
    const fetchAuthorizerRouteData = async () => {
      const result = await GetApi("/money-ticket/authorizer-route");

      if (result.statusCode >= 400) {
        setMessageBarMessage(result.message);
        setMessageBarOpen(true);
        return;
      }

      const superiorList: Superior[] = result.data.map((it: any) => ({
        approvalType:
          it.rowNo === 1
            ? ApprovalTypeCode.FIRST_SUPERIOR
            : ApprovalTypeCode.SECOND_SUPERIOR,
        authorizer_bs: it.authorizer_bs,
        dept: it.dept,
        email: it.email,
        name: it.name,
        route: it.route,
        // order: it.rowNo === 1 ? 1 : 4,
      }));
      setSuperiorList(superiorList);
    };

    const fetchMoneyTicketData = async () => {
      const result = await GetApi("/money-ticket/balance");

      if (result.statusCode >= 400) {
        setMessageBarMessage(result.message);
        setMessageBarOpen(true);
        return;
      }

      const balance1 = result.data.length > 0 ? result.data[0].amounts : 0;
      const balance2 = result.data.length > 1 ? result.data[1].amounts : 0;
      setBalance((balance1 + balance2).toLocaleString());
      setInitBalance(balance1 + balance2);
    };

    // 承認ルートの取得
    if (!props.location.search) {
      fetchAuthorizerRouteData();
    }
    // 金券所有額の取得
    fetchMoneyTicketData();
  }, []);

  return (
    <ViewWrapper>
      <MessageBar
        open={messageBarOpen}
        setOpen={setMessageBarOpen}
        severity={"error"}
        message={messageBarMessage}
      />
      <Card title={"新規金券精算申請作成"} titleLayout={true}>
        <Card>
          <h2>申請種別</h2>
          <Divider />
          <SelectBox
            title={"申請種別"}
            value={applyCategory}
            required={applyCategoryVisible}
            disabled={!applyCategoryVisible}
            maxWidth={"200px"}
            options={
              !loginState.loginUserInfo?.position ||
              loginState.loginUserInfo?.position < "1010"
                ? applyCategoryForMemberOptions
                : applyCategoryOptions
            }
            onChange={onApplyCategorySelectChange}
          />
        </Card>
        <Card>
          <h2>基本情報</h2>
          <Divider />
          <Grid container>
            <Grid item sm={12} md={4}>
              <TextBox
                id={"useAmounts"}
                title={"金券使用額"}
                required={amountOfMoneyVisible}
                readOnly={!amountOfMoneyVisible}
                labelWidth={"150px"}
                maxWidth={"150px"}
                textAlign={"right"}
                value={String(useAmounts)}
                error={useAmountsError}
                onChange={onChangeUseAmounts}
                onFocus={onFocusUseAmounts}
                onBlur={onBlurUseAmounts}
                type={useAmountsTextType}
              />
            </Grid>
            <Grid item sm={12} md={4}>
              <TextBox
                id={"balance"}
                title={"所有金券"}
                readOnly={true}
                labelWidth={"150px"}
                maxWidth={"180px"}
                value={approvalStatus !== "7" ? balance : "-"}
              />
            </Grid>
            <Grid item sm={12} md={4}>
              <SelectBox
                title={"振込先口座"}
                required={bankAccountVisible}
                disabled={!bankAccountVisible}
                labelWidth={"150px"}
                maxWidth={"150px"}
                value={bankAccount}
                options={
                  availableBankAccount === "0"
                    ? [
                        {
                          code: "0",
                          name: "給与口座",
                        },
                      ]
                    : availableBankAccount === "1"
                    ? [
                        {
                          code: "0",
                          name: "給与口座",
                        },
                        {
                          code: "1",
                          name: "その他口座",
                        },
                      ]
                    : []
                }
                onChange={onBankAccountSelectChange}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item sm={12} md={4}>
              <SelectBox
                title={"利用区分"}
                required={true}
                labelWidth={"150px"}
                maxWidth={"200px"}
                value={applyUsageClassification}
                options={applyUsageClassificationOptions[applyCategory]}
                onChange={onApplyUsageClassificationSelectChange}
              />
            </Grid>
            <Grid item sm={12} md={8}>
              <TextBox
                id={"applyUsageClassificationDetail"}
                title={"摘要"}
                required={applyUsageClassificationDetailVisible}
                readOnly={!applyUsageClassificationDetailVisible}
                labelWidth={"150px"}
                maxWidth={"400px"}
                placeholder={"店舗名称 / 催事名称 / 購入物品名称等入力"}
                value={applyUsageClassificationDetail}
                error={applyUsageClassificationDetailError}
                onChange={onChangeApplyUsageClassificationDetail}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item sm={12} md={4}>
              <SelectBox
                title={"人数"}
                required={peopleNumberVisible}
                disabled={!peopleNumberVisible}
                labelWidth={"150px"}
                value={peopleNumber}
                options={peopleNumberOptions}
                onChange={onPeopleNumberSelectChange}
              />
            </Grid>
            <Grid item sm={12} md={4}>
              <SelectBox
                title={"リアル/オンライン"}
                required={onlineVisible}
                disabled={!onlineVisible}
                labelWidth={"150px"}
                value={online}
                options={onlineOptions}
                onChange={onOnlineSelectChange}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item sm={12} md={4}>
              <DatePicker
                title={"使用日"}
                required={useDateVisible}
                disabled={!useDateVisible}
                value={useDate}
                labelWidth={"150px"}
                // maxWidth={"150px"}
                onChange={onChangeUseDateChange}
              />
            </Grid>
            <Grid item sm={12} md={8}>
              <TextBox
                id={"useDateReason"}
                title={"理由(使用日)"}
                labelWidth={"150px"}
                required={useDateReasonVisible}
                readOnly={!useDateReasonVisible}
                placeholder={"土日祝日利用の場合は理由を明記してください"}
                value={useDateReason}
                error={useDateReasonError}
                onChange={onChangeUseDateReason}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item sm={12} md={4}>
              <TextBox
                id={"distributorNumber"}
                title={`${distributorInfoTitle}数`}
                readOnly={true}
                labelWidth={"150px"}
                maxWidth={"150px"}
                value={String(distributorNumber)}
              />
            </Grid>
            <Grid item sm={12} md={8}>
              <TextBox
                id={"distributorNumberReason"}
                title={`理由(${distributorInfoTitle}数)`}
                required={distributorNumberReasonVisible}
                readOnly={!distributorNumberReasonVisible}
                labelWidth={"150px"}
                placeholder={"１人精算の場合は理由を明記してください"}
                value={distributorNumberReason}
                error={distributorNumberReasonError}
                onChange={onChangeDistributorNumberReason}
              />
            </Grid>
          </Grid>
          <TextArea
            title={"備考欄"}
            labelWidth={"150px"}
            maxWidth={"800px"}
            value={comment}
            placeholder={
              "承認者へ伝えたいことがあれば入力してください。土日祝日の領収書を含む場合は理由を明記してください。"
            }
            onChange={handlerOnChangeComment}
            error={commentError}
          />
        </Card>
        <Card>
          <h2>領収書</h2>
          <Divider />
          <TextBox
            id={"receiptTotalAmounts"}
            title={"合計金額"}
            required={receiptTotalAmountsVisible}
            readOnly={!receiptTotalAmountsVisible}
            labelWidth={"150px"}
            maxWidth={"150px"}
            textAlign={"right"}
            value={receiptTotalAmounts}
            error={receiptTotalAmountsError}
            onChange={onChangeReceiptTotalAmounts}
            onFocus={onFocusReceiptTotalAmounts}
            onBlur={onBlurReceiptTotalAmounts}
            type={receiptTotalAmountsTextType}
          />
          <ImageArea images={images} setImages={setImages} />
        </Card>
        <Card>
          <h2>{distributorInfoTitle}情報</h2>
          <Divider />
          <Button
            title={`${distributorInfoTitle}の追加`}
            disabled={!addDistributorButtonEnable}
            onClick={onClickAddDistribution}
          />
          <Grid container>
            <Grid item sm={12} md={4}>
              <TextBox
                id={"distributorTotalAmounts"}
                title={"配分合計額"}
                readOnly={true}
                labelWidth={"150px"}
                maxWidth={"150px"}
                textAlign={"right"}
                value={String(distributorTotalAmounts)}
              />
            </Grid>
            <Grid item sm={12} md={4}>
              <TextBox
                id={"remainingBalance"}
                title={"申請金額 - 配分合計額"}
                readOnly={true}
                labelWidth={"150px"}
                maxWidth={"150px"}
                textAlign={"right"}
                value={String(remainingBalance)}
              />
            </Grid>
          </Grid>

          <Table
            headers={[
              {
                name: "user_data",
                disp: "名前",
                type: "custom",
                component: (
                  rowIndex: any,
                  index: any,
                  header: any,
                  rowData: any
                ) => {
                  return (
                    <td
                      key={index}
                      id={String(header.name) + "_" + String(rowIndex)}
                    >
                      <p
                        style={{
                          color: "gray",
                          margin: "2px",
                          fontSize: "6px",
                        }}
                      >
                        {rowData["authorizer_bs"]}
                      </p>
                      <p style={{ margin: "2px" }}>{rowData["name"]}</p>
                      <p
                        style={{
                          color: "gray",
                          margin: "2px",
                          fontSize: "6px",
                        }}
                      >
                        {rowData["dept"]}
                      </p>
                    </td>
                  );
                },
              },
              {
                name: "pno",
                disp: "配分先所属",
                type: "textBox",
                readOnly: "pnoReadOnly",
                onTextChange: onPnoTextChange,
              },
              {
                name: "amounts",
                disp: "金額",
                type: "textBox",
                textAlign: "right",
                readOnly: "amountsReadOnly",
                onTextChange: onTextChange,
                onTextFocus: onTextFocus,
                onTextBlur: onTextBlur,
              },
              {
                name: "delete",
                disp: "削除",
                type: "delete",
              },
            ]}
            data={distributionList}
            onDeleteRow={onDeleteRow}
          />
        </Card>
        <Card>
          <h2>承認者情報</h2>
          <Divider />
          <Button
            title="第１承認者の追加"
            variant="outlined"
            disabled={!addFirstSuperiorButtonEnable}
            onClick={onClickAddFirstSuperior}
          />
          <Table
            headers={[
              {
                name: "route",
                disp: "",
                type: "text",
              },
              {
                name: "user_data",
                disp: "名前",
                type: "custom",
                component: (
                  rowIndex: any,
                  index: any,
                  header: any,
                  rowData: any
                ) => {
                  return (
                    <td
                      key={index}
                      id={String(header.name) + "_" + String(rowIndex)}
                    >
                      <p
                        style={{
                          color: "gray",
                          margin: "2px",
                          fontSize: "6px",
                        }}
                      >
                        {rowData["authorizer_bs"]}
                      </p>
                      <p style={{ margin: "2px" }}>{rowData["name"]}</p>
                      <p
                        style={{
                          color: "gray",
                          margin: "2px",
                          fontSize: "6px",
                        }}
                      >
                        {rowData["dept"]}
                      </p>
                    </td>
                  );
                },
              },
              {
                name: "button",
                disp: "",
                type: "button",
                title: "変更",
                onClick: handlerSuperiorChangeRow,
              },
              {
                name: "delete",
                disp: "",
                type: "delete",
              },
            ]}
            data={superiorList}
            onDeleteRow={handlerSuperiorDeleteRow}
          />
        </Card>
      </Card>

      <div className={classes.buttonGroup}>
        <Button
          id="applyButton"
          title={applyButtonName}
          variant="contained"
          onClick={regist}
        />
        <Button
          id="cancelButton"
          title="キャンセル"
          variant="outlined"
          onClick={() => history.push("/money-ticket/apply")}
        />
      </div>
      <ModalUserList
        value={searchEmployee}
        isOpen={addDistributionModalIsOpen}
        selectedUsers={modalSelectedDistributorList}
        onChange={handleSearchEmployee}
        onRowDoubleClick={handleAddDistributionList}
        onClickClose={onClickCloseDistributionModal}
      />
      <ModalUserList
        type={"orMorePosition"}
        value={searchEmployee}
        isOpen={superiorModalIsOpen}
        // selectedUsers={modalSelectedSuperiorList}
        selectedUsers={superiorList.map((superior) => superior.authorizer_bs)}
        onChange={handleSearchEmployee}
        onRowDoubleClick={handleSuperiorListRowClick}
        onClickClose={onClickCloseSuperiorModal}
      />
    </ViewWrapper>
  );
};
