import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import Typography from "@material-ui/core/Typography";
import currencySymbol from "../../assets/images/currencySymbol.png";

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    boxShadow: "0 2px 2px 0 rgba(0, 0, 0, .3)",
    // borderRadius: "5px",
    border: "1px solid " + theme.palette.lpSecond.main,
    background: theme.palette.lpWhite.main,
    borderLeft: "solid 15px " + theme.palette.lpSecond.main,
  },
  cardRed: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    boxShadow: "0 2px 2px 0 rgba(0, 0, 0, .3)",
    border: `1px solid #C1335F`,
    background: theme.palette.lpWhite.main,
    borderLeft: `solid 15px #C1335F`,
    cursor: "default",
  },
  title: {
    fontSize: 12,
  },
  moneyGroup: {
    display: "flex",
  },
  money: {
    padding: "0 20px 0 20px",
    fontSize: "40px",
    color: theme.palette.grey[900],
  },
}));

type CardProps = {
  width?: string;
  maxWidth?: string;
  loading?: boolean;
  color?: string;
  expire?: string;
  amounts: string;
};

export const MoneyTicket: React.FC<CardProps> = ({
  width = "",
  maxWidth = "",
  loading = false,
  color = "default",
  expire = "",
  amounts = "0",
}: CardProps) => {
  const classes = useStyles();

  return (
    <Fragment>
      <div
        className={color === "red" ? classes.cardRed : classes.card}
        style={{ width: width, maxWidth: maxWidth }}
      >
        {loading ? (
          <Skeleton variant="rect" height={100} />
        ) : (
          <Fragment>
            <Typography
              className={classes.title}
              component="p"
              color="textSecondary"
              gutterBottom
            >
              {expire}
            </Typography>
            <div className={classes.moneyGroup}>
              <div className={classes.money}>
                <img
                  src={currencySymbol}
                  alt=""
                  width={"35px"}
                  style={{
                    position: "relative",
                    top: "2px",
                    right: "5px",
                  }}
                />
                {amounts}
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};
