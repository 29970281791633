import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  ViewWrapper,
  Button,
  Table,
  Block,
  MessageBar,
  MoneyTicket,
  ImageModal,
  ReactModal,
  ImagePreview,
  TextBox,
  Card,
} from "../../components";
import { useHistory } from "react-router-dom";
import { GetApi, PutApi } from "../../utils";
import Typography from "@material-ui/core/Typography";
import {
  ActionEnum as LoaderActionEnum,
  LoaderContext,
} from "../../stores/LoaderStore";
const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  tableTitleGroup: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: theme.spacing(1),
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
  },
  moneyGroup: {
    display: "flex",
  },
  money: {
    padding: "0 20px 0 20px",
    fontSize: "50px",
    color: "#A3A0F5",
  },
  title: {
    color: theme.palette.grey[400],
  },
  div: {
    margin: theme.spacing(1),
  },
  aaa: {
    "--width": "90%",
  },
}));

type Distribution = {
  bs: string;
  name: string;
  dept: string;
  email: string;
  amounts: string | number;
};

type Approval = {
  category: string;
  authorizer_bs: string;
  name: string;
  dept: string;
  email: string;
  amounts: string;
};

export const GeneralAffairsDetail = (props: any) => {
  // クエリパラメータの取得

  //console.log(props);
  //const qs = queryString.parse(props.location.search)
  // パスパラメータの取得
  //const { id } = props.match.params
  const { id, setId, data, setData } = props;

  // 定数定義
  const classes = useStyles();
  const history = useHistory();
  const { dispatch: loaderDispatch } = useContext(LoaderContext);
  const [loading, setLoading] = useState(true);
  const [comment, setComment] = useState("");
  const [commentError, setCommentError] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [totalamounts, setTotalamounts] = useState(0);
  const [applyId, setApplyId] = useState("");
  const [applyCategory, setApplyCategory] = useState("");
  const [usageClassification, setUsageClassification] = useState("");
  const [usageClassificationDetail, setUsageClassificationDetail] =
    useState("");
  const [peopleNumber, setPeopleNumber] = useState("");
  const [online, setOnline] = useState("");
  const [commentRemarks, setRemarks] = useState("");
  const [useDate, setUseDate] = useState("");
  const [applyUser, setApplyUser] = useState("");
  const [distributionList, setDistributionList] = useState<Distribution[]>([]);
  const [approvalList, setApprovalList] = useState<Approval[]>([]);
  const [messageBarMessage, setMessageBarMessage] = useState("");
  const [messageBarOpen, setMessageBarOpen] = useState(false);
  const [judgmentButton, setJudgmentButton] = useState(false);
  const [images, setImages] = useState([]);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [amountCorrectionModalOpen, setAmountCorrectionModalOpen] =
    useState(false);
  const [imageId, setImageId] = useState("");
  const [useDateReason, setUseDateReason] = useState("");
  const [distributorNumberReason, setDistributorNumberReason] = useState("");
  const [receiptTotalAmounts, setReceiptTotalAmounts] = useState("");

  const handlerApproveJudgment = (judgment: string) => {
    const update = async () => {
      if (comment.length > 200) {
        setMessageBarMessage("判定コメントは 200 文字以下で入力してください。");
        setMessageBarOpen(true);
        setCommentError("判定コメントは 200 文字以下で入力してください。");
        return;
      } else if(judgment !== "APPROVAL" && comment.length <= 0){
        setMessageBarMessage("判定コメントを入力してください。");
        setMessageBarOpen(true);
        setCommentError("判定コメントを入力してください");
        return;
      }else{
        setCommentError("");
      }
      loaderDispatch({
        type: LoaderActionEnum.SET_LOADER_TRUE,
      });
      const body = {
        judgment: judgment,
        comment: comment,
      };
      const result = await PutApi(
        "/money-ticket/general-affairs/" + id + "/judgment",
        body
      );

      loaderDispatch({
        type: LoaderActionEnum.SET_LOADER_FALSE,
      });

      // 処理成功
      if (result.statusCode >= 400) {
        setMessageBarMessage(result.message);
        setMessageBarOpen(true);
      } else {
        let nextRow = 0;
        const filteredData = data.filter((d: any, index: number) => {
          if (d.apply_id === id) {
            nextRow = index;
          }
          return d.apply_id !== id;
        });
        if (filteredData.length === 0) {
          setId("");
        } else if (filteredData.length <= nextRow) {
          setId(filteredData[nextRow - 1].apply_id);
        } else {
          setId(filteredData[nextRow].apply_id);
        }
        setComment("");
        setData(filteredData);
      }
    };
    update();
  };

  const fetchData = async () => {
    setLoading(true);
    if (!id) {
      return;
    }
    const result = await GetApi("/money-ticket/general-affairs/" + id);
    if (result.statusCode >= 400) {
      setMessageBarMessage(result.message);
      setMessageBarOpen(true);
      return;
    }
    if (result.data) {
      setTotalamounts(result.data?.amounts);
      setCreatedAt(result.data?.created_at);
      setApplyUser(result.data?.applicant_name);
      setApplyId(result.data?.display_apply_id);
      setApplyCategory(result.data?.apply_category);
      setUsageClassification(result.data?.apply_usage_classification);
      setUsageClassificationDetail(
        result.data?.apply_usage_classification_detail || "-"
      );
      setPeopleNumber(result.data?.people_number || "-");
      setOnline(result.data?.online || "-");
      setUseDate(result.data?.use_date || "-");
      setImages(result.data?.receipt_images.split(","));
      setRemarks(result.data?.comment || "-")
      setApprovalList(result.data?.authorizer);
      setDistributorNumberReason(result.data?.distributor_number_reason || "");
      setUseDateReason(result.data?.use_date_reason || "");
      setReceiptTotalAmounts(result.data?.receipt_total_amounts);
      setDistributionList(
        result.data?.authorizer
          .filter((d: any) => d.approval_type === "配分者")
          .map((d: any) => {
            return Object.assign({}, d);
          })
      );
      console.log(result.data?.receipt_images.split(","));

      if (result.data?.apply_status === "総務承認待ち") {
        setJudgmentButton(true);
      } else {
        setJudgmentButton(false);
      }
    } else {
      history.push("/notfound");
    }
    setLoading(false);
  };

  const onClickClose = () => {
    setImageModalOpen(false);
  };

  const onClickAmountCorrectionSave = () => {
    const update = async () => {
      loaderDispatch({
        type: LoaderActionEnum.SET_LOADER_TRUE,
      });
      const body = {
        distributor: distributionList.map((d: Distribution) => {
          d.amounts = Number(d.amounts);
          return d;
        }),
      };

      const result = await PutApi(
        `/money-ticket/general-affairs/${id}/amount-correction`,
        body
      );
      loaderDispatch({
        type: LoaderActionEnum.SET_LOADER_FALSE,
      });
      if (result.statusCode >= 400) {
        setMessageBarMessage(result.message);
        setMessageBarOpen(true);
        return;
      }

      setApprovalList(
        approvalList.map((user) => {
          const filter = result.data.distributor.filter(
            (distributorUser: any) =>
              distributorUser.authorizer_bs === user.authorizer_bs
          );
          if (filter.length > 0) {
            user.amounts = filter[0].amounts;
          }
          return user;
        })
      );
    };
    update();
  };

  const onTextChange = (e: any, row: any) => {
    setDistributionList(
      distributionList.map((d: any) => {
        if (row.authorizer_bs === d.authorizer_bs) {
          d.amounts = e.target.value;
        }
        return d;
      })
    );
  };

  const handleChangeComment = (e: any) => {
    setComment(e.target.value);
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <ViewWrapper>
      <MessageBar
        open={messageBarOpen}
        setOpen={setMessageBarOpen}
        severity={"error"}
        message={messageBarMessage}
      />
      <ImageModal
        isOpen={imageModalOpen}
        onClickClose={onClickClose}
        imageId={imageId}
      />
      <ReactModal
        width={"500px"}
        isOpen={amountCorrectionModalOpen}
        onClickClose={() => setAmountCorrectionModalOpen(false)}
      >
        <h3>配分金額修正</h3>
        <Table
          loading={loading}
          headers={[
            {
              name: "name",
              disp: "名前",
              type: "text",
            },
            {
              name: "dept",
              disp: "部門",
              type: "text",
            },
            {
              name: "amounts",
              disp: "金額",
              type: "textBox",
              onTextChange: onTextChange,
            },
          ]}
          data={distributionList}
        />
        <div className={classes.buttonGroup}>
          <Button title="保存" onClick={onClickAmountCorrectionSave} />
          <Button
            title="閉じる"
            variant="outlined"
            onClick={() => setAmountCorrectionModalOpen(false)}
          />
        </div>
      </ReactModal>

      <Card title={"金券精算申請　明細"} titleLayout={true}>
        <MoneyTicket
          loading={loading}
          maxWidth="300px"
          expire={"金券使用額"}
          amounts={Number(totalamounts).toLocaleString()}
        >
          {/* 金券使用額
        <div className={classes.moneyGroup}>
          <div className={classes.money}>
            ¥{Number(totalamounts).toLocaleString()}
          </div>
        </div> */}
        </MoneyTicket>
        <Block loading={loading}>
          <div className={classes.title}>申請情報</div>
          <Typography variant="body2" gutterBottom>
            {applyId}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {applyUser}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {moment(createdAt).format("YYYY年MM月DD日 HH時mm分")}
          </Typography>

          <div className={classes.title}>申請種別</div>
          <Typography variant="body2" gutterBottom>
            {applyCategory}
          </Typography>

          <div className={classes.title}>利用区分</div>
          <Typography variant="body2" gutterBottom>
            {usageClassification}
          </Typography>

          <div className={classes.title}>摘要</div>
          <Typography variant="body2" gutterBottom>
            {usageClassificationDetail}
          </Typography>

          <div className={classes.title}>使用日</div>
          <Typography variant="body2" gutterBottom>
            {useDate === "-" ? "-" : moment(useDate).format("YYYY年MM月DD日")}
          </Typography>
          {useDateReason && (
            <Typography variant="body2" gutterBottom style={{ color: "blue" }}>
              土日祝申請理由：{useDateReason}
            </Typography>
          )}

          <div className={classes.title}>人数</div>
          <Typography variant="body2" gutterBottom>
            {peopleNumber}
          </Typography>
          {distributorNumberReason && (
            <Typography variant="body2" gutterBottom style={{ color: "blue" }}>
              １人申請理由：{distributorNumberReason}
            </Typography>
          )}

          <div className={classes.title}>リアル/オンライン</div>
          <Typography variant="body2" gutterBottom>
            {online}
          </Typography>

          {/* <div className={classes.title}>申請者</div>
        <Typography variant="body2" gutterBottom>
          {applyUser}
        </Typography>

        <div className={classes.title}>申請日</div>
        <Typography variant="body2" gutterBottom>
          {moment(createdAt).format("YYYY年MM月DD日 HH時mm分")}
        </Typography> */}

          <div className={classes.title}>備考欄</div>
          <Typography variant="body2" gutterBottom>
            {commentRemarks}
          </Typography>

          {images.length > 0 && images[0] !== "" && (
            <Fragment>
              <div className={classes.title}>領収書合計金額</div>
              <p style={{ color: "red" }}>
                CHECK!　添付されている領収書の合計金額が入力されているか確認
              </p>
              <MoneyTicket
                loading={loading}
                maxWidth={"300px"}
                expire={""}
                amounts={Number(receiptTotalAmounts).toLocaleString()}
              />
            </Fragment>
          )}

          {images.length > 0 && images[0] !== "" && (
            <Fragment>
              <div className={classes.title}>領収書</div>
              <p style={{ color: "red" }}>
                CHECK!　領収書の日付が申請日の３ヶ月以内かを確認
              </p>
            </Fragment>
          )}
          {images.length > 0 &&
            images.map((image: any) => (
              <ImagePreview
                onClick={() => {
                  setImageId(image);
                  setImageModalOpen(true);
                }}
                id={image}
                key={image}
              />
            ))}
        </Block>

        <div className={classes.tableTitleGroup}>
          <h3>判定情報</h3>
          <Button
            title="配分金額修正"
            variant="outlined"
            onClick={() => setAmountCorrectionModalOpen(true)}
          />
        </div>
        <Table
          loading={loading}
          headers={[
            {
              name: "approval_type",
              disp: "種別",
              type: "text",
            },
            {
              name: "name",
              disp: "名前",
              type: "text",
            },
            {
              name: "dept",
              disp: "部門",
              type: "text",
            },
            {
              name: "amounts",
              disp: "金額",
              type: "moneyText",
            },
            {
              name: "judgment",
              disp: "判定",
              type: "label",
              maxWidth: "70px",
              background: {
                スキップ: "#6F94E7",
                未判定: "#9A919C",
                承認: "#3962F7",
                差し戻し: "#C1335F",
              },
            },
            {
              name: "judgmented_at",
              disp: "判定日時",
              type: "datetime",
            },
            {
              name: "comment",
              disp: "判定コメント",
              type: "text",
            },
          ]}
          data={approvalList}
        />

        {!loading && (
          <Fragment>
            {judgmentButton && (
              <TextBox
                title={"判定コメント"}
                value={comment}
                error={commentError}
                onChange={handleChangeComment}
              />
            )}
            <div className={classes.buttonGroup}>
              {judgmentButton && (
                <Fragment>
                  <Button
                    title="承認"
                    variant="contained"
                    onClick={() => handlerApproveJudgment("APPROVAL")}
                  />
                  <Button
                    title="差し戻し"
                    variant="contained"
                    onClick={() => handlerApproveJudgment("REJECTION")}
                  />
                  <Button
                    title="修正依頼"
                    variant="contained"
                    onClick={() => handlerApproveJudgment("FIX")}
                  />
                </Fragment>
              )}
              <Button
                title="閉じる"
                variant="outlined"
                onClick={() => setId("")}
              />
            </div>
          </Fragment>
        )}
      </Card>
    </ViewWrapper>
  );
};
