import React, { ReactNode, useReducer } from "react";

type StateType = {
  users?: any;
};

export enum ActionEnum {
  SET_USERS = "SET_USERS",
}

type ActionType = {
  type: ActionEnum;
  payload?: any;
};
type ContextType = {
  state: StateType;
  dispatch: React.Dispatch<ActionType>;
};
const initialState = { users: [] };

export const reducer = (state: StateType, action: ActionType) => {
  switch (action.type) {
    case ActionEnum.SET_USERS:
      return {
        ...state,
        users: action.payload?.users,
      };
    default:
      return { ...state };
  }
};
interface IProps {
  children: ReactNode;
}
export const UsersContext = React.createContext({} as ContextType);
export const UsersProvider = ({ children }: IProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <UsersContext.Provider value={{ state, dispatch }}>
      {children}
    </UsersContext.Provider>
  );
};
