import React, { ReactNode, useReducer } from "react";

type StateType = {
  themeColor?: string;
};

export enum ActionEnum {
  CHANGE_THEME_COLOR = "CHANGE_THEME_COLOR",
}

type ActionType = {
  type: ActionEnum;
  payload?: any;
};
type ContextType = {
  state: StateType;
  dispatch: React.Dispatch<ActionType>;
};
const initialState = {
  themeColor: "lincrea",
};

export const reducer = (state: StateType, action: ActionType) => {
  switch (action.type) {
    case ActionEnum.CHANGE_THEME_COLOR:
      return {
        ...state,
        themeColor: action.payload?.themeColor,
      };
    default:
      return { ...state };
  }
};
interface IProps {
  children: ReactNode;
}
export const ThemeContext = React.createContext({} as ContextType);
export const ThemeProvider = ({ children }: IProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <ThemeContext.Provider value={{ state, dispatch }}>
      {children}
    </ThemeContext.Provider>
  );
};
