import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextBox, SelectBox, TextArea, Button } from "..";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { Skeleton } from "@material-ui/lab";
import { Checkbox, Tooltip } from "@material-ui/core";
import DetailsIcon from "@material-ui/icons/Details";
import { useTheme } from "@material-ui/core/styles";
import currencySymbol from "../../assets/images/currencySymbol.png";
const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: "collapse",
    borderSpacing: "0",
    width: "100%",
    maxHeight: "500px",
    overflow: "hidden",
    "& tr, & td": {
      textAlign: "center",
      padding: "8px 4px",
    },
    "& td.icon": {
      backgroundSize: "35px",
      backgroundPosition: "left 5px center",
      backgroundRepeat: "no-repeat",
      paddingLeft: "30px",
    },
  },
  th: {
    background: theme.palette.lpGray.light,
    padding: "12px 8px",
    borderBottom: "solid 20px #eee",
    position: "sticky",
    top: "20px",
    zIndex: 1,
  },
  tr: {
    background: theme.palette.lpWhite.main,
    borderBottom: "solid 1px #eee",
    "&:hover": {
      backgroundColor: theme.palette.lpWhite.dark,
    },
  },
  trCursor: {
    cursor: "pointer",
  },
  trSelectedRow: {
    backgroundColor: theme.palette.lpSecond.light,
    "&:hover": {
      backgroundColor: theme.palette.lpSecond.light,
    },
  },
  iconItem: {
    display: "inline-flex",
    verticalAlign: "middle",
  },
  iconGroup: {
    display: "inline-flex",
    width: "100%",
    padding: theme.spacing(1),
    alignItems: "center",
  },
  iconCircle: {
    width: "30px",
    height: "30px",
    borderRadius: "15px",
    alignItems: "center",
    textAlign: "center",
  },
  icon: {
    marginTop: "6px",
    fontSize: "1.8em",
    color: theme.palette.lpSecond.light,
  },
  iconText: {
    alignSelf: "center",
    padding: "0 " + theme.spacing(1) + "px",
  },
  textBox: {
    padding: "0",
  },
  skeleton: {
    margin: "3px 0",
  },
  loader: {
    color: "#ffffff",
    fontSize: "10px",
    // margin: "calc(100vh / 2 - 50px) auto auto auto",
    position: "relative",
    textIndent: "-9999em",
    transform: "translateZ(0)",
    animationDelay: "-0.16s",
    borderRadius: "50%",
    width: "2em",
    height: "2em",
    animationFillMode: "both",
    animation: "load7 1.8s infinite ease-in-out",
    "&:before": {
      borderRadius: "50%",
      width: "2em",
      height: "2em",
      animationFillMode: "both",
      animation: "load7 1.8s infinite ease-in-out",
      content: "",
      position: "absolute",
      top: "0",
      left: "-3.5em",
      animationDelay: "-0.32s",
    },
    "&:after": {
      borderRadius: "50%",
      width: "2em",
      height: "2em",
      animationFillMode: "both",
      animation: "load7 1.8s infinite ease-in-out",
      content: "",
      position: "absolute",
      top: "0",
      left: "3.5em",
    },
  },
  label: {
    borderRadius: "20px 20px 20px 20px",
    // background: "cornflowerblue",
    color: "white",
    padding: "4px",
    textAlign: "center",
  },
  checkBox: {
    color: theme.palette.lpFirst.main,
  },
  checkBoxDisabled: {
    color: theme.palette.lpFirst.main,
  },
  loading: {
    animation: "blink 0.5s ease-in-out infinite alternate",
  },
}));

type TableData = {
  [key: string]: any;
};

type TableProps = {
  headers: { [key: string]: any }[];
  data: TableData[];
  filter?: string;
  loading?: boolean;
  cursor?: boolean;
  moreScroll?: boolean;
  height?: string;
  tooltip?: boolean;
  onRowClick?: (row: TableData) => void;
  onRowDoubleClick?: (row: TableData) => void;
  onDeleteRow?: (row: TableData) => void;
  onScrolled?: () => Promise<any>;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>, row: TableData) => void;
};

export const SumTable: React.FC<TableProps> = ({
  headers = [],
  data = [],
  filter = "",
  loading = false,
  cursor = false,
  moreScroll = false,
  height = "",
  tooltip = false,
  onRowClick = () => {},
  onRowDoubleClick = () => {},
  onDeleteRow = () => {},
  onScrolled = async () => {},
  onChange = () => {},
}: TableProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const [moreScrollLoading, setMoreScrollLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(-1);

  if (filter) {
    data = data.filter((item: any) => {
      for (let i in item) {
        if (item[i].indexOf(filter) !== -1) {
          return true;
        }
      }
      return false;
    });
  }

  const handleScroll = (e: any) => {
    const scrolled = async () => {
      const bottom =
        e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight;
      console.log(bottom);
      if (bottom) {
        setMoreScrollLoading(true);
        await onScrolled();
        setMoreScrollLoading(false);
        console.log("end");
      }
    };
    scrolled();
  };

  useEffect(() => {
    if (selectedRow >= data.length) {
      setSelectedRow(data.length - 1);
    }
  }, [data, selectedRow]);

  return (
    <Fragment>
      {loading ? (
        <div>
          <Skeleton className={classes.skeleton} variant="rect" height={30} />
          <Skeleton className={classes.skeleton} variant="rect" height={40} />
          <Skeleton className={classes.skeleton} variant="rect" height={40} />
          <Skeleton className={classes.skeleton} variant="rect" height={40} />
          <Skeleton className={classes.skeleton} variant="rect" height={40} />
        </div>
      ) : (
        <div
          style={
            height
              ? { height: height, maxHeight: "600px", overflow: "hidden" }
              : { maxHeight: "600px", overflow: "hidden" }
          }
          onScroll={handleScroll}
        >
          <table id="table" className={classes.table}>
            <thead>
              <tr>
                {headers?.map((header: any, index: number) => {
                  return (
                    <th key={index} className={classes.th}>
                      {header.disp}
                    </th>
                  );
                })}
              </tr>
            </thead>
            {data.length === 0 ? (
              <tbody>
                <tr>
                  <td
                    colSpan={Number(headers.length)}
                    style={{ textAlign: "center" }}
                  >
                    データがありません。
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {data.map((d: any, rowIndex: number) => {
                  return (
                    <Tooltip
                      title={d["tooltip"]}
                      placement="right-end"
                      disableFocusListener={!tooltip}
                      disableHoverListener={!tooltip}
                      disableTouchListener={!tooltip}
                    >
                      <tr
                        key={rowIndex}
                        className={
                          classes.tr +
                          " " +
                          (d["cursor"] === "default" || !cursor
                            ? ""
                            : classes.trCursor) +
                          " " +
                          (d["cursor"] === "default" || !cursor
                            ? ""
                            : selectedRow === rowIndex && classes.trSelectedRow)
                        }
                        onDoubleClick={() => {
                          onRowDoubleClick(d);
                          setSelectedRow(rowIndex);
                        }}
                        onClick={() => {
                          onRowClick(d);
                          setSelectedRow(rowIndex);
                        }}
                      >
                        {headers?.map((header: any, index: number) => {
                          return header.type === "iconText" ? (
                            <td key={index} className={classes.iconGroup}>
                              <div
                                className={classes.iconCircle}
                                style={
                                  d.iconColor === "green"
                                    ? { background: "#76E59C" }
                                    : d.iconColor === "red"
                                    ? { background: "#E76A7C" }
                                    : {}
                                }
                              >
                                <i
                                  className={d.icon + " " + classes.icon}
                                  style={
                                    d.iconColor === "green"
                                      ? { color: "#D9FEE1" }
                                      : d.iconColor === "red"
                                      ? { color: "#FFE8F4" }
                                      : {}
                                  }
                                />
                              </div>
                              <div className={classes.iconText}>
                                {d[header.name]}
                              </div>
                            </td>
                          ) : header.type === "text" ? (
                            <td
                              key={index}
                              id={String(header.name) + "_" + String(rowIndex)}
                            >
                              {d[header.name]}
                            </td>
                          ) : header.type === "moneyText" ? (
                            <td key={index}>
                              {isNaN(d[header.name]) ? (
                                d[header.name]
                              ) : (
                                <Fragment>
                                  <img
                                    src={currencySymbol}
                                    alt=""
                                    width={"9px"}
                                    style={{
                                      position: "relative",
                                      top: "2px",
                                      right: "2px",
                                    }}
                                  />
                                  {Number(d[header.name]).toLocaleString()}
                                </Fragment>
                              )}
                            </td>
                          ) : header.type === "coloredMoneyText" ? (
                            <td
                              key={index}
                              className={classes.iconGroup}
                              style={{ textAlignLast: "left" }}
                            >
                              {isNaN(d[header.name]) ? (
                                d[header.name]
                              ) : (
                                <Fragment>
                                  <div
                                    className={classes.iconCircle}
                                    style={{
                                      // width: "50px",
                                      alignItems: "rigth",
                                    }}
                                  >
                                    <i
                                      className={d.icon + " " + classes.icon}
                                      style={
                                        d.iconColor === "green"
                                          ? { color: "#76E59C" }
                                          : d.iconColor === "red"
                                          ? { color: "#E76A7C" }
                                          : {}
                                      }
                                    />
                                  </div>
                                  <div
                                    style={{
                                      width: "80px",
                                      display: "flex",
                                      flexDirection: "row-reverse",
                                      alignItems: "center",
                                    }}
                                  >
                                    <h3
                                      style={{
                                        color: "#000000",
                                        display: "inline-flex",
                                      }}
                                    >
                                      {Number(d[header.name]).toLocaleString()}
                                    </h3>
                                    <img
                                      src={currencySymbol}
                                      alt=""
                                      width={"10px"}
                                      height={"10px"}
                                      style={{
                                        position: "relative",
                                        top: "1px",
                                        right: "2px",
                                      }}
                                    />
                                  </div>
                                </Fragment>
                              )}
                            </td>
                          ) : header.type === "textBox" ? (
                            <td key={index}>
                              <TextBox
                                id={
                                  String(header.name) + "_" + String(rowIndex)
                                }
                                value={d[header.name]}
                                maxWidth={
                                  header.maxWidth ? header.maxWidth : "100px"
                                }
                                textAlign={header.textAlign}
                                readOnly={d[header.readOnly]}
                                onChange={(e: any) => header.onTextChange(e, d)}
                                onFocus={
                                  !!header.onTextFocus
                                    ? (e: any) => header.onTextFocus(e, d)
                                    : () => {}
                                }
                                onBlur={
                                  !!header.onTextBlur
                                    ? (e: any) => header.onTextBlur(e, d)
                                    : () => {}
                                }
                                type={d["textType"] ?? "text"}
                              />
                            </td>
                          ) : header.type === "textArea" ? (
                            <td key={index}>
                              <TextArea
                                id={
                                  String(header.name) + "_" + String(rowIndex)
                                }
                                value={d[header.name]}
                                maxWidth={
                                  header.maxWidth ? header.maxWidth : "100px"
                                }
                                onChange={(e: any) => header.onTextChange(e, d)}
                              />
                            </td>
                          ) : header.type === "selectBox" ? (
                            <td key={index}>
                              <SelectBox
                                value={d[header.name]}
                                options={header.options}
                                maxWidth={
                                  header.maxWidth ? header.maxWidth : "200px"
                                }
                                onChange={(e) => header.onChange(e, d)}
                              />
                            </td>
                          ) : header.type === "checkBox" ? (
                            <td key={index}>
                              <Checkbox
                                disabled={header.disabled || d["disabled"]}
                                checked={d[header.name]}
                                onChange={(e: any) =>
                                  header.onCheckBoxChange(e, d)
                                }
                                style={
                                  header.disabled || d["disabled"]
                                    ? { color: "#9C9391" }
                                    : { color: theme.palette.lpFirst.dark }
                                }
                              />
                            </td>
                          ) : header.type === "label" ? (
                            <td key={index}>
                              <div
                                id={
                                  String(header.name) + "_" + String(rowIndex)
                                }
                                className={classes.label}
                                style={{
                                  maxWidth: header.maxWidth,
                                  background: header.background[d[header.name]],
                                }}
                              >
                                {d[header.name]}
                              </div>
                            </td>
                          ) : header.type === "datetime" ? (
                            <td key={index}>
                              {!d[header.name]
                                ? ""
                                : moment(d[header.name]).format(
                                    "YYYY年MM月DD日 HH時mm分"
                                  )}
                            </td>
                          ) : header.type === "detail" ? (
                            <td key={index} className={classes.iconGroup}>
                              <IconButton
                                aria-label="detail"
                                onClick={(e: any) => header.onClick(e, d)}
                              >
                                <DetailsIcon />
                              </IconButton>
                            </td>
                          ) : header.type === "delete" && !d["disabled"] ? (
                            <td key={index} className={classes.iconGroup}>
                              <IconButton
                                id={
                                  String(header.name) + "_" + String(rowIndex)
                                }
                                aria-label="delete"
                                onClick={() => onDeleteRow(d)}
                              >
                                <DeleteIcon />
                              </IconButton>
                              <div>
                                {/* <i className={"fa fa-trash-o " + classes.icon} onClick={() => onDeleteRow(d)} /> */}
                              </div>
                            </td>
                          ) : header.type === "button" ? (
                            <td key={index} className={classes.iconGroup}>
                              <Button
                                id={
                                  String(header.name) + "_" + String(rowIndex)
                                }
                                title={header.title}
                                variant="outlined"
                                onClick={() => header.onClick(d)}
                              />
                              <div>
                                {/* <i className={"fa fa-trash-o " + classes.icon} onClick={() => onDeleteRow(d)} /> */}
                              </div>
                            </td>
                          ) : header.type === "custom" ? (
                            <Fragment>
                              {header.component(rowIndex, index, header, d)}
                            </Fragment>
                          ) : (
                            <td key={index}></td>
                          );
                        })}
                      </tr>
                    </Tooltip>
                  );
                })}
                {moreScroll ? (
                  <Fragment>
                    {moreScrollLoading ? (
                      <tr>
                        <td
                          className={classes.loading}
                          colSpan={Number(headers.length)}
                          style={{ textAlign: "center" }}
                        >
                          loading...
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td
                          colSpan={Number(headers.length)}
                          style={{ textAlign: "center" }}
                        >
                          {/* データをロードします。 */}
                        </td>
                      </tr>
                    )}
                  </Fragment>
                ) : (
                  <tr>
                    <td
                      colSpan={Number(headers.length)}
                      style={{ textAlign: "center" }}
                    >
                      {/* これ以上データはありません。 */}
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
      )}
    </Fragment>
  );
};
