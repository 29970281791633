import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  textBox: {
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
  label: {},
  inputGroup: {
    display: "flex",
    width: "100%",
  },
  icon: {
    marginTop: "5px",
    padding: "8px 16px 0 16px",
    borderRadius: "3px 0 0 3px",
    background: theme.palette.lpGray.light,
    border: "1px solid #ddd",
  },
  input: {
    "&:read-only": {
      backgroundColor: "rgb(234, 233, 233)",
      "&:hover": {
        backgroundColor: "rgb(234, 233, 233)",
      },
      "&:focus": {
        backgroundColor: "rgb(234, 233, 233)",
      },
    },
    // marginTop: "5px",
    width: "90%",
    height: "37px",
    padding: "10px 15px",
    background: theme.palette.lpTextBox.main,
    borderRadius: "4px",
    border: "1px solid #ddd",
    boxSizing: "border-box",
    "&:hover": {
      background: theme.palette.lpTextBox.dark,
    },
    "&:focus": {
      outline: "0",
      border: "1px solid " + theme.palette.lpSecond.main,
      background: theme.palette.lpTextBox.light,
    },
  },
  errorInput: {
    marginTop: "5px",
    width: "100%",
    height: "37px",
    padding: "10px 15px",
    background: theme.palette.lpTextBox.main,
    borderRadius: "4px",
    border: "1px solid red",
    boxSizing: "border-box",
    "&:hover": {
      background: theme.palette.lpTextBox.dark,
    },
    "&:focus": {
      outline: "0",
      border: "1px solid " + theme.palette.lpSecond.main,
      background: theme.palette.lpTextBox.light,
    },
  },
  inputIcon: {
    marginTop: "5px",
    width: "100%",
    padding: "10px 15px",
    borderRadius: "0 3px 3px 0",
    background: theme.palette.lpTextBox.main,
    border: "1px solid #ddd",
    boxSizing: "border-box",
    "&:hover": {
      background: theme.palette.lpTextBox.dark,
    },
    "&:focus": {
      outline: "0",
      border: "1px solid " + theme.palette.lpSecond.main,
      background: theme.palette.lpTextBox.light,
      borderRadius: "0 3px 3px 0",
    },
  },
  error: {
    color: "red",
    fontSize: "10px",
  },
  inputErrorGroup: {
    flex: 1,
    display: "block",
  },
}));

type TextBoxProps = {
  id?: string;
  title?: string;
  value?: string;
  width?: string;
  maxWidth?: string;
  icon?: string;
  placeholder?: string;
  error?: string;
  readOnly?: boolean;
  multiline?: boolean;
  row?: number;
  textAlign?: "right" | "left";
  required?: boolean;
  labelWidth?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onInput?: (e: any) => void;
  onFocus?: any;
  onBlur?: any;
  style?: any;
  type?: any;
  autoComplete?: "on" | "off";
};

export const SearchBox: React.FC<TextBoxProps> = ({
  id = "",
  title = "",
  value = "",
  width = "100%",
  maxWidth = "",
  icon = "",
  placeholder = "",
  error = "",
  required = false,
  readOnly = false,
  multiline = false,
  labelWidth = "100px",
  row = 1,
  textAlign = "left",
  onChange = () => {},
  onInput = () => {},
  onBlur = () => {},
  onFocus = () => {},
  style = {},
  type = "text",
  autoComplete = "on",
}: TextBoxProps) => {
  const classes = useStyles();
  //const { title, value, width, icon, placeholder, onChange, error } = props

  return (
    <Fragment>
      <div className={classes.textBox} style={{ width: width }}>
        {title && (
          <div className={classes.label} style={{ width: labelWidth }}>
            {title}
            {required && <span style={{ color: "red" }}> *</span>}
          </div>
        )}

        {icon ? (
          <div className={classes.inputGroup}>
            <div className={classes.icon}>
              <span>
                <i className={icon}></i>
              </span>
            </div>
            <input
              type={type}
              id={id}
              className={classes.inputIcon}
              value={value}
              placeholder={placeholder}
              readOnly={readOnly}
              onChange={onChange}
              style={{ ...style, maxWidth: maxWidth, textAlign: textAlign }}
              autoComplete={autoComplete}
            />
          </div>
        ) : (
          <Fragment>
            <div className={classes.inputErrorGroup}>
              <input
                type={type}
                id={id}
                className={error ? classes.errorInput : classes.input}
                value={value}
                placeholder={placeholder}
                readOnly={readOnly}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onInput={onInput}
                style={{ ...style, maxWidth: maxWidth, textAlign: textAlign }}
                autoComplete={autoComplete}
              />
              {error && <div className={classes.error}>{error}</div>}
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};
