import { Fragment, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ActionEnum as LoginActionEnum,
  LoginContext,
} from "../../stores/LoginStore";
import {
  ActionEnum as ThemeActionEnum,
  ThemeContext,
} from "../../stores/ThemeStore";
import {
  ActionEnum as UsersActionEnum,
  UsersContext,
} from "../../stores/UsersStore";
import { GetApi } from "../../utils/api/api";
import { ForbiddenPage } from "../ForbiddenPage";
import { GetCognitoAuth } from "./awsConfig";
import {
  MenuContext,
  ActionEnum as MenuActionEnum,
} from "../../stores/MenuStore";

export const Auth = (props: any) => {
  const history = useHistory();
  const [login, setLogin] = useState(false);
  const { dispatch: loginDispatch } = useContext(LoginContext);
  const { dispatch: themeDispatch } = useContext(ThemeContext);
  const { dispatch: usersDispatch } = useContext(UsersContext);
  const { state: menuState, dispatch: menuDispatch } = useContext(MenuContext);
  const [noAuth, setNoAuth] = useState(false);

  if (props.location.pathname !== "/null") {
    sessionStorage.redirecturl =
      props.location.pathname + props.location.search;
  }

  useEffect(() => {
    const auth = GetCognitoAuth(
      async (result: any) => {
        const user = await GetApi("/users/me");
        if (user.statusCode === 400) {
          setNoAuth(true);
          return;
        }
        loginDispatch({
          type: LoginActionEnum.LOGIN,
          payload: {
            loginUserInfo: {
              bs: user.data.bs || "",
              dept: user.data.dept || "",
              name: user.data.name || "",
              email: user.data.email || "",
              dept_code: user.data.dept_code || "",
              position: user.data.position || "",
              availableBankAccount: user.data.available_bank_account || "",
              defaultBankAccount: user.data.default_bank_account || "",
              auth: {
                generalAffairs: false,
                officerEntertainmentExpenses: false,
                balanceEditing: false,
              },
            },
          },
        });
        themeDispatch({
          type: ThemeActionEnum.CHANGE_THEME_COLOR,
          payload: {
            themeColor: user.data.theme,
          },
        });

        try {
          GetApi("/users/me/auth").then((userInfo) => {
            console.log(userInfo);
            loginDispatch({
              type: LoginActionEnum.LOGIN,
              payload: {
                loginUserInfo: {
                  bs: user.data.bs || "",
                  dept: user.data.dept || "",
                  name: user.data.name || "",
                  email: user.data.email || "",
                  dept_code: user.data.dept_code || "",
                  position: user.data.position || "",
                  availableBankAccount: user.data.available_bank_account || "",
                  defaultBankAccount: user.data.default_bank_account || "",
                  auth: {
                    generalAffairs: userInfo.data.generalAffairs,
                    officerEntertainmentExpenses:
                      userInfo.data.officerEntertainmentExpenses,
                    balanceEditing: userInfo.data.balanceEditing,
                  },
                  // generalAffairs: generalAffairs.data?.users?.length > 0 ? true : false,
                },
              },
            });
          });
        } catch (error) {
          console.log(error);
        }

        try {
          GetApi("/users").then((result) => {
            usersDispatch({
              type: UsersActionEnum.SET_USERS,
              payload: {
                users: result.data,
              },
            });
          });
        } catch (error) {
          console.log(error);
        }
        const defaultRedirectUrl = "/dashboard";
        let url = String(sessionStorage.getItem("redirecturl"));
        console.log(url);
        if (
          !url ||
          url === "null" ||
          url === "" ||
          url === "/" ||
          url === defaultRedirectUrl
        ) {
          url = defaultRedirectUrl;
          menuDispatch({
            type: MenuActionEnum.SELECT_MENU,
            payload: {
              menu: {
                name: "ダッシュボード",
                disp: true,
              },
            },
          });
          history.push(url);
        }
        setLogin(true);
      },
      () => {
        console.log("認証に失敗しました。");
      }
    );
    auth.getSession();
  }, [history, loginDispatch, themeDispatch, usersDispatch]);

  return (
    <Fragment>{login ? props.children : noAuth && <ForbiddenPage />}</Fragment>
  );
};
