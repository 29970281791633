import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useEffect, useState } from "react";
import {
  ViewWrapper,
  Button,
  Table,
  Card,
  SelectBox,
  MessageBar,
  MoneyTicketStatusSteper,
  ModalForm,
} from "../../components";
import { useHistory } from "react-router-dom";
import { GetApi, PostApi } from "../../utils";
import { GeneralAffairsDetail } from ".";
import queryString from "query-string";
import {
  ActionEnum as LoaderActionEnum,
  LoaderContext,
} from "../../stores/LoaderStore";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { statusBackgroundColor } from "../../definitions/const";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: "500px",
    padding: "8px",
    margin: theme.spacing(1),
  },
  title: {
    fontSize: 12,
  },
  moneyGroup: {
    display: "flex",
  },
  money: {
    padding: "0 20px 0 20px",
    fontSize: "50px",
    color: "#A3A0F5",
  },
  expire: {
    marginTop: "10px",
    padding: "5px 10px 5px 10px",
    color: "#181724",
    background: "#E3E3E3",
    borderRadius: "20px 20px 20px 20px",
  },
  test: {
    // width: "50%",
    margin: "0 auto",
    marginTop: 150,
  },
  splitPane: {
    position: "static",
  },
  container: {
    display: "grid",
    gridTemplateColumns: "40% 60%",
    height: "100%",
  },
  mainContainerWithSide: {
    gridColumn: "1/2",
  },
  mainContainer: {
    gridColumn: "1/3",
  },
  sideContainerNone: {
    display: "none",
  },
  sideContainer: {
    overflow: "overlay",
    padding: "16px",
    left: "32px",
    position: "relative",
    height: "100%",
    gridColumn: "2/3",
    background: theme.palette.lpWhite.main,
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, .3)",
  },
  status: {
    margin: "8px",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const options = [
  {
    code: "GENERAL_AFFAIRS",
    name: "総務承認待ち",
  },
  {
    code: "APPROVAL",
    name: "総務承認済み",
  },
  {
    code: "SETTLEMENT",
    name: "決済完了",
  },
  {
    code: "REJECTION",
    name: "差し戻し",
  },
  {
    code: "FIX",
    name: "修正依頼",
  },
];

export const GeneralAffairs = (props: any) => {
  // クエリパラメータの取得
  const qs = queryString.parse(props.location.search);
  const { dispatch: loaderDispatcn } = useContext(LoaderContext);
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState<{}[]>([]);
  const [status, setStatus] = useState(
    !qs.status ? "GENERAL_AFFAIRS" : String(qs.status)
  );
  const [statusName, setStatusName] = useState(
    qs.status === "SETTLEMENT"
      ? "決済完了"
      : qs.status === "APPROVAL"
      ? "総務承認済み"
      : "総務承認待ち"
  );
  const [tableLoading, setTableLoading] = useState(true);
  const [moreScroll, setMoreScroll] = useState(false);
  const [id, setId] = useState(!qs.selectedId ? "" : qs.selectedId);
  const [messageBarMessage, setMessageBarMessage] = useState("");
  const [messageBarOpen, setMessageBarOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [paymentDate, setPaymentDate] = useState(moment().format("YYYY-MM-DD"));

  const [searchCount, setSearchCount] = useState("");

  const onRowClick = (row: any) => {
    setId(row.apply_id);
    history.push("/money-ticket/general-affairs?selectedId=" + row.apply_id);
  };

  const onClickCloseModal = () => {
    setModalIsOpen(false);
  };

  const fetchData = async (status: string, statusChange: boolean = false) => {
    if (data.length > 0 && !moreScroll && !statusChange) {
      return;
    }
    if (data.length === 0 || statusChange) {
      setTableLoading(true);
    }
    const param = { offset: !statusChange ? data.length : 0, status: status };
    const result = await GetApi("/money-ticket/general-affairs", param);

    console.log("fetchData")
    console.log(param)

    if (result.statusCode >= 400) {
      setMessageBarMessage(result.message);
      setMessageBarOpen(true);
      return;
    }

    console.log("resultaaa")
    console.log(result)
    console.log(result.length)
    console.log("検索件数:"+result.data.length)
    setSearchCount(result.data.length)

    setMoreScroll(result.data.length === 50 ? true : false);
    if (data.length === 0 || statusChange) {
      setData(
        result.data.map((d: any) => {
          d.select = false;
          return d;
        })
      );
      setTableLoading(false);
    } else {
      const _tempData = result.data
        .filter(
          (element: any) =>
            data.findIndex((e: any) => e.apply_id === element.apply_id) < 0
        )
        .map((d: any) => {
          d.select = false;
          return d;
        });
      const __tempData = [...data, ..._tempData];
      setData(__tempData);
    }
  };

  const onScrolled = async () => {
    if (!moreScroll) {
      return;
    }
    await fetchData(status);
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    fetchData(status);
  }, []);

  const onSelectChange = (e: any) => {
    setId("");
    setStatus(e.target.value);
    setStatusName(
      options.filter((option) => option.code === e.target.value)[0].name
    );
    fetchData(e.target.value, true);
  };

  const paymentDateChange = (e: any) => {
    setPaymentDate(e.target.value);
  };

  const onCheckBoxChange = (e: any, row: any) => {
    setData(
      data.map((data: any) => {
        if (data.display_apply_id === row.display_apply_id) {
          data.select = e.target.checked;
        }
        return data;
      })
    );
  };

  const onClickDetailRow = (e: any, row: any) => {
    setId(row.apply_id);
    history.push("/money-ticket/general-affairs?selectedId=" + row.apply_id);
  };

  function exportCSV(records: any) {
    let data = records.map((record: any) => record.join(",")).join("\r\n");

    let bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    let blob = new Blob([bom, data], { type: "text/csv" });
    let url = (window.URL || window.webkitURL).createObjectURL(blob);
    let link = document.createElement("a");
    link.download =
      "金券_即時支払伝票_" + moment().format("YYYY-MM-DD_HH:mm:ss") + ".csv";
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const modalOpen = () => {
    setModalIsOpen(true);
  };

  const onClikcOutputSettlementCSV = async () => {
    const sendData = data
      .filter((d: any) => d.select)
      .map((d: any) => d.apply_id);
    if (sendData.length === 0) {
      setMessageBarMessage("データが選択されていません。");
      setMessageBarOpen(true);
      return;
    }

    if (paymentDate < moment().format("YYYY-MM-DD")) {
      setMessageBarMessage("過去日付は指定できません。");
      setMessageBarOpen(true);
      return;
    }
    loaderDispatcn({
      type: LoaderActionEnum.SET_LOADER_TRUE,
    });
    const param = {
      paymentDate: paymentDate,
      apply_id: sendData,
    };
    const result = await PostApi("/money-ticket/settlement/csv", param);
    loaderDispatcn({
      type: LoaderActionEnum.SET_LOADER_FALSE,
    });

    if (result.statusCode >= 400) {
      setMessageBarMessage(result.message);
      setMessageBarOpen(true);
      return;
    }

    let csvData = [];
    csvData.push([
      "OBCD001",
      "CPIS001",
      "CPIS002",
      "CPIS003",
      "CPIS004",
      "CPIS005",
      "CPIS006",
      "CPIS007",
      "CPIS008",
      "CPIS009",
      "CPIS010",
      "CPIS011",
      "CPIS012",
      "CPIS013",
      "CPIS014",
      "CPIS015",
      "CPIS018",
      "CPIS020",
      "CPIS025",
      "CPIS027",
      "CPIS028",
      "CPIS029",
      "CPIS030",
      "CPIS032",
      "CPIS037",
      "CPIS034",
      "CPIS035",
      "CPIS036",
    ]);
    for (const res of result.data) {
      csvData.push([
        res.OBCD001,
        res.CPIS001,
        res.CPIS002,
        res.CPIS003,
        res.CPIS004,
        res.CPIS005,
        res.CPIS006,
        res.CPIS007,
        res.CPIS008,
        res.CPIS009,
        res.CPIS010,
        res.CPIS011,
        res.CPIS012,
        res.CPIS013,
        res.CPIS014,
        res.CPIS015,
        res.CPIS018,
        res.CPIS020,
        res.CPIS025,
        res.CPIS027,
        res.CPIS028,
        res.CPIS029,
        res.CPIS030,
        res.CPIS032,
        res.CPIS037,
        res.CPIS034,
        res.CPIS035,
        res.CPIS036,
      ]);
    }
    exportCSV(csvData);

    setData(data.filter((d: any) => !d.select));
    return;
  };

  return (
    <ViewWrapper>
      <MessageBar
        open={messageBarOpen}
        setOpen={setMessageBarOpen}
        severity={"error"}
        message={messageBarMessage}
      />

      <ModalForm
        isOpen={modalIsOpen}
        onClickClose={onClickCloseModal}
        height={"120px"}
      >
        <h2>入金予定日</h2>
        <Grid container direction="row" justify="center" alignItems="center">
          <TextField
            id="date"
            label="入金予定日"
            type="date"
            value={paymentDate}
            variant={"outlined"}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={paymentDateChange}
          />
          <Button title="CSV出力" onClick={onClikcOutputSettlementCSV} />
        </Grid>
      </ModalForm>

      <div className={classes.container}>
        <div
          className={id ? classes.mainContainerWithSide : classes.mainContainer}
        >
          <div className={classes.status}>
            <div className={classes.title}>ステータス</div>
            <MoneyTicketStatusSteper status={statusName} />
            <SelectBox
              title={"ステータス選択"}
              value={status}
              options={options}
              onChange={onSelectChange}
            />
            
            <div className={classes.title}>件数：{searchCount}件</div>

          </div>
          <p style={{ color: "red", paddingLeft: "10px" }}>
            ※申請をクリックして明細をひらきます
          </p>

          <Card title={"総務が承認した/する金券精算申請"} titleLayout={true}>
            <Table
              loading={tableLoading}
              cursor
              headers={
                status !== "APPROVAL"
                  ? [
                      {
                        name: "display_apply_id",
                        disp: "申請書ID",
                        type: "text",
                      },
                      {
                        name: "applicant_name",
                        disp: "申請者",
                        type: "text",
                      },
                      {
                        name: "applicant_dept",
                        disp: "部署",
                        type: "text",
                      },
                      {
                        name: "created_at",
                        disp: "申請日",
                        type: "datetime",
                      },
                      {
                        name: "apply_status",
                        disp: "ステータス",
                        type: "label",
                        maxWidth: "100px",
                        background: {
                          配分者確認待ち: "#A1BC00",
                          上長承認待ち: "#00BE6F",
                          総務承認待ち: "#005DBE",
                          総務承認済み: "#4400B7",
                          決済完了: "#383636",
                          差し戻し: "#C1335F",
                          修正依頼: "#fa8000",
                        },
                      },
                    ]
                  : [
                      {
                        name: "select",
                        disp: "選択",
                        type: "checkBox",
                        onCheckBoxChange: onCheckBoxChange,
                      },
                      {
                        name: "display_apply_id",
                        disp: "申請書ID",
                        type: "text",
                      },
                      {
                        name: "contents",
                        disp: "内容",
                        type: "text",
                      },
                      {
                        name: "applicant_name",
                        disp: "申請者",
                        type: "text",
                      },
                      {
                        name: "applicant_dept",
                        disp: "部署",
                        type: "text",
                      },
                      {
                        name: "created_at",
                        disp: "申請日",
                        type: "datetime",
                      },
                      {
                        name: "apply_status",
                        disp: "ステータス",
                        type: "label",
                        maxWidth: "100px",
                        background: statusBackgroundColor,
                      },
                      {
                        name: "button",
                        disp: "詳細",
                        type: "detail",
                        onClick: onClickDetailRow,
                      },
                    ]
              }
              data={data}
              onRowClick={status !== "APPROVAL" ? onRowClick : () => {}}
              onScrolled={onScrolled}
              moreScroll={moreScroll}
            />
          </Card>
          {status === "APPROVAL" && (
            <div className={classes.buttonGroup}>
              <Button
                title="全てチェック"
                variant="contained"
                onClick={() => {
                  setData(
                    data.map((d: any) => {
                      d.select = true;
                      return d;
                    })
                  );
                }}
              />
              <Button
                title="CSV出力"
                variant="contained"
                onClick={modalOpen}
                // onClick={onClikcOutputSettlementCSV}
              />
            </div>
          )}
        </div>
        <div
          className={
            id
              ? `${classes.sideContainer} SideDetail`
              : classes.sideContainerNone
          }
        >
          <GeneralAffairsDetail
            id={id}
            setId={setId}
            data={data}
            setData={setData}
          />
        </div>
      </div>
    </ViewWrapper>
  );
};
