import React, { useContext } from "react";
import Modal from "react-modal";
import { Button, Table, TextBox } from "..";
import { UsersContext } from "../../stores/UsersStore";
import { LoginContext } from "../../stores/LoginStore";

const modalStyle: Modal.Styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.55)",
  },
  content: {
    maxWidth: "500px",
    margin: "auto",
    padding: "16px",
  },
};

type ModalUserListProps = {
  type?: "all" | "orMorePosition";
  value: string | undefined;
  isOpen: boolean;
  selectedUsers: string[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onRowDoubleClick: (row: any) => void;
  onClickClose: () => void;
};

export const ModalUserList: React.FC<ModalUserListProps> = ({
  type = "all",
  value = "",
  isOpen = false,
  selectedUsers = [],
  onChange = () => {},
  onRowDoubleClick = () => {},
  onClickClose = () => {},
}: ModalUserListProps) => {
  // const classes = useStyles();
  const { state: usersState } = useContext(UsersContext);
  const { state: loginState } = useContext(LoginContext);
  let users;
  if (type === "all") {
    users = usersState.users;
    users = users.filter((user: any) => {
      return !selectedUsers.some((selectUser: any) => selectUser === user.bs);
    });
  } else if (type === "orMorePosition") {
    const compairPosition =
      !loginState.loginUserInfo?.position ||
      loginState.loginUserInfo?.position <= "1011"
        ? "1010"
        : "1020";
    users = usersState.users.filter(
      (user: any) => user.position >= compairPosition
    );
    users = users.filter((user: any) => {
      return !selectedUsers.some((selectUser: any) => selectUser === user.bs);
    });
  }

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      style={modalStyle}
      onRequestClose={onClickClose}
    >
      <h3>ユーザ選択</h3>
      <TextBox
        icon={"fa fa-search"}
        placeholder={"search(部分一致)"}
        value={value}
        onChange={onChange}
        width={"90%"}
      />
      <p>※ダブルクリックでユーザが追加できます</p>
      <Table
        filter={value}
        height={"70%"}
        headers={[
          // {
          //   name: "bs",
          //   disp: "bs番号",
          //   type: "text",
          // },
          {
            name: "name",
            disp: "名前",
            type: "text",
          },
          {
            name: "dept",
            disp: "部門",
            type: "text",
          },
        ]}
        data={users}
        onRowDoubleClick={onRowDoubleClick}
      />
      <Button title="閉じる" onClick={onClickClose} />
    </Modal>
  );
};
