import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment, useState } from "react";
import { ViewWrapper, Button, Table, Card } from "../../components";
import { useHistory } from "react-router-dom";
import { ApplyDetail } from ".";
import queryString from "query-string";
import Tabs from "@material-ui/core/Tabs";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import { statusBackgroundColor } from "../../definitions/const";
import { useGetApi } from "../../hooks/useGetApi";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "40% 60%",
    height: "100%",
  },
  mainContainerWithSide: {
    gridColumn: "1/2",
  },
  mainContainer: {
    gridColumn: "1/3",
  },
  sideContainerNone: {
    display: "none",
  },
  sideContainer: {
    overflow: "scroll",
    padding: "0px 8px",
    left: "32px",
    position: "relative",
    height: "100%",
    gridColumn: "2/3",
    background: theme.palette.lpWhite.main,
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, .3)",
  },
}));

const Apply = (props: any) => {
  const qs = queryString.parse(props.location.search);
  const classes = useStyles();
  const history = useHistory();
  const width = props.width;

  const [status, setStatus] = useState(
    !qs.status ? "UNDECIDED" : String(qs.status)
  );

  const [id, setId] = useState(!qs.selectedId ? "" : qs.selectedId);
  const [type, setType] = useState(!qs.type ? "" : qs.type);
  const [tabValue, setTabValue] = useState(0);
  const [queries, setQueries] = useState({});
  const {
    data: historyData,
    setData: setHistoryData,
    isLoading: isLoadingHistoryData,
    moreScroll,
  } = useGetApi({
    type: "scroll",
    url: "/money-ticket/apply",
    queries: queries,
    id: "apply_id",
  });

  const handleChangeTabValue = (
    event: React.ChangeEvent<{}>,
    newValue: any
  ) => {
    setTabValue(newValue);
  };

  const onRowClick = (row: any) => {
    let typeCode = "approval";
    setId(row.id);
    setTabValue(1);
    setType(typeCode);
    history.push(
      "/money-ticket/apply?type=" + typeCode + "&selectedId=" + row.id
    );
  };

  const onScrolled = async () => {
    setQueries({
      offset: historyData.length,
    });
  };

  return (
    <ViewWrapper>
      <div className={classes.container}>
        <div
          className={
            id && isWidthUp("sm", width)
              ? classes.mainContainerWithSide
              : classes.mainContainer
          }
        >
          <div>
            <Button
              title={"新規金券精算申請"}
              onClick={() => history.push("/money-ticket/apply/create")}
            />
          </div>

          {isWidthDown("xs", width) && (
            <Box m={1}>
              <Paper square>
                <Tabs
                  centered
                  variant="fullWidth"
                  value={tabValue}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleChangeTabValue}
                  aria-label="disabled tabs example"
                >
                  <Tab label="一覧" />
                  <Tab label="詳細" />
                </Tabs>
              </Paper>
            </Box>
          )}

          {(isWidthDown("xs", width) && tabValue === 0) ||
          isWidthUp("sm", width) ? (
            <Fragment>
              <p style={{ paddingLeft: "8px" }}>
                ※申請をクリックして明細をひらきます
              </p>
              <Card title={"あなたの金券精算申請"} titleLayout={true}>
                <Table
                  loading={isLoadingHistoryData}
                  cursor
                  headers={[
                    {
                      name: "apply_category",
                      disp: "申請種別",
                      type: "custom",
                      component: (
                        rowIndex: any,
                        index: any,
                        header: any,
                        rowData: any
                      ) => {
                        return (
                          <td
                            key={index}
                            id={String(header.name) + "_" + String(rowIndex)}
                          >
                            <p
                              style={{
                                color: "gray",
                                margin: "2px",
                                fontSize: "6px",
                              }}
                            >
                              {!rowData["created_at"]
                                ? ""
                                : moment(rowData["created_at"]).format(
                                    "YYYY年MM月DD日 HH時mm分"
                                  )}
                            </p>
                            <p style={{ margin: "2px" }}>
                              {rowData["display_apply_id"]}
                            </p>
                            <p style={{ margin: "2px" }}>
                              {rowData["apply_category"]}
                            </p>
                            <p
                              style={{
                                color: "gray",
                                margin: "2px",
                                fontSize: "6px",
                              }}
                            >
                              {rowData["dept"]}
                            </p>
                          </td>
                        );
                      },
                    },
                    {
                      name: "amounts",
                      disp: "金額",
                      type: "coloredMoneyText",
                    },
                    {
                      name: "status",
                      disp: "ステータス",
                      type: "label",
                      maxWidth: "100px",
                      background: statusBackgroundColor,
                    },
                  ]}
                  data={historyData}
                  onRowClick={onRowClick}
                  onScrolled={onScrolled}
                  moreScroll={moreScroll}
                />
              </Card>
            </Fragment>
          ) : (
            <div style={{ padding: "8px" }}>
              <div>
                {id && type === "approval" ? (
                  <ApplyDetail
                    id={id}
                    setId={setId}
                    data={historyData}
                    setData={setHistoryData}
                    setTabValue={setTabValue}
                  />
                ) : (
                  <div>データが選択されていません。</div>
                )}
              </div>
            </div>
          )}
        </div>
        {isWidthUp("sm", width) && (
          <div
            className={
              id
                ? `${classes.sideContainer} SideDetail`
                : classes.sideContainerNone
            }
          >
            {id && type === "approval" ? (
              <ApplyDetail 
                id={id} 
                setId={setId}
                              data={historyData}
              setData={setHistoryData}
                setTabValue={setTabValue} />
            ) : (
              <div></div>
            )}
          </div>
        )}
      </div>
    </ViewWrapper>
  );
};

export default withWidth()(Apply);
