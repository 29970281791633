import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import {
  ViewWrapper,
  Button,
  Table,
  SumTable,
  Card,
  MessageBar,
  DatePicker,
} from "../../components";
import { GetApi } from "../../utils";
import { GeneralAffairsDetail } from ".";
import queryString from "query-string";
import Grid from "@material-ui/core/Grid";
import { SearchBox } from "../../components/SearchBox/SearchBox";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: "500px",
    padding: "8px",
    margin: theme.spacing(1),
  },
  title: {
    fontSize: 12,
  },
  moneyGroup: {
    display: "flex",
  },
  money: {
    padding: "0 20px 0 20px",
    fontSize: "50px",
    color: "#A3A0F5",
  },
  expire: {
    marginTop: "10px",
    padding: "5px 10px 5px 10px",
    color: "#181724",
    background: "#E3E3E3",
    borderRadius: "20px 20px 20px 20px",
  },
  test: {
    // width: "50%",
    margin: "0 auto",
    marginTop: 150,
  },
  splitPane: {
    position: "static",
  },
  container: {
    display: "grid",
    gridTemplateColumns: "40% 60%",
    height: "100%",
  },
  mainContainerWithSide: {
    gridColumn: "1/2",
  },
  mainContainer: {
    gridColumn: "1/3",
  },
  sideContainerNone: {
    display: "none",
  },
  sideContainer: {
    overflow: "overlay",
    padding: "16px",
    left: "32px",
    position: "relative",
    height: "100%",
    gridColumn: "2/3",
    background: theme.palette.lpWhite.main,
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, .3)",
  },
  status: {
    margin: "8px",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export const GeneralSearch = (props: any) => {
  // クエリパラメータの取得
  const qs = queryString.parse(props.location.search);
  const classes = useStyles();
  const [data, setData] = useState<{}[]>([]);

  const [sumData, setSumData] = useState<{}[]>([]);
  const [status, setStatus] = useState(
    !qs.status ? "GENERAL_AFFAIRS" : String(qs.status)
  );
  const [tableLoading, setTableLoading] = useState(true);
  const [moreScroll, setMoreScroll] = useState(false);
  const [id, setId] = useState(!qs.selectedId ? "" : qs.selectedId);
  const [messageBarMessage, setMessageBarMessage] = useState("");
  const [messageBarOpen, setMessageBarOpen] = useState(false);
  const [searchCount, setSearchCount] = useState("");

  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());

  const [organization, setOrganization] = useState("");
  const [teamName, setTeamName] = useState("");
  const [userName, setUserName] = useState("");

  const [dateToError, setdateToError] = useState("");
  const [organizationError, setOrganizationError] = useState("");
  const [teamNameError, setTeamNameError] = useState("");
  const [userNameError, setUserNameError] = useState("");

  const [honbuVisible, setHonbuVisible] = useState(true);

  const onChangeOrganization = (e: any) => {
    setOrganization(e.target.value);
  };

  const onChangeTeamName = (e: any) => {
    setTeamName(e.target.value);
  };

  const onChangeUserName = (e: any) => {
    setUserName(e.target.value);
  };

  const onChangeDateFromChange = (date: Date) => {
    const _useDate = `${date.getFullYear().toString()}-${String(
      date.getMonth() + 1
    )}-${date.getDate().toString()}`;

    setDateFrom(date);
  };

  const onChangeDateToChange = (date: Date) => {
    const _useDate = `${date.getFullYear().toString()}-${String(
      date.getMonth() + 1
    )}-${date.getDate().toString()}`;

    setDateTo(date);
  };

  const fetchData = async (status: string, statusChange: boolean = false) => {
    if (data.length > 0 && !moreScroll && !statusChange) {
      return;
    }
    if (data.length === 0 || statusChange) {
      setTableLoading(true);
    }

    const param = {
      offset: !statusChange ? data.length : 0,
      status: status,
      dateFrom: `${dateFrom.getFullYear().toString()}-${(
        "0" + String(dateFrom.getMonth() + 1)
      ).slice(-2)}-${("0" + dateFrom.getDate().toString()).slice(-2)}`,
      dateTo: `${dateTo.getFullYear().toString()}-${(
        "0" + String(dateTo.getMonth() + 1)
      ).slice(-2)}-${("0" + dateTo.getDate().toString()).slice(-2)}`,
      searchOrganization: organization,
    };

    console.log(param);

    //ここで日時を渡して初期表示
    const result = await GetApi("/money-ticket/general-search", param);
    if (result.statusCode >= 400) {
      setMessageBarMessage(result.message);
      setMessageBarOpen(true);
      return;
    }

    setSearchCount(result.data[0].length);

    setMoreScroll(result.data[0].length === 50 ? true : false);
    if (data.length === 0 || statusChange) {
      setData(
        result.data[0].map((d: any) => {
          d.select = false;
          return d;
        })
      );
      setTableLoading(false);
    } else {
      const _tempData = result.data[0]
        .filter(
          (element: any) =>
            data.findIndex((e: any) => e.apply_id === element.apply_id) < 0
        )
        .map((d: any) => {
          d.select = false;
          return d;
        });
      const __tempData = [...data, ..._tempData];
      setData(__tempData);
    }
    //合計金額
    setSumData(result.data[1]);
  };

  const onScrolled = async () => {
    if (!moreScroll) {
      return;
    }
    await fetchData(status);
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    fetchData(status);
  }, []);

  const search = () => {
    const requestData = {
      dateFrom: `${dateFrom.getFullYear().toString()}-${(
        "0" + String(dateFrom.getMonth() + 1)
      ).slice(-2)}-${("0" + dateFrom.getDate().toString()).slice(-2)}`,
      dateTo: `${dateTo.getFullYear().toString()}-${(
        "0" + String(dateTo.getMonth() + 1)
      ).slice(-2)}-${("0" + dateTo.getDate().toString()).slice(-2)}`,
      searchOrganization: organization,
      searchTeam: teamName,
      searchUserName: userName,
    };

    // 日付チェック
    if (dateFrom > dateTo) {
      setMessageBarMessage(
        "決済期間toは決済期間fromより新しい日付を指定してください。"
      );
      setMessageBarOpen(true);
      //setdateToError("決済期間toは決済期間fromより新しい日付を指定してください。")
      return;
    } else {
      setdateToError("");
    }

    //文字数チェック（組織名）
    if (requestData.searchOrganization.length > 100) {
      setMessageBarMessage("組織名は100字以内で入力してください。");
      setMessageBarOpen(true);
      setOrganizationError("組織名は100字以内で入力してください。");
      return;
    } else {
      setOrganizationError("");
    }

    //文字数チェック（チーム名）
    if (requestData.searchTeam.length > 100) {
      setMessageBarMessage("チーム名は100字以内で入力してください。");
      setMessageBarOpen(true);
      setTeamNameError("チーム名は100字以内で入力してください。");
      return;
    } else {
      setTeamNameError("");
    }

    //文字数チェック（名前）
    if (requestData.searchUserName.length > 40) {
      setMessageBarMessage("名前は40字以内で入力してください。");
      setMessageBarOpen(true);
      setUserNameError("名前は40字以内で入力してください。");
      return;
    } else {
      setUserNameError("");
    }

    const getData = async () => {
      setTableLoading(true);
      //   //ここで検索
      const res = await GetApi("/money-ticket/general-search", requestData);
      if (res.statusCode >= 400) {
        setMessageBarMessage(res.message);
        setMessageBarOpen(true);
        return;
      }

      setSearchCount(res.data[0].length);

      if (res.data[0].length === 0) {
        setData(
          res.data[0].map((d: any) => {
            d.select = false;
            return d;
          })
        );
        setTableLoading(false);
        setSumData(res.data[1]);
      } else {
        setData(res.data[0]);
        setTableLoading(false);

        //合計金額
        setSumData(res.data[1]);
      }
    };

    const result = getData();
  };

  function exportCSV(records: any) {
    let data = records.map((record: any) => record.join(",")).join("\r\n");

    let bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    let blob = new Blob([bom, data], { type: "text/csv" });
    let url = (window.URL || window.webkitURL).createObjectURL(blob);
    let link = document.createElement("a");
    link.download =
      "金券_利用状況_" + moment().format("YYYY-MM-DD_HH:mm:ss") + ".csv";
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const onClikcOutputSettlementCSV = async () => {
    let csvData = [];
    csvData.push([
      "チーム名",
      "名前",
      "決済金額",
      "飲食・差入",
      "物品購入",
      "ゴルフ",
      "レジャー",
    ]);

    for (let i = 0; i < data.length; i++) {
      let teamName = Object.values(data[i])[0];
      let userName = Object.values(data[i])[1];
      let allSum = String(Object.values(data[i])[2]).replace(/,/g, "");
      let sumEating = String(Object.values(data[i])[3]).replace(/,/g, "");
      let sumPurchase = String(Object.values(data[i])[4]).replace(/,/g, "");
      let sumGolf = String(Object.values(data[i])[5]).replace(/,/g, "");
      let sumLeisure = String(Object.values(data[i])[6]).replace(/,/g, "");

      csvData.push([
        teamName,
        userName,
        allSum,
        sumEating,
        sumPurchase,
        sumGolf,
        sumLeisure,
      ]);
    }
    exportCSV(csvData);

    setData(data.filter((d: any) => !d.select));
    return;
  };

  return (
    <ViewWrapper>
      <MessageBar
        open={messageBarOpen}
        setOpen={setMessageBarOpen}
        severity={"error"}
        message={messageBarMessage}
      />

      <Card title={"検索欄"} titleLayout={true}>
        <Grid container alignItems="center" justify="center">
          <Grid item sm={12} md={4}>
            <DatePicker
              title={"決済期間from"}
              value={dateFrom}
              labelWidth={"100px"}
              onChange={onChangeDateFromChange}
            />
          </Grid>

          <Grid item sm={12} md={4}>
            <DatePicker
              title={"決済期間to"}
              value={dateTo}
              labelWidth={"100px"}
              onChange={onChangeDateToChange}
            />
          </Grid>
        </Grid>

        <Grid container alignItems="center" justify="center">
          <Grid item sm={12} md={8}>
            <SearchBox
              id={"Organization"}
              title={"本部名"}
              labelWidth={"100px"}
              readOnly={honbuVisible}
              placeholder={"本部コード問題を解決後実装予定です。"}
              value={organization}
              onChange={onChangeOrganization}
              error={organizationError}
            />
          </Grid>
          {/* </Grid> */}
          {/* <Grid container alignItems="center" justify="center">  */}
          <Grid item sm={12} md={8}>
            <SearchBox
              id={"TeamName"}
              title={"組織名"}
              labelWidth={"100px"}
              value={teamName}
              onChange={onChangeTeamName}
              error={teamNameError}
            />
          </Grid>
          {/* </Grid> */}
          {/* <Grid container alignItems="center" justify="center">  */}
          <Grid item sm={12} md={8}>
            <SearchBox
              id={"UserName"}
              title={"名前"}
              labelWidth={"100px"}
              value={userName}
              onChange={onChangeUserName}
              error={userNameError}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" justify="center">
          <Button
            id="applyButton"
            title="検索"
            variant="contained"
            onClick={search}
          />
        </Grid>
      </Card>

      <div className={classes.container}>
        <div
          className={id ? classes.mainContainerWithSide : classes.mainContainer}
        >
          <Card title={"金券利用状況（給与加算）"} titleLayout={true}>
            <div className={classes.title}>件数：{searchCount}件</div>
            <Table
              loading={tableLoading}
              headers={[
                {
                  name: "department",
                  disp: "チーム名",
                  type: "text",
                },
                {
                  name: "name",
                  disp: "名前",
                  type: "text",
                },
                {
                  name: "total",
                  disp: "決済金額",
                  type: "text",
                },
                {
                  name: "eating",
                  disp: "飲食・差入",
                  type: "text",
                },
                {
                  name: "purchase",
                  disp: "物品購入",
                  type: "text",
                },
                {
                  name: "golf",
                  disp: "ゴルフ",
                  type: "text",
                },
                {
                  name: "leisure",
                  disp: "レジャー",
                  type: "text",
                },
              ]}
              data={data}
              onScrolled={onScrolled}
              moreScroll={moreScroll}
            />

            <SumTable
              loading={tableLoading}
              headers={[
                {
                  name: "all_total",
                  disp: "合計金額",
                  type: "text",
                },
                {
                  name: "all_eating",
                  disp: "飲食・差入合計",
                  type: "text",
                },
                {
                  name: "all_purchase",
                  disp: "物品購入合計",
                  type: "text",
                },
                {
                  name: "all_golf",
                  disp: "ゴルフ合計",
                  type: "text",
                },
                {
                  name: "all_leisure",
                  disp: "レジャー合計",
                  type: "text",
                },
              ]}
              data={sumData}
            />
            <Grid container alignItems="center" justify="center">
              <Button
                title="CSV出力"
                variant="contained"
                //onClick={modalOpen}
                onClick={onClikcOutputSettlementCSV}
              />
            </Grid>
          </Card>
        </div>
        <div
          className={
            id
              ? `${classes.sideContainer} SideDetail`
              : classes.sideContainerNone
          }
        >
          <GeneralAffairsDetail
            id={id}
            setId={setId}
            data={data}
            setData={setData}
          />
        </div>
      </div>
    </ViewWrapper>
  );
};
