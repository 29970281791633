import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  ViewWrapper,
  Button,
  Table,
  Card,
  MessageBar,
  ModalForm,
} from "../../components";
import { useHistory } from "react-router-dom";
import { GetApi, PostApi } from "../../utils";
import { ApplyDetail } from ".";
import queryString from "query-string";
import Tabs from "@material-ui/core/Tabs";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import currencySymbol from "../../assets/images/currencySymbol.png";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import {
  LoaderContext,
  ActionEnum as LoaderActionEnum,
} from "../../stores/LoaderStore";
import { LoginContext } from "../../stores/LoginStore";
import {
  JudgmentContext,
  ActionEnum as JudgmentActionEnum,
} from "../../stores/JudgmentStore";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: "500px",
    padding: "8px",
    margin: theme.spacing(1),
  },
  moneyGroup: {
    display: "flex",
  },
  money: {
    padding: "0 20px 0 20px",
    fontSize: "50px",
    color: theme.palette.lpSecond.main,
  },
  expire: {
    marginTop: "10px",
    padding: "5px 10px 5px 10px",
    color: "#181724",
    background: "#E3E3E3",
    borderRadius: "20px 20px 20px 20px",
  },
  container: {
    display: "grid",
    gridTemplateColumns: "50% 50%",
    height: "100%",
  },
  mainContainerWithSide: {
    gridColumn: "1/2",
  },
  mainContainer: {
    gridColumn: "1/3",
  },
  sideContainerNone: {
    display: "none",
  },
  sideContainer: {
    overflow: "scroll",
    padding: "0px 8px",
    left: "32px",
    position: "relative",
    height: "100%",
    gridColumn: "2/3",
    background: theme.palette.lpWhite.main,
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, .3)",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
  },
  titleText: {
    color: theme.palette.grey[400],
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  requestAmounts: {
    color: theme.palette.grey[800],
    fontSize: "25px",
  },
  requestName: {
    color: theme.palette.grey[900],
    fontSize: "20px",
  },
  requestHistoryName: {
    color: theme.palette.grey[900],
    fontSize: "14px",
  },
  requestDeptName: {
    color: theme.palette.grey[700],
    fontSize: "10px",
  },
  requestPurpose: {
    color: theme.palette.grey[800],
    fontSize: "12px",
    paddingTop: "8px",
  },
  label: {
    borderRadius: "20px 20px 20px 20px",
    // background: "cornflowerblue",
    color: "white",
    padding: "4px",
    textAlign: "center",
  },
}));

const TransferRequest = (props: any) => {
  const qs = queryString.parse(props.location.search);
  const classes = useStyles();
  const history = useHistory();
  const { dispatch: loaderDispatch } = useContext(LoaderContext);
  const { state: loginState } = useContext(LoginContext);
  const { state: judgmentState, dispatch: judgmentDispatch } =
    useContext(JudgmentContext);
  const width = props.width;
  const [data, setData] = useState<{}[]>([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [moreScroll, setMoreScroll] = useState(false);
  const [id, setId] = useState(!qs.selectedId ? "" : qs.selectedId);
  const [messageBarMessage, setMessageBarMessage] = useState("");
  const [messageBarOpen, setMessageBarOpen] = useState(false);
  const [type, setType] = useState(!qs.type ? "" : qs.type);
  const [tabValue, setTabValue] = React.useState(0);
  const [transferRequest, setTransferRequest] = useState([]);
  const [transferRequestHistory, setTransferRequestHistory] = useState([]);
  const [isOpenRequestHistory, setIsOpenRequestHistory] = useState(false);

  const onClickCloseRequestHistoryModal = () => {
    setIsOpenRequestHistory(false);
  };

  const onClickRequestHistoryModal = async () => {
    const result = await GetApi("/money-ticket/transfer-request/history");

    if (result.statusCode >= 400) {
      setMessageBarMessage(result.message);
      setMessageBarOpen(true);
      return;
    }
    setTransferRequestHistory(result.data);
    setIsOpenRequestHistory(true);
  };

  const fetchHistoryData = async () => {
    const result = await GetApi("/money-ticket/transfer");

    if (result.statusCode >= 400) {
      setMessageBarMessage(result.message);
      setMessageBarOpen(true);
      return;
    }

    let dispData = result.data.map((d: any) => {
      if (d.type === "金券申請") {
        d.icon = "fa fa-file-text";
        d.iconColor = "purple";
      } else if (d.type === "受け取り") {
        d.icon = "fa fa-plus-square";
        d.iconColor = "green";
      } else if (d.type === "受け渡し") {
        d.icon = "fa fa-minus-square";
        d.iconColor = "red";
      } else if (d.type === "インポート") {
        d.icon = "fa fa-plus-square";
        d.iconColor = "green";
      }
      return d;
    });

    setMoreScroll(result.data.length !== 0 ? true : false);
    setData(dispData);
    setTableLoading(false);
  };

  const handlerApproveJudgment = (id: string, judgment: string) => {
    const update = async () => {
      loaderDispatch({
        type: LoaderActionEnum.SET_LOADER_TRUE,
      });
      const body = {
        judgment: judgment,
      };
      const result = await PostApi(
        "/money-ticket/transfer-request/" + id + "/judgment",
        body
      );

      loaderDispatch({
        type: LoaderActionEnum.SET_LOADER_FALSE,
      });

      // 処理成功
      if (result.statusCode >= 400) {
        setMessageBarMessage(result.message);
        setMessageBarOpen(true);
      } else {
        judgmentDispatch({
          type: JudgmentActionEnum.CHANGE_DATA_JUDGMENT_REQUEST,
          payload: {
            data: {
              judgment: {
                request: judgmentState.data.judgment.request - 1,
              },
            },
          },
        });
        setTransferRequest(
          transferRequest.filter((data: any) => data.transfer_request_id !== id)
        );
        fetchHistoryData();
      }
    };
    update();
  };

  const handleChangeTabValue = (event: any, newValue: any) => {
    setTabValue(newValue);
  };

  const onRowClick = (row: any) => {
    setId(row.id);
    let typeCode = "";
    if (row.type === "金券申請") {
      typeCode = "approval";
    } else if (row.type === "受け取り") {
      typeCode = "transfer";
    } else if (row.type === "受け渡し") {
      typeCode = "transfer";
    } else if (row.type === "インポート") {
      typeCode = "import";
    }
    setTabValue(1);
    setType(typeCode);
    history.push(
      "/money-ticket/transfer?type=" + typeCode + "&selectedId=" + row.id
    );
  };

  const onScrolled = async () => {
    const fetchHistoryData = async () => {
      if (!moreScroll) {
        return;
      }
      const result = await GetApi("/money-ticket/transfer", {
        offset: data.length,
      });

      if (result.statusCode >= 400) {
        setMessageBarMessage(result.message);
        setMessageBarOpen(true);
        return;
      }

      let dispData = result.data.map((d: any) => {
        if (d.type === "金券申請") {
          d.icon = "fa fa-file-text";
          d.iconColor = "purple";
        } else if (d.type === "受け取り") {
          d.icon = "fa fa-plus-square";
          d.iconColor = "green";
        } else if (d.type === "受け渡し") {
          d.icon = "fa fa-minus-square";
          d.iconColor = "red";
        } else if (d.type === "インポート") {
          d.icon = "fa fa-plus-square";
          d.iconColor = "green";
        }
        return d;
      });

      const _temp = dispData.filter(
        (element: any) => data.findIndex((e: any) => e.id === element.id) < 0
      );
      const _tempData = [...data, ..._temp];

      setMoreScroll(result.data.length !== 0 ? true : false);
      setData(_tempData);
    };
    await fetchHistoryData();
  };

  useEffect(() => {
    const fetchTransferRequestData = async () => {
      const result = await GetApi("/money-ticket/transfer-request");

      if (result.statusCode >= 400) {
        setMessageBarMessage(result.message);
        setMessageBarOpen(true);
        return;
      }
      setTransferRequest(result.data);
    };

    const fetchMoneyTicketData = async () => {
      const result = await GetApi("/money-ticket/balance");

      if (result.statusCode >= 400) {
        setMessageBarMessage(result.message);
        setMessageBarOpen(true);
        return;
      }
    };

    //unconfirmedMoneyTicketData();
    fetchMoneyTicketData();
    fetchHistoryData();
    fetchTransferRequestData();
  }, []);

  return (
    <ViewWrapper>
      <div className={classes.container}>
        <div
          className={
            id && isWidthUp("sm", width)
              ? classes.mainContainerWithSide
              : classes.mainContainer
          }
        >
          <div>
            <Button
              title={"おねだり"}
              onClick={() =>
                history.push("/money-ticket/transfer-request/create")
              }
            />
            <Button
              title={"おねだり履歴"}
              variant={"outlined"}
              onClick={onClickRequestHistoryModal}
            />
            <ModalForm
              isOpen={isOpenRequestHistory}
              onClickClose={onClickCloseRequestHistoryModal}
            >
              <h3>おねだり履歴</h3>
              <ul
                style={{
                  padding: "0px",
                  overflowY: "auto",
                }}
              >
                {transferRequestHistory.map((data: any) => (
                  <div>
                    <Card style={{ maxWidth: "600px" }}>
                      <div>
                        <Grid container alignItems="center">
                          <Grid item xs>
                            <div
                              className={classes.label}
                              style={{
                                maxWidth: "100px",
                                background:
                                  data.judgment === "1"
                                    ? "#9A919C"
                                    : data.judgment === "2"
                                    ? "#3962F7"
                                    : "#C1335F",
                              }}
                            >
                              {data.judgment_name}
                            </div>
                            <div className={classes.titleText}>
                              {moment(data.created_at).format(
                                "YYYY年MM月DD日 HH時mm分"
                              )}
                            </div>
                            <div className={classes.requestHistoryName}>
                              {data.request_bs !==
                                loginState.loginUserInfo?.bs &&
                                data.request_name + " におねだりされました"}
                              {data.target_bs !==
                                loginState.loginUserInfo?.bs &&
                                data.target_name + " におねだりしました"}
                            </div>
                            <div className={classes.requestAmounts}>
                              <img
                                src={currencySymbol}
                                alt=""
                                width={"18px"}
                                style={{
                                  position: "relative",
                                  top: "1px",
                                  right: "3px",
                                }}
                              />
                              {Number(data.amounts).toLocaleString()}
                            </div>
                            <div className={classes.requestDeptName}>
                              {data.dept_name}
                            </div>
                          </Grid>
                        </Grid>
                        <div className={classes.requestPurpose}>
                          <p style={{ whiteSpace: "pre" }}>{data.purpose}</p>
                        </div>
                      </div>
                    </Card>
                  </div>
                ))}
              </ul>
            </ModalForm>
          </div>

          {transferRequest.length > 0 ? (
            <Fragment>
              <h3>おねだりされてます</h3>
              <ul
                style={{
                  flexWrap: "nowrap",
                  display: "flex",
                  padding: "0px",
                  overflowY: "auto",
                }}
              >
                {transferRequest.map((data: any) => (
                  <div>
                    <Card style={{ minWidth: "280px", maxWidth: "600px" }}>
                      <div>
                        <Grid container alignItems="center">
                          <Grid item xs>
                            <div className={classes.titleText}>
                              {moment(data.created_at).format(
                                "YYYY年MM月DD日 HH時mm分"
                              )}
                            </div>
                            <div className={classes.requestName}>
                              {data.name}
                            </div>
                            <div className={classes.requestDeptName}>
                              {data.dept_name}
                            </div>
                          </Grid>
                          <Grid item>
                            <div className={classes.requestAmounts}>
                              <img
                                src={currencySymbol}
                                alt=""
                                width={"18px"}
                                style={{
                                  position: "relative",
                                  top: "1px",
                                  right: "3px",
                                }}
                              />
                              {Number(data.amounts).toLocaleString()}
                            </div>
                          </Grid>
                        </Grid>
                        <div className={classes.requestPurpose}>
                          <p style={{ whiteSpace: "pre" }}>{data.purpose}</p>
                        </div>
                        <Divider />
                        <div className={classes.buttonGroup}>
                          <Button
                            title="承諾"
                            variant="contained"
                            onClick={() =>
                              handlerApproveJudgment(
                                data.transfer_request_id,
                                "APPROVAL"
                              )
                            }
                          />
                          <Button
                            title="却下"
                            variant="outlined"
                            onClick={() =>
                              handlerApproveJudgment(
                                data.transfer_request_id,
                                "REJECTION"
                              )
                            }
                          />
                        </div>
                      </div>
                    </Card>
                  </div>
                ))}
              </ul>
            </Fragment>
          ) : (
            <Fragment>
              <h3>おねだりされていません</h3>
            </Fragment>
          )}

          <h3>金券受け渡し履歴</h3>
          {isWidthDown("xs", width) && (
            <Box m={1} style={{ marginBottom: "0px" }}>
              <Paper square>
                <Tabs
                  centered
                  variant="fullWidth"
                  value={tabValue}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleChangeTabValue}
                  aria-label="disabled tabs example"
                >
                  <Tab label="一覧" />
                  <Tab label="詳細" />
                </Tabs>
              </Paper>
            </Box>
          )}
          {(isWidthDown("xs", width) && tabValue === 0) ||
          isWidthUp("sm", width) ? (
            <Card style={{ marginTop: "1px" }}>
              <Table
                loading={tableLoading}
                cursor
                headers={[
                  {
                    name: "type",
                    disp: "種別",
                    type: "iconText",
                  },
                  {
                    name: "contents",
                    disp: "内容",
                    type: "text",
                  },
                  {
                    name: "created_at",
                    disp: "日付",
                    type: "datetime",
                  },
                  {
                    name: "amounts",
                    disp: "金額",
                    type: "moneyText",
                  },
                ]}
                data={data}
                onRowClick={onRowClick}
                onScrolled={onScrolled}
                moreScroll={moreScroll}
              />
            </Card>
          ) : (
            <Card style={{ marginTop: "1px" }}>
              <div>
                {id && type === "approval" ? (
                  <ApplyDetail
                    id={id}
                    setId={setId}
                    setTabValue={setTabValue}
                  />
                ) : (
                  <div>データが選択されていません。</div>
                )}
              </div>
            </Card>
          )}
        </div>
        {isWidthUp("sm", width) && (
          <div
            className={id ? classes.sideContainer : classes.sideContainerNone}
          >
            {id && type === "approval" ? (
              <ApplyDetail id={id} setId={setId} setTabValue={setTabValue} />
            ) : (
              <div></div>
            )}
          </div>
        )}
      </div>

      <MessageBar
        open={messageBarOpen}
        setOpen={setMessageBarOpen}
        severity={"error"}
        message={messageBarMessage}
      />
    </ViewWrapper>
  );
};

export default withWidth()(TransferRequest);
