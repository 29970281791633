export const statusBackgroundColor: { [key: string]: string } = {
  配分者確認待ち: "#7DA8FF",
  第１承認待ち: "#6F80EB",
  最終承認待ち: "#6256D5",
  総務承認待ち: "#3620C0",
  総務承認済み: "#37098B",
  決済完了: "#383636",
  取消し: "#C1335F",
  差し戻し: "#C1335F",
  修正依頼: "#fa8000",
};

export const judgmentBackgroundColor: { [key: string]: string } = {
  スキップ: "#6F94E7",
  未判定: "#9A919C",
  承認: "#3962F7",
  取消し: "#C1335F",
  差し戻し: "#C1335F",
};

export const judgmentType: { [key: string]: string } = {
  distributorName: "配分者",
  firstAuthorizerName: "第１承認",
  secondAuthorizerName: "最終承認",
};
