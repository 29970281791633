import React, { ReactNode, useReducer } from "react";

type StateType = {
  data: {
    judgment: {
      apply: number;
      request: number;
    };
  };
};

export enum ActionEnum {
  CHANGE_DATA_JUDGMENT_APPLY = "CHANGE_DATA_JUDGMENT_APPLY",
  CHANGE_DATA_JUDGMENT_REQUEST = "CHANGE_DATA_JUDGMENT_REQUEST",
}

type ActionType = {
  type: ActionEnum;
  payload?: any;
};
type ContextType = {
  state: StateType;
  dispatch: React.Dispatch<ActionType>;
};
const initialState = {
  data: {
    judgment: {
      apply: 0,
      request: 0,
    },
  },
};

export const reducer = (state: StateType, action: ActionType) => {
  switch (action.type) {
    case ActionEnum.CHANGE_DATA_JUDGMENT_APPLY:
      return {
        ...state,
        data: {
          ...state.data,
          judgment: {
            ...state.data.judgment,
            apply: action.payload?.data?.judgment?.apply,
          },
        },
      };
    case ActionEnum.CHANGE_DATA_JUDGMENT_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          judgment: {
            ...state.data.judgment,
            request: action.payload?.data?.judgment?.request,
          },
        },
      };
    default:
      return { ...state };
  }
};
interface IProps {
  children: ReactNode;
}
export const JudgmentContext = React.createContext({} as ContextType);
export const JudgmentProvider = ({ children }: IProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <JudgmentContext.Provider value={{ state, dispatch }}>
      {children}
    </JudgmentContext.Provider>
  );
};
