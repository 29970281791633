import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment, useEffect, useState } from "react";
import { ViewWrapper, Button, Block, MoneyTicket } from "../../components";
import { useHistory } from "react-router-dom";
import { GetApi } from "../../utils";
import Typography from "@material-ui/core/Typography";
const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
  },
  moneyGroup: {
    display: "flex",
  },
  money: {
    padding: "0 20px 0 20px",
    fontSize: "50px",
    color: "#A3A0F5",
  },
  title: {
    color: theme.palette.grey[400],
  },
  div: {
    margin: theme.spacing(1),
  },
  img: {
    width: "100px",
  },
}));

export const BalanceEditingDetail = (props: any) => {
  // クエリパラメータの取得
  const { id, setId, setTabValue } = props;

  // 定数定義
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [createdAt, setCreatedAt] = useState("");
  const [reason, setReason] = useState("");
  const [typeName, setTypeName] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [operatorName, setOperatorName] = useState("");
  const [amounts, setAmounts] = useState("");

  const fetchData = async () => {
    setLoading(true);
    if (!id) {
      return;
    }

    const result = await GetApi("/money-ticket/balance-editing/" + id);
    console.log(result);
    if (result.data) {
      setAmounts(result.data?.amounts);
      setCreatedAt(result.data?.created_at);
      setTypeName(result.data?.type_name);
      setOwnerName(result.data?.owner_name);
      setOperatorName(result.data?.operator_name);
      setReason(result.data?.reason);
    } else {
      history.push("/notfound");
    }
    setLoading(false);
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <ViewWrapper>
      <h2>残高編集詳細</h2>

      <Block loading={loading}>
        <MoneyTicket
          loading={loading}
          maxWidth="300px"
          expire={"編集金額"}
          amounts={Number(amounts).toLocaleString()}
        >
          <Fragment></Fragment>
        </MoneyTicket>

        <div className={classes.title}>編集日時</div>
        <Typography variant="body2" gutterBottom>
          {moment(createdAt).format("YYYY年MM月DD日 HH時mm分")}
        </Typography>
        <div className={classes.title}>区分</div>
        <Typography variant="body2" gutterBottom>
          {typeName}
        </Typography>

        <div className={classes.title}>対象者</div>
        <Typography variant="body2" gutterBottom>
          {ownerName}
        </Typography>

        <div className={classes.title}>編集者</div>
        <Typography variant="body2" gutterBottom>
          {operatorName}
        </Typography>

        <div className={classes.title}>編集理由</div>
        <Typography variant="body2" gutterBottom>
          <p style={{ whiteSpace: "pre", margin: "0px" }}>{reason}</p>
        </Typography>
      </Block>

      <div className={classes.buttonGroup}>
        <Fragment>
          <Button
            title="閉じる"
            variant="outlined"
            onClick={() => {
              setId("");
              setTabValue(0);
            }}
          />
        </Fragment>
      </div>
    </ViewWrapper>
  );
};
