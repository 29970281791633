import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    // margin: theme.spacing(1),
    "& .MuiSnackbar-root": {
      position: "fixed",
      top: "30%",
    },
    "& .MuiAlert-root": {
      padding: "0px 16px",
      borderRadius: "6px 6px 0px 0px",
    },
  },
  errorIcon: {
    color: theme.palette.lpRed.main,
  },
  successIcon: {
    color: theme.palette.lpGreen.main,
  },
  errorBack: {
    background: theme.palette.lpRed.main,
  },
  successBack: {
    background: theme.palette.lpGreen.main,
  },
  messageArea: {
    minWidth: "280px",
    padding: theme.spacing(2),
    boxShadow: "0 0 30px 0px rgba(0, 0, 0, .4)",
    borderRadius: "3px 3px 0px 0px",
    background: theme.palette.lpWhite.main,
    textAlign: "center",
  },
  messageButtonArea: {
    padding: theme.spacing(1),
    boxShadow: "0 10px 30px 0px rgba(0, 0, 0, .4)",
    borderRadius: "0px 0px 3px 3px",
    textAlign: "center",
  },
  button: {
    height: "100%",
    width: "100%",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    outline: "none",
    padding: "0px",
    appearance: "none",
    color: theme.palette.lpWhite.main,
  },
}));

export const MessageBar = (props: any) => {
  const classes = useStyles();
  const { open, setOpen, message, severity } = props;

  const handleClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const onClose = (event: any) => {
    setOpen(false);
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <Snackbar
          open={open}
          autoHideDuration={severity === "error" ? 4000 : 2000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <div>
            {/* <Alert onClose={handleClose} severity={severity}></Alert> */}
            <div className={classes.messageArea}>
              <div>
                {severity === "error" ? (
                  <i
                    className={
                      "fa fa-exclamation-triangle fa-3x " + classes.errorIcon
                    }
                  ></i>
                ) : severity === "success" ? (
                  <i
                    className={
                      "fa fa-check-circle fa-3x " + classes.successIcon
                    }
                  ></i>
                ) : (
                  <div></div>
                )}
              </div>
              <div>
                <span style={{ fontSize: "14px" }}>{severity}</span>
              </div>
              <div style={{ padding: "10px" }}>{message}</div>
            </div>
            <div
              className={
                classes.messageButtonArea +
                " " +
                (severity === "error"
                  ? classes.errorBack
                  : severity === "success"
                  ? classes.successBack
                  : "")
              }
            >
              <button className={classes.button} onClick={onClose}>
                閉じる
              </button>
            </div>
          </div>
        </Snackbar>
      </div>
    </Fragment>
  );
};
