import React, { ReactNode, useReducer } from "react";

type StateType = {
  loader?: boolean;
  confetti?: boolean;
  menu?: any;
  loginUserInfo?: {
    bs: string;
    dept: string;
    jobTitle?: string;
    name: string;
    email: string;
    dept_code: string;
    generalAffairs: boolean;
  };
  data: {
    judgment: {
      apply: number;
      request: number;
    };
  };
  users?: any;
  themeColor?: string;
};

export enum ActionEnum {
  SELECT_MENU = "SELECT_MENU",
  SET_LOADER_TRUE = "SET_LOADER_TRUE",
  SET_LOADER_FALSE = "SET_LOADER_FALSE",
  SET_CONFETTI_TRUE = "SET_CONFETTI_TRUE",
  SET_CONFETTI_FALSE = "SET_CONFETTI_FALSE",
  LOGIN = "LOGIN",
  SET_USERS = "SET_USERS",
  CHANGE_THEME_COLOR = "CHANGE_THEME_COLOR",
  CHANGE_DATA_JUDGMENT_APPLY = "CHANGE_DATA_JUDGMENT_APPLY",
  CHANGE_DATA_JUDGMENT_REQUEST = "CHANGE_DATA_JUDGMENT_REQUEST",
}

type ActionType = {
  type: ActionEnum;
  payload?: any;
};
type ContextType = {
  state: StateType;
  dispatch: React.Dispatch<ActionType>;
};
const initialState = {
  loader: false,
  confetti: false,
  menu: {},
  themeColor: "lincrea",
  data: {
    judgment: {
      apply: 0,
      request: 0,
    },
  },
};

export const reducer = (state: StateType, action: ActionType) => {
  switch (action.type) {
    case ActionEnum.SELECT_MENU:
      return {
        ...state,
        menu: action.payload?.menu,
      };
    case ActionEnum.SET_LOADER_TRUE:
      return {
        ...state,
        loader: true,
      };
    case ActionEnum.SET_LOADER_FALSE:
      return {
        ...state,
        loader: false,
      };
    case ActionEnum.SET_CONFETTI_TRUE:
      return {
        ...state,
        confetti: true,
      };
    case ActionEnum.SET_CONFETTI_FALSE:
      return {
        ...state,
        confetti: false,
      };
    case ActionEnum.LOGIN:
      return {
        ...state,
        loginUserInfo: action.payload?.loginUserInfo,
      };
    case ActionEnum.SET_USERS:
      return {
        ...state,
        users: action.payload?.users,
      };
    case ActionEnum.CHANGE_THEME_COLOR:
      return {
        ...state,
        themeColor: action.payload?.themeColor,
      };
    case ActionEnum.CHANGE_DATA_JUDGMENT_APPLY:
      return {
        ...state,
        data: {
          ...state.data,
          judgment: {
            ...state.data.judgment,
            apply: action.payload?.data?.judgment?.apply,
          },
        },
      };
    case ActionEnum.CHANGE_DATA_JUDGMENT_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          judgment: {
            ...state.data.judgment,
            request: action.payload?.data?.judgment?.request,
          },
        },
      };
    default:
      return { ...state };
  }
};
interface IProps {
  children: ReactNode;
}
export const GlobalContext = React.createContext({} as ContextType);
export const GlobalProvider = ({ children }: IProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  );
};
